export const PerformanceMarker = {
    ComponentMounted: "ComponentMounted",
    APILoadStart: "APILoadStart",
    APILoadEnd: "APILoadEnd",
    AllAPICallEnd: "AllAPICallEnd",
    AllRequestEnd: "AllRequestEnd",
    ProcessDataStart: "ProcessDataStart",
    ProcessDataEnd: "ProcessDataEnd",
    TableRenderDone: "TableRenderDone",
    FilterApplied: "FilterApplied",
    NewPageload: "NewPageLoad",
    AppConstructor: "AppConstructor",
    AllFeedback: "AllFeedback",
    MyFeedback: "MyFeedback",
    FeedbackTrends: "FeedbackTrends",
    Comment: "Comment",
}

export const PerformanceMeasureName = {
    APILoadTime: "APILoadTime",
    DataProcessingTime: "DataProcessingTime",
    RenderTime: "RenderTime",
    FilterTime: "FilterTime",
    PageLoadTime: "PageLoadTime",
    APIDataLoadTime: "APIDataLoadTime"
}

export const Page = {
    Feedback: "Feedback",
    Trends: "Trends",
    MyFeedbackPage: "MyFeedbackPage",
    SubmitFeedbackPage: "SubmitFeedbackPage",
    AdminTab: "AdminTab",
    BulkUpload: "BulkUpload",
    FeedbackLabel: "FeedbackLabel",
    FeedbackTemplate: "FeedbackTemplate",
    FeedbackCategorization: "FeedbackCategorization",
    ThemeTablePage: "ThemeTablePage",
    ThemeDetailPage: "ThemeDetailPage",

}