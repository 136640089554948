import { get, set, del, delMany, getMany } from 'idb-keyval';
import Logger from './util/logger';

export const setIndexedDb = async (key, val) => {
    try {
        await set(key, val)
    } catch(err) {
        Logger.sessionError("error occured while setting indexedDb", err);
    }
}

export const deleteManyfromIndexedDb = async (keyArr) => {
    try{
        await delMany(keyArr);
    } catch(err) {
        Logger.sessionError(`error occured while deleting indexedDb, ${err}`);
    }
}

export const deleteFromIndexedDb = async (key) => {
    try {
        await del(key);
    } catch(err) {
        Logger.sessionError(`error occured while deleting from indexedDb, ${err}`)
    }
}


export const getFromIndexedDb = async (key) => {
    try {
        const data = await get(key);
        return data;
    } catch(err) {
        Logger.sessionError(`error occured while getting indexedDb, ${err}`);
    }
}

export const getManyFromIndexedDb = async (keyArr) => {
    try{
        const data = await getMany(keyArr);
        return data;
    } catch(err) {

    }
}