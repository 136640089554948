import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
    Divider,
    Button,
    TextButton,
    TextAreaFormGroup,
    Alert
} from "@amzn/storm-ui";
import {
    Dropdown,
    DropdownItem,
} from "@amzn/storm-ui"
import { Form, FormRow } from "@amzn/storm-ui-column-form-kit";
import { useStore } from "../../../store";
import apiClient from "../../../util/apiClient";
import Logger from "../../../util/logger";
import Row from "../../../components/row";
import { createEMFObject, stringComparatorForArrayOfObjects } from "../../../util/util";
import endpoints from "../../../urlConfig";


const CreateFeedbackType = (props) => {
    const [typeInput, setTypeInput] = useState("");
    const [notes, setNotes] = useState("");
    const [isError, setIsError] = useState(false);
    const [selectedType, setSelectedType] = useState("");
    const [feedbackTypes, setFeedbackTypes] = useState([]);
    const history = useHistory();
    const {mode} = props;
    const { updateStaticData } = useStore();
    const staticData = useStore((state) => state.staticData);

    const userInfo = useStore((state) => state.userInfo);
    const { staticOperations ,staticEndpoint } = endpoints;

    useEffect(() => {
        const typesList = staticData.filter(data => data.docType === "FEEDBACK_TYPE");
        typesList.sort(stringComparatorForArrayOfObjects);
        setFeedbackTypes(typesList);
    // eslint-disable-next-line
    }, []);

    const closeCreateModal = () => {
        history.push('/admin/categorization');
    }

    const createTypeSuccess = (res) => {
        Logger.sessionInfo(`${mode} Feedback Type successfull: ${typeInput}`, {
            emfLog: createEMFObject(`${mode}FeedbackTypeCounts`, 1, "Count"),
        });
        const params = {
            size: 10000
        }
        apiClient
          .get(staticEndpoint, { params })
          .then((response) => {
            updateStaticData(response?.data?.results?.map((stat) => {
                return Object.assign(stat.fields, { id: stat.id });
              }))
              history.push('/admin/categorization');
          })
          .catch((error) => {
            Logger.sessionError(`fetching staticdata failed ${error}`, {
              browserUrl: window.location.href,
            });
            setIsError(true);
          })
          
    }

    const handleEdit = () => {
        let submitObj = {};
        const {user:{email}} = userInfo
        submitObj = {
            entityType: "feedbackType",
            operationType: "update",
            "input": {
                id: selectedType,
                fields: {
                    "docType": "FEEDBACK_TYPE",
                    "value": typeInput,
                    "updatedBy": email,
                    "notes": notes,
                    "entityValue": typeInput,
                    "operationType": "update"
                }
            }
        }
        apiClient.post(staticOperations, submitObj)
        .then(createTypeSuccess)
        .catch(err => {
            Logger.sessionError(`Failed to update Feedback Type: ${typeInput} error:${err}`,{
                emfLog: createEMFObject(`UpdateFeedbackTypeFailed`, 1, "Count",{FeedbackType:"UpdateFeedbackTypeFailed"}),
            });
            setIsError(true);
        })
    }

    const handleSubmit = () => {
        let submitObj = {};
        const {user:{email}} = userInfo
        submitObj = {
            entityType: "feedbackType",
            operationType: "create",
            "input" :{
                   fields:{
                   "docType" : "FEEDBACK_TYPE",
                   "value" : typeInput,
                   "updatedBy": email,
                   "notes" : notes,
                   "entityValue": typeInput,
                   "operationType": "create"
                   }  
                }
        }
        apiClient.post(staticOperations, submitObj)
        .then(createTypeSuccess)
        .catch(err => {
            Logger.sessionError(`Failed to create Feedback Type: ${typeInput} error: ${err}`,{
                emfLog: createEMFObject(`CreateFeedbackTypeFailed`, 1, "Count",{FeedbackType:"CreateFeedbackTypeFailed"}),
            });
            setIsError(true);
        })
    }

    const validateSubmit = () => {
        return !typeInput || !notes
    }

    const validateEdit = () => {
        const isdisabled = !typeInput || !notes || !selectedType || selectedType===typeInput;
        return isdisabled
    }

    if(isError){
        return (
            <Alert type="error">Something went wrong. Please try again.</Alert>
        )
    }

    return (
        <Form id="createLabel" >
            {mode !== "Create" && 
                <FormRow
                label="Feedback type"
                isSectionHeader
                id="feedback-type-row"
                controlComponents={[
                    (componentId) => (
                        <Dropdown
                            onChange={(val) => setSelectedType(val)}
                            selectedValue={selectedType}
                            onOverrideLabel={(dropdownItem, value) => {
                                if (value) { return dropdownItem; }
                                return 'Choose feedback type to update';
                            }}
                            error={!selectedType}
                        >
                            {feedbackTypes.map((type, key) => {
                                console.log(type)
                                console.log(feedbackTypes);
                                return <DropdownItem key={key} value={type.id} >{type.value}</DropdownItem>
                            })}
                        </Dropdown>
                    ),
                ]}
            />
            }
            <FormRow
                label="Feedback type"
                isSectionHeader
                id="feedback-type-row"
                controlComponents={[
                    (componentId) => (
                        <TextAreaFormGroup
                            placeholder= "Create feedback type"
                            value= {typeInput}
                            onChange={(event)=>{
                                setTypeInput(event.target.value)
                            }}
                            statusType={!typeInput? "error" : undefined}
                        />
                    ),
                ]}
            />
            <Divider />
            <FormRow
                label="Remarks"
                isSectionHeader
                id="remarks-row"
                controlComponents={[
                    (componentId) => (
                        <TextAreaFormGroup
                            placeholder= "Remarks"
                            value= {notes}
                            statusType={typeInput && !notes ? "error":undefined}
                            onChange={(event)=>{
                                setNotes(event.target.value)
                            }}
                        />
                    ),
                ]}
            />
            <Divider />
            <Row alignmentHorizontal="right">
                <TextButton
                    onClick={closeCreateModal}
                >
                    Cancel
                </TextButton>
                <Button
                    disabled={mode==="Create"?
                                validateSubmit() : validateEdit()}
                    primary={true}
                    onClick={() =>
                        mode === "Create"?
                        handleSubmit() : handleEdit()
                    }
                >
                    {`${mode} feedback type`}
                </Button>
            </Row>
        </Form>
    )
}

export default CreateFeedbackType;