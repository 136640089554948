import React, { useState, useEffect } from "react";
import { Text, Icon, BreadcrumbGroup, Breadcrumb, TextButton, Alert } from "@amzn/storm-ui";
import { useHistory } from "react-router";
import Row from "../../../components/row";
import { Table } from "../../../components/unified-data-table/unified-data-table";
import {
    FooterPanelContainer,
    PanelGroup,
    Pagination,
    ClientSideExportButton,
    HeaderPanelContainer,
    DateRangeFilter,
    TextOperationValueFilter,
    ValidationStatusEnum,
    FilterBar,
    AddFilterSelector,
    udcDateRangeFilter,
    BulkActionSelector,
    SelectAllCheckbox
} from "@amzn/unified-data-table-components/pack";
import { download } from "@amzn/storm-ui-icons";
import Logger from "../../../util/logger";
import Column from "../../../components/column";
import { PAGINATION_CONSTANT,TABLE_ROWS_COUNT } from "../../../util/constants";
import { createEMFObject, dateRangeFilterComparator, processReviewMappings, formatDateTime } from "../../../util/util";
import { getCases, updateOverrideMappingCase } from "../../../util/apiUtils";
import ReviewMappingCustomBulkActionComponent from './ReviewMappingCustomBulkAction';
import Loader from "../../../components/Loader";
import CellLinkRenderer from "../../../components/cellLinkRenderer/CellLinkRenderer";

const ReviewTheme = (props) => {

    const history = useHistory();
    const [gridApi, setGridApi] = useState();
    const [caseUpdate, setCaseUpdate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [ isError, setIsError] = useState(false);

    useEffect(()=>{
        async function updateData() {
            if(caseUpdate && gridApi) {
                setCaseUpdate(false);
                const result = await getCases();
                const procesedCases = processReviewMappings(result?.data?.results);
                console.log(procesedCases);
                gridApi.setRowData(procesedCases);
            }
        }
        updateData();
    // eslint-disable-next-line
    },[caseUpdate])

    const fetchCases = async (api) => {
        try {
          const initialCasesResponse = await getCases(0, TABLE_ROWS_COUNT);
          api.setRowData(processReviewMappings(initialCasesResponse.data.results));
          setTableData(processReviewMappings(initialCasesResponse.data.results));
          let requestsMade = TABLE_ROWS_COUNT;
          let bucketSize = TABLE_ROWS_COUNT;
          let index = 1;
          const ceilingBucketSize = 2000;
          while (requestsMade < initialCasesResponse.data.totalItemsCount) {
            index++;
            if (bucketSize < ceilingBucketSize) {
              bucketSize = TABLE_ROWS_COUNT * index;
            }
            const caseResponse = await getCases(requestsMade, bucketSize)
              // const { feedbacks } = this.state;
              const {
                data: { results: moreTableData },
              } = caseResponse;
  
              const freshTableData = processReviewMappings(moreTableData);
              setTableData(tableData.concat(...freshTableData));
              if (api) {
                api.applyTransaction({
                  add: freshTableData,
                });
              }
            requestsMade += bucketSize;
          }
        } catch (error) {
          Logger.sessionError(
            `ReviewMappingTablePage componentDidMount useEffect,
            ${error}`,
            {
              browserUrl: window.location.href,
            }
          );
          setIsError(true);
        }
      }

    const onGridReady = async (event) => {
        const { api } = event;
        setGridApi(api);
        // 1. For client side row model, you need to explicitly decide when to show loading overlay
        api.showLoadingOverlay();

        // 2. Fetch data source
        await fetchCases(api);
        // const procesedCases = processReviewMappings(result?.data?.results);
        // console.log(procesedCases);
        // api.setRowData(procesedCases);
    }
    const columnDefs = [{
        colId: "checkboxCol",
        checkboxSelection: true,
        headerComponent: null,
        width:30
      },
      {
        colId: "createDate",
        headerName: 'Created date',
        field: 'createdTimestamp',
        valueFormatter:(params) => formatDateTime(params.data.createdTimestamp),
        sortable: true,
        filter: udcDateRangeFilter,
        sort: "desc",
        filterValueGetter: (params) => {
          return  formatDateTime(params.data.createdTimestamp);
        },
        filterParams: {
          comparator: dateRangeFilterComparator,
        },
    },
    {
      colId: "overRideReason",
      headerName: "Override reason",
      field: "overrideReason"
    },
    {
        colId: 'newThemeName',
        headerName: 'New theme',
        field: 'newThemeName',
        sortable: true
    },
    {
        colId: 'oldThemeName',
        headerName: 'Old theme',
        field: 'oldThemeName',
        sortable: true
    },
    {
        colId: 'newProductServices',
        headerName: 'New product services',
        field: 'newProductServices',
    },
    {
        colId: 'oldProductServices',
        headerName: 'Old product services',
        field: 'oldProductServices',
    },
    {
        colId: 'newAdditionalContext',
        headerName: 'New additional context',
        field: 'newAdditionalContext',
    },
    {
        colId: 'oldAdditionalContext',
        headerName: 'Old additional context',
        field: 'oldAdditionalContext',
    },
    {
        colId: 'newPriority',
        headerName: 'New priority',
        field: 'newPriority'
    },
    {
        colId: 'oldPriority',
        headerName: 'Old priority',
        field: 'oldPriority'
    },
    {
        colId: 'caseId',
        headerName: 'Case id',
        field: 'caseId',
        sortable: true
    },
    {
        colId: 'feedbackId',
        headerName: 'Feedbacks',
        field: 'feedbackId',
        cellRenderer: 'cellLinkRenderer',
    },
    {
        colId: 'blockedRevenueDelta',
        headerName: 'Blocked revenue change',
        field: 'blockedRevenueDelta',
        sortable: true
    },
    {
        colId: 'submittedBy',
        headerName: 'Submitted by',
        field: 'submittedBy'
    },

    ];

    const handleExport = () => {
        Logger.sessionInfo("Exported review table", {
            emfLog: createEMFObject("reviewExportClick", 1, "Count", { ExportAction: "reviewExported" }),
        });
        gridApi.exportDataAsCsv({
            allColumns: true,
            onlySelected: false,
            columnKeys: [
                "createDate",
                "newThemeName",
                "newProductServices",
                "newAdditionalContext",
                "newPriority",
                "oldThemeName",
                "oldProductServices",
                "oldAdditionalContext",
                "oldPriority",
                "feedbackId",
                "submittedBy"
            ],
            fileName: "exportlabels.csv",
        });
    }

    const filterString = {
        filterComponentProps: {
          filterOptions: {
            contains: { label: "contains", default: true },
            notContains: { label: "not contains", default: false },
            equals: { label: "equals", default: false },
            notEqual: { label: "not equal to", default: false },
          },
          inputParams: {
            placeholder: "Enter filter here",
          },
          validate: (input) => {
            if (input === null || input === undefined) {
              return {
                status: ValidationStatusEnum.Error,
                message: "Input cannot be empty",
              };
            }
            return {
              status: ValidationStatusEnum.Success,
            };
          },
        },
      };

    const filterGroups = [
        {
            colId: "oldThemeName",
            filterName: "Old theme Name",
            filterComponent: TextOperationValueFilter,
            filterString,
        },
        {
            colId: "submittedBy",
            filterName: "Created by alias",
            filterComponent: TextOperationValueFilter,
            filterString,
        },
    ]

    const bulkActionDefs = [
        {
          bulkActionComponent: ReviewMappingCustomBulkActionComponent,
          bulkActionComponentProps: {
            approvalAction: "reject",
            warningMessage: "This request will discarded. Are you sure you want to continue?"
          },
          bulkActionId: 'deleteRowAction',
          label: 'Reject',
          getDisabledStatus: (selectedRowItem) => ({
            disabled: false,
          }),
          getSelectedRowLabel: (rowItem) => "Reject case",
          onApply: (params) => {
            approverejectBulkAction("rejected", params);
          },
        },
        {
          bulkActionComponent: ReviewMappingCustomBulkActionComponent,
          bulkActionComponentProps: {
            approvalAction: "approve",
            warningMessage: "Approving this action will save the new changes which will be used to further enhance the Sales AI model. Are you sure you want to continue?"
          },
          bulkActionId: 'approveRowAction',
          label: 'Approve',
          getDisabledStatus: (selectedRowItem) => ({
            disabled: false,
          }),
          getSelectedRowLabel: (rowItem) => "Approve",
          onApply: (params) => {
            approverejectBulkAction("approved", params);
          }
        },
      ]
    
    const approverejectBulkAction = async ( action, params ) => {
        // prepare payload and call update API
        console.log(params);
        setIsLoading(true);
        let submitObj = {};
        let ids = "";
        params?.selectedRowItems?.forEach((item)=>{
            ids = ids + (item?.rowData?.id || "") + "," ;
        })
        ids = ids.slice(0,-1);
        submitObj= {
            id: ids,
            fields: {
                "id": ids,
                "status": action
            }
        }
        //make api call
        try {
            await updateOverrideMappingCase(submitObj);
            Logger.sessionInfo(`approved mapping request: ${ids}`, {
                emfLog: createEMFObject(`UpdateCaseSuccess`, 1, "Count", {mappingOverride:"UpdateCaseSuccess"}),
              })
            setCaseUpdate(true);
            setIsLoading(false);
        } catch(err) {
            Logger.sessionError(`Failed to update cases: ${ids}`,{
                emfLog: createEMFObject(`UpdateCaseFailed`, 1, "Count", {mappingOverride:"UpdateCaseFailed"}),
              })
            setCaseUpdate(true);
            setIsLoading(false);
        }
      }

    const footerElement = (
        <FooterPanelContainer>
            <PanelGroup align="start">
                <ClientSideExportButton
                    isDisabled={(rows, pageSize) => (
                        rows === 0 ? true : false
                    )}
                    onExport={handleExport}
                    label={<Icon type={download} />}>
                </ClientSideExportButton>
            </PanelGroup>
            <PanelGroup align="end">
                <Pagination pageSizes={PAGINATION_CONSTANT} />
            </PanelGroup>
        </FooterPanelContainer>
    );

    const headerElement = (
        <>
            <FilterBar filterGroups={filterGroups} />
            <HeaderPanelContainer>
                <PanelGroup align="start">
                    <BulkActionSelector
                        labels={
                            {
                                selectorText: "Action",
                                // dialogTitle: "Row Action",
                                // getRowNameDisplayText: ()=>"",
                                saveChanges: "Proceed",
                                getRowsSelectedDisplayText: (count) => `${count} Row Selected`
                            }
                        }
                        bulkActionDefs={bulkActionDefs}
                    />
                </PanelGroup>
                <PanelGroup align="end">
                    <DateRangeFilter
                        filterColId="createdTimestamp"
                        customPeriodId="CUSTOM"
                        secondaryViewElementId="portal"
                        zone="America/Toronto"
                    />
                    <AddFilterSelector filterGroups={filterGroups} />
                </PanelGroup>
            </HeaderPanelContainer>
        </>
    )

    // if(isLoading){
    //     return(
    //         <Row
    //         spacingInset="xlarge"
    //         alignmentVertical="center"
    //         alignmentHorizontal="center"
    //         >
    //         <Loader />
    //         </Row>
    //     )
    // }

    if (isLoading || isError) {
        return (
          <Row alignmentHorizontal="center" spacingInset="400">
            {isLoading && <Loader />}
            {isError && (
              <Alert type="error">Something went wrong. Please try again.</Alert>
            )}
          </Row>
        );
      }

    return (
        <Column spacingInset="400">
            <BreadcrumbGroup>
                <Breadcrumb><TextButton onClick={() => history.push('/admin')}>Admin</TextButton></Breadcrumb>
                <Breadcrumb label="review theme" />
            </BreadcrumbGroup>
            <Text type="h2">Review themes</Text>

            <Row >
                <Text type="span">This functionality allows admins to review the feedback to theme mapping requests.</Text>
            </Row>
            <Table
                onGridReady={onGridReady}
                frameworkComponents= {{
                    'cellLinkRenderer' : CellLinkRenderer
                  }}
                gridOptions={{
                    columnDefs,
                    pagination: true,
                    getRowId: (rowData) => {
                        console.log(rowData);
                        return rowData.data.id
                    },
                    rowData: tableData,
                    frameworkComponents: {
                        SelectAllCheckbox,
                      },
                    rowSelection: 'multiple',
                    isRowSelectable: rowNode => true,
                }}
                tableFooter={footerElement}
                headerPanel={headerElement}
            />
        </Column>
    )
}

export default ReviewTheme;