import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
    Divider,
    Button,
    TextButton,
    TextAreaFormGroup,
    Alert,
    DropdownItemGroup
} from "@amzn/storm-ui";
import {
    Dropdown,
    DropdownItem,
} from "@amzn/storm-ui"
import { Form, FormRow } from "@amzn/storm-ui-column-form-kit";
import { ThemeProvider } from "styled-components";
import { overrideZIndex, getStaticData, createEMFObject, throttle } from "../../../util/util";
import Row from "../../../components/row";
import { useStore } from "../../../store";
import apiClient from "../../../util/apiClient";
import Logger from "../../../util/logger";
import {
    MultiSelect,
    MultiSelectItem,
    MultiSelectSearchPanel,
  } from "@amzn/storm-ui-multi-select";
  import endpoints from "../../../urlConfig";

const CreateTemplateForm = (props) => {
    const { mode, triggerSetEditLabelsObj, changeApplyButtonDisabledProperty } = props;
    const history = useHistory();
    const staticData = useStore((state) => state.staticData);
    const [feedBackCategoryTypes, setFeedBackCategoryTypes] = useState([]);
    const [feedbackTypes, setFeedbackTypes] = useState([]);
    const [feedbackLabels, setFeedbackLabels] = useState([]);
    const [allProduct, setAllProduct] = useState(false);
    const [productServiceList, setProductServiceList] = useState([]);
    const [selectedProductServices, setSelectedProductServices] = useState("");
    const [searchObjWithGroup, setSearchObjWithGroup] = useState([]);
    const [searchObjWithGroupSearch, setSearchObjWithGroupSearch] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [isSearchLoading, setIsSearchLoading] = useState(false);

    const [feedBackCategoryType, setFeedBackCategoryType] = useState();
    const [labelInput, setLabelInput] = useState("");
    const [notes, setNotes] = useState("");
    const [additionalContext, setAdditionalContext] = useState("");
    const [templateName, setTemplateName] = useState("");
    const [feedbackType, setFeedbackType] = useState("");
    const [isError, setIsError] = useState(false);
    const [formEdited, setFormEdited] = useState(false);
    const [editedEntities, setEditedEntities] = useState({});
    const [feedBackProductServicesTypes, setFeedBackProductServicesTypes] = useState([]);
    const [duplicateTemplateName, setDuplicateTemplateName] = useState(false);
    const {template} = endpoints;
    

    useEffect(()=>{
        if(mode==="Edit" && feedbackType){
            setFeedBackCategoryTypes(getStaticData("FEEDBACK_CATEGORY", feedbackType, staticData));
        }
    // eslint-disable-next-line
    },[feedbackType]);

    useEffect(()=>{
        if(mode==="Edit" && feedBackCategoryType){
            setFeedbackLabels(getStaticData("FEEDBACK_LABEL", feedBackCategoryType, staticData));
            generateProductSrvices(feedBackCategoryType);
        }
    // eslint-disable-next-line
    },[feedBackCategoryType]);



    useEffect(() => {
        setFeedbackTypes(getStaticData("FEEDBACK_TYPE", "", staticData));
        if(mode==="Edit"){
            const { selectedRowItems } = props;
            const { rowData: {feedbackCategory, feedbackLabel, feedbackType, name, productServices} } = selectedRowItems[0];
            setFeedbackType(feedbackType);
            setFeedBackCategoryType(feedbackCategory);
            setProductServiceList(productServices);
            setLabelInput(feedbackLabel);
            setTemplateName(name);
            setSelectedProductServices(productServices.split(','))
        }
    //eslint-disable-next-line
    }, [])


    const closeCreateModal = () => {
        history.push('/admin/template');
    }

    const createTemplateSuccess = (res) => {
        Logger.sessionInfo(`successfully created template: ${res}`, {
            emfLog: createEMFObject(`CreateFeedbackTemplateCounts`, 1, "Count"),
        });
        history.push('/admin/template');
          
    }

    const handleSubmit = () => {
        let submitObj = {};
        submitObj = {
            fields: {
                name: templateName,
                feedbackType: feedbackType,
                feedbackCategory: feedBackCategoryType,
                productServices: productServiceList,
                feedbackLabel: labelInput,
                additionalContext: additionalContext,
            }  
        }
        apiClient.post(template, submitObj)
        .then(createTemplateSuccess)
        .catch(err => {
            Logger.sessionError(`Failed to create template: error: ${err}`,{
                emfLog: createEMFObject(`CreateFeedbackTemplateFailed`, 1, "Count", {FeedbackTemplate:"CreateFeedbackTemplateFailed"}),
            });
            setIsError(true);
            setTimeout(()=>{
                history.push('/admin/template');
            },1000)
        })
        
    }


    useEffect(()=>{
        if(feedBackCategoryType && templateName && feedbackType && productServiceList && !duplicateTemplateName && notes && triggerSetEditLabelsObj) {
            triggerSetEditLabelsObj({
                templateName: templateName,
                selectedFeedbackType: feedbackType,
                selectedCategory: feedBackCategoryType,
                selectedProductServices: productServiceList,
                labelsValue: labelInput,
                additionalContext: additionalContext,
                notes: notes,
            })
            if(changeApplyButtonDisabledProperty && formEdited){
                changeApplyButtonDisabledProperty(false);
            } else{
                changeApplyButtonDisabledProperty(true);
            }
        }
        else {
            if(changeApplyButtonDisabledProperty){
                changeApplyButtonDisabledProperty(true);
            }
        }
    // eslint-disable-next-line
    },[labelInput, feedBackCategoryType, notes, templateName, feedbackType, productServiceList, additionalContext]);

    if(isError){
        return(
            <Alert type="error">Something went wrong. Please try again.</Alert>
        )
    }

    const validateEdit = (value, entity) => {
        const editedEntitiesState = Object.assign({}, editedEntities);
        if(!value){
            delete editedEntitiesState[entity]
            setEditedEntities(editedEntitiesState);
        }
        if(mode === "Edit" && value){
            const { selectedRowItems } = props;
            const { rowData } = selectedRowItems[0];
            if(value!==rowData[entity]){
                editedEntitiesState[entity] = true;
            }
            else {
                delete editedEntitiesState[entity];
            }
            setEditedEntities(editedEntitiesState);
            if(Object.keys(editedEntitiesState).length>0){
                setFormEdited(true);
            }
            else{
                setFormEdited(false);
            }
        }

    }

    const generateProductSrvices = (categoryType) => {
        let allproductFlag = false;
        let feedBackProductServicesTypes = getStaticData(
            "PRODUCT_SERVICES",
            categoryType,
            staticData
          );
          if (feedBackProductServicesTypes.length === 0) {
            feedBackProductServicesTypes = ["Others"];
          }
          if (
            feedBackProductServicesTypes.length === 1 &&
            feedBackProductServicesTypes[0] === "All Products"
          ) {
              if (!allProduct) {
                  allproductFlag = true;
                  setAllProduct(true);
              }
            feedBackProductServicesTypes = getStaticData("PRODUCT_SERVICES", "", staticData);
            feedBackProductServicesTypes = feedBackProductServicesTypes.filter(
              (word) => word !== "All Products"
            );
            feedBackProductServicesTypes = feedBackProductServicesTypes.filter(
              (word) => word !== "NONE"
            );
            let feedbackCategoryGroup = getStaticData("FEEDBACK_CATEGORY", "", staticData);
            feedbackCategoryGroup = feedbackCategoryGroup.filter(
              (word) =>
                word !== "International Expansion / Multi-Marketplace" &&
                word !== "Marketing Resources & Insights" &&
                word !== "Other" &&
                word !== "General"
            );
            let uSet1 = new Set(feedbackCategoryGroup);
            feedbackCategoryGroup = Array.from(uSet1);
            feedBackProductServicesTypes.push(...feedbackCategoryGroup);
            let uSet = new Set(feedBackProductServicesTypes);
            feedBackProductServicesTypes = Array.from(uSet);
          } else {
              if (allProduct) {
                  allproductFlag = false;
                  setAllProduct(false);
              }
          }
          feedBackProductServicesTypes.sort();
          setFeedBackProductServicesTypes(feedBackProductServicesTypes);
          if (allproductFlag && searchObjWithGroup.length === 0) {
            let feedbackCategoryGroup = getStaticData("FEEDBACK_CATEGORY", "", staticData);
            feedbackCategoryGroup = feedbackCategoryGroup.filter(
              (word) =>
                word !== "International Expansion / Multi-Marketplace" &&
                word !== "Marketing Resources & Insights" &&
                word !== "Other"
            );
            let searchObjWithObjArr = [];
            feedbackCategoryGroup.forEach((word) => {
              let servicesData = getStaticData("PRODUCT_SERVICES", word, staticData);
              servicesData.sort();
              if (servicesData[0] !== "All Products") {
                let items = servicesData.map((key) => {
                  if (word === "Custom / Other" && key === "Other") {
                    key = "Other ";
                  }
                  if (word === "Creative" && key === "General") {
                    key = "General ";
                  }
                  let item = {
                    label: key,
                    key: key,
                  };
                  return { item };
                });
                let data = {
                  group: word,
                  values: items,
                };
                searchObjWithObjArr.push(data);
              }
              setSearchObjWithGroup(searchObjWithObjArr);
            });
          }
    }

    const renderDropdownItems = (items) =>
      items.map((category, index) => (
        <MultiSelectItem key={category.item.label} value={category.item.label}>
          {category.item.label}
        </MultiSelectItem>
      ));
    const renderAllDropDownValues = (data) => {
      let items = [];
      for (let category of data) {
        items.push(
          <DropdownItemGroup label={category.group}></DropdownItemGroup>
        );
        let dropDownValues = category.values;
        for (let dropDown of dropDownValues) {
          items.push(
            <MultiSelectItem
              key={dropDown.item.label}
              value={dropDown.item.label}
            >
              {dropDown.item.label}
            </MultiSelectItem>
          );
        }
      }
      return items;
    };

    const mappingForMultiselectSearch = (list) =>
    list.map((key) => ({ item: { label: key, key } }));

    const getMatch = (a, b)=>{
        let matches = [];
        for (let i = 0; i < a.length; i++) {
          for (let e = 0; e < b.length; e++) {
            let s1 = a[i].item.label;
            let s2 = b[e].item.label;
            if (s1 === s2) {
              matches.push(s1);
            }
          }
        }
        return matches;
      }

    const onProductServicesFilterChange = (items) => {
        const filterItems = mappingForMultiselectSearch(
          feedBackProductServicesTypes
        );
        if (items.length === filterItems.length) {
            setFilteredOptions(filterItems);
          if (allProduct) {
            setSearchObjWithGroupSearch(searchObjWithGroup);
          }
        } else {
            setFilteredOptions(items);
          if (allProduct) {
            let obj = [];
            searchObjWithGroup.forEach((grp) => {
              let group = grp.group;
              let val = grp.values;
              let intersection = getMatch(val, items);
              if (intersection !== undefined && intersection.length > 0) {
                let dataItem = intersection.map((key) => {
                  let item = {
                    label: key,
                    key: key,
                  };
                  return { item };
                });
                let data = {
                  group: group,
                  values: dataItem,
                };
                obj.push(data);
              }
            });
  
            if (obj.length === 0 && items.length > 0) {
              for (const itemp of items) {
                searchObjWithGroup.forEach((grp) => {
                  let group = grp.group;
                  let val = grp.values;
                  let itemVal = itemp.item.key;
                  if (group.indexOf(itemVal) !== -1) {
                    let dataItem = val.map((key) => {
                      let item = {
                        label: key.item.key,
                        key: key.item.key,
                      };
                      return { item };
                    });
                    let data = {
                      group: group,
                      values: dataItem,
                    };
                    obj.push(data);
                  }
                });
              }
            }
            setSearchObjWithGroupSearch(obj);
          }
        }
      };
  
      const onProductServicesFilterClear = () => {
        const filterItems = mappingForMultiselectSearch(
          feedBackProductServicesTypes
        );
        setFilteredOptions(filterItems);
        if (allProduct) {
            setSearchObjWithGroupSearch(searchObjWithGroup);
        }
      };


      const onSearchInput = () => {
        let params = {};
        if (!templateName.trim()) {
          // handling clear button
          setTemplateName("");
          return;
        }
        setIsSearchLoading(true);
        let searchUrl = `/search/template/${encodeURIComponent(templateName)}?size=10`
    
        apiClient
          .get(searchUrl, { params })
          .then((response) => {
            if (response?.data?.results?.length > 0) {
              const results = response.data.results.map((result) => {
                return Object.assign(result.fields, { id: result.id });
              });
              for(let i = 0; i< results.length; i++){
                const templateNameSearchRes = results[i]?.name?.toLocaleLowerCase();
                if(templateNameSearchRes && templateNameSearchRes=== templateName.toLocaleLowerCase()){
                    setDuplicateTemplateName(true);
                    break;
                } else{
                    setDuplicateTemplateName(false);
                }
              }
              setIsSearchLoading(false);
            } else {
                setDuplicateTemplateName(false);
                setIsSearchLoading(false);
            }
          })
          .catch(() => {
            setDuplicateTemplateName(false);
            setIsSearchLoading(false);
          })
          .finally(() => {
            setIsSearchLoading(false);
          });
      };

      const onSearchInputThrottle = throttle(onSearchInput, 1000);

    return (
            <ThemeProvider theme={overrideZIndex}>
                <Form id="createTemplate" >
                <FormRow
                        label="Template name"
                        isSectionHeader
                        id="feedback-template-name-row"
                        controlComponents={[
                            (componentId) => (
                                <TextAreaFormGroup
                                    placeholder= "Template name"
                                    value= {templateName}
                                    onChange={(event)=>{
                                        setTemplateName(event.target.value);
                                        validateEdit(event.target.value,"name");
                                        setIsSearchLoading(true);
                                        // onSearchInputThrottle()
                                    }}
                                    onBlur={onSearchInputThrottle}
                                    statusType={duplicateTemplateName?'error':undefined}
                                    message={duplicateTemplateName?'Template with same name exists':''}
                                />
                            ),
                        ]}
                    />
                    <Divider />
                <FormRow
                        label="Feedback type"
                        isSectionHeader
                        id="feedback-type-row"
                        controlComponents={[
                            (componentId) => (
                                <Dropdown
                                    error={templateName && !feedbackType}
                                    fullWidth
                                    id={componentId}
                                    onChange={(value) => {
                                        validateEdit(value, "feedbackType");
                                        setFeedbackType(value);
                                        setFeedBackCategoryType("");
                                        setFeedBackCategoryTypes(getStaticData("FEEDBACK_CATEGORY", value, staticData));
                                        setFeedBackProductServicesTypes([])
                                        setSearchObjWithGroup([])
                                        setAllProduct(false)
                                        setSelectedProductServices("");
                                    }}
                                    onOverrideLabel={(dropdownItem, value) => {
                                        if (value) {
                                            return dropdownItem;
                                        }
                                        return "Feedback type";
                                    }}
                                    selectedValue={feedbackType}
                                >
                                    {feedbackTypes.map((type, index) => (
                                        <DropdownItem key={type} value={type}>
                                            {type}
                                        </DropdownItem>
                                    ))}
                                </Dropdown>
                            ),
                        ]}
                    />
                    <Divider />
                    <FormRow
                        label="Feedback category"
                        isSectionHeader
                        id="feedback-category-row"
                        controlComponents={[
                            (componentId) => (
                                <Dropdown
                                    error={feedbackType && !feedBackCategoryType}
                                    fullWidth
                                    id={componentId}
                                    onChange={(value) => {
                                        validateEdit(value, "feedbackCategory");
                                        setFeedBackCategoryType(value);
                                        setLabelInput("");
                                        setFeedbackLabels(getStaticData("FEEDBACK_LABEL", value, staticData));
                                        generateProductSrvices(value);
                                        setSelectedProductServices("")
                                    }}
                                    onOverrideLabel={(dropdownItem, value) => {
                                        if (value) {
                                            return dropdownItem;
                                        }
                                        return "Feedback category";
                                    }}
                                    selectedValue={feedBackCategoryType}
                                >
                                    {feedBackCategoryTypes.map((subCategory, index) => (
                                        <DropdownItem key={subCategory} value={subCategory}>
                                            {subCategory}
                                        </DropdownItem>
                                    ))}
                                </Dropdown>
                            ),
                        ]}
                    />
                    <Divider />
                    <FormRow
                        label="Product/Service"
                        isSectionHeader
                        id="product-row"
                        controlComponents={[
                            () => (
                                <div className={feedBackCategoryType && !productServiceList.length ? "error-state" : ""}>
                                    <MultiSelect
                                        name="product-multi-select"
                                        fullWidth
                                        onChange={(value) => {
                                            setProductServiceList(value.toString());
                                            validateEdit(value, "productServices");
                                            // this.setContextValueForList(
                                            //     "advertisingSubProduct",
                                            //     value
                                            // );
                                            setSelectedProductServices(value);
                                        }}
                                        // onOverrideLabel={overRideLabelWrapper("Product/Services")}
                                        onOverrideLabel={(selectedValues, selectedItems) => {
                                            if (selectedValues.length === 0) {
                                              return `Choose one or multiple "Product/Services"`;
                                            }
                                            const prefix = (
                                              <span key={`prefix-${selectedValues.length}`}>
                                                ( {selectedValues.length} - values for Product/Services selected )
                                              </span>
                                            );
                                        //return [prefix, ' ' ,label];
                                        return [prefix];
                                    }}
                                    selectedValues={selectedProductServices}
                                    withSearchPanel={(
                                        <MultiSelectSearchPanel
                                            items={feedBackProductServicesTypes.map((key) => {
                                                return { label: key, key };
                                            })}
                                            options={{ keys: ["label"], threshold: 0.3 }}
                                            onFilterChange={onProductServicesFilterChange}
                                            onFilterClear={onProductServicesFilterClear}
                                        />
                                    )}
                                >
                                    {allProduct
                                        ? renderAllDropDownValues(searchObjWithGroupSearch)
                                        : renderDropdownItems(filteredOptions)}
                                </MultiSelect>
                                </div>
                            ),
                        ]}
                    />
                    <Divider />
                    <FormRow
                        label="Feedback label"
                        isSectionHeader
                        id="feedback-label-row"
                        optionalTag="optional"
                        controlComponents={[
                            (componentId) => (
                                <Dropdown
                                    fullWidth
                                    id={componentId}
                                    onChange={(value) => {
                                        validateEdit(value, "feedbackLabel");
                                        setLabelInput(value);
                                    }}
                                    onOverrideLabel={(dropdownItem, value) => {
                                        if (value) {
                                            return dropdownItem;
                                        }
                                        return "Feedback label";
                                    }}
                                    selectedValue={labelInput}
                                >
                                    {feedbackLabels.map((label, index) => (
                                        <DropdownItem key={label} value={label}>
                                            {label}
                                        </DropdownItem>
                                    ))}
                                </Dropdown>
                            ),
                        ]}
                    />
                    <Divider />
                    <FormRow
                        label="Additional context"
                        isSectionHeader
                        id="feedback-context-row"
                        optionalTag="optional"
                        controlComponents={[
                            (componentId) => (
                                <TextAreaFormGroup
                                    placeholder= "Additionsl context"
                                    value= {additionalContext}
                                    onChange={(event)=>{
                                        setAdditionalContext(event.target.value)
                                        validateEdit(event.target.value, "additionalContext");
                                    }}
                                />
                            ),
                        ]}
                    />
                    <Divider />
                    <FormRow
                        label="Remarks"
                        isSectionHeader
                        id="feedback-category-row"
                        controlComponents={[
                            (componentId) => (
                                <TextAreaFormGroup
                                    placeholder= "Remarks"
                                    value= {notes}
                                    onChange={(event)=>{
                                        setNotes(event.target.value)
                                    }}
                                    statusType={productServiceList.length && !notes ? "error":undefined}
                                />
                            ),
                        ]}
                    />
                    <Divider />
                    {mode!=="Edit" &&
                    <Row alignmentHorizontal="right">
                        <TextButton
                            onClick={closeCreateModal}
                        >
                            Cancel
                        </TextButton>
                        <Button
                            disabled={ !notes || !feedBackCategoryType || !productServiceList || !feedbackType ||  !templateName || duplicateTemplateName ||isSearchLoading }
                            primary={true}
                            onClick={() =>
                                handleSubmit()
                            }
                        >
                            {`${mode} template`}
                        </Button>
                    </Row>}
                </Form>
            </ThemeProvider>
    )
}

export default CreateTemplateForm;