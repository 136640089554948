import * as React from "react";
import { parseJwt } from "../../authentication/authenticator";

import apiClient from "../../util/apiClient";
import {createEMFObject, throttle, getToken} from "../../util/util";
import "./Header.scss";
import Logger from "../../util/logger";
//import styled from 'styled-components';
import {
  //SearchInput,
  Text,
} from '@amzn/storm-ui';
//import Loader from "../Loader";

const SCOPES = ["Feedbacks"];

class Header extends React.Component {
  constructor(props) {
    super(props);

    let currentScope = SCOPES[0];

    if (window.location.pathname.indexOf("/feature/") === 0) {
      currentScope = SCOPES[1];
    } else if (window.location.pathname.indexOf("/feedback/") === 0) {
      currentScope = SCOPES[2];
    }



    this.state = {
      token: "",
      isOptionsOpen: false,
      isSearchLoading: false,
      isSuggestionsOpen: false,
      searchText: "",
      searchSuggestions: [],
      scope: currentScope,
      user: "",
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.fetchSearch = this.fetchSearch.bind(this);
    this.fetchSearchThrottled = throttle(this.fetchSearch, 1000);
    this.setTokenAndUser = this.setTokenAndUser.bind(this);
  }

  async setTokenAndUser(){
    let token = await getToken()
    this.setState({
      token,
      user: parseJwt(token)
    })
  }

  async componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.setTokenAndUser();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.optionsRef && !this.optionsRef.contains(event.target)) {
      this.setState({ isOptionsOpen: false });
    }
  }

  fetchSearch() {
    const { searchText } = this.state;

    if (searchText) {
      this.setState({ isSearchLoading: true });
      apiClient
        .get(`search/${'Feedbacks'.toLowerCase()}/${encodeURIComponent(searchText)}`, {
          params: {
            size: 10,
          },
        })
        .then((response) => {
          this.setState({
            searchSuggestions: response.data.results.filter(
              (suggestion) => suggestion.type === "Feature" || suggestion.type === "Feedback"
            ),
          });
        })
        .then(() => {
          this.setState({ isSearchLoading: false });
        });

      Logger.sessionInfo(`Global search fired for query ${searchText}`, {
        emfLog: createEMFObject("GlobalSearchHits", 1, "Count"),
      });
    }
  }

  render() {
    const {
      //isSearchLoading,
      //isSuggestionsOpen,
      //searchText,
      //searchSuggestions,
      user,
    } = this.state;

    let notIFramePresent = (window.top === window.self);
    return (
      <div className={'header ' + (!notIFramePresent ? "iframe" : "")} >
        {notIFramePresent && <a className="logo" href="/">
          <span className="logo__image" />
          <Text  className="logo__title" inline={true} fontSize="medium">Customer Feedback Portal </Text>
        </a>}
       {/* {notIFramePresent &&
        <div className="search">
          <div style={{ flexGrow: 1, position: "relative" ,border:"0px"}}>
            <SearchInput  id="search-input-basic"
              placeholder="Search..."
              value={searchText}
              onChange={(event) => {
                this.setState({ searchText: event.target.value, isSuggestionsOpen: true }, this.fetchSearchThrottled);
              }}
              onClick={() => {
                this.setState({ isSuggestionsOpen: true });
              }}
              onFocus={() => {
                this.setState({ isSuggestionsOpen: true });
              }}
              ref={(node) => (this.searchRef = node)}
              onClear={(): void => {
                this.setState({ searchText: "", isSuggestionsOpen: false });
                this.setState({searchSuggestions : ""});
              }}
            />
            {isSearchLoading && (
                <Loader />
            )}
            {isSuggestionsOpen && searchSuggestions.length > 0 && (
              <div className="search-results" ref={(node) => (this.matchesRef = node)}>
                {searchSuggestions.map((suggestion) => (
                  <div className="search-results-item" key={suggestion.id}>
                    <a href={`/${suggestion.type.toLowerCase()}/${suggestion.id}`}>
                      <div>
                        {suggestion.type === "Feedback" ? suggestion.fields.accountName : suggestion.fields.featureName}
                      </div>
                      <div className="type">{suggestion.type}</div>
                    </a>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>}*/}

        {notIFramePresent && user ? (
          <div className="user">
            <Text className="logo__title" level={6}>Hello, {user.given_name}</Text>
            <span
              className="image"
              style={{
                background: `center /cover url(https://internal-cdn.amazon.com/badgephotos.amazon.com/phone-image.pl?uid=${user.identities[0].userId}) no-repeat`,
              }}
            />
          </div>
        ) : (
          <div className="user"></div>
        )}
      </div>
    );
  }
}

export default Header;
