// Text Filters
export const textContains = (filterText, filterField, data) => {
    const filterTextArr = filterText.split(',');
    const result = data.filter(entry=>{
        const value = entry[filterField];
        if(!value){
            return false;
        }
        return filterTextArr.some((item) => {
            return value.toLowerCase().indexOf(item.toLowerCase()) >= 0;
          });
    })

    return result;
}

export const textNotContains = (filterText, filterField, data) => {
    const filterTextArr = filterText.split(',');
    const result = data.filter(entry=>{
        const value = entry[filterField];
        if(!value){
            return false;
        }
        return filterTextArr.some((item) => {
            return value.toLowerCase().indexOf(item.toLowerCase()) === -1;
          });
    })

    return result;
}


// Date Filters
export const dateOn = (filterDate, filterField, data) => {
    //assuming the two dates are epoch timestamps
    const dateQuery = new Date(filterDate*1000).setHours(0,0,0,0);
    return data.filter(entry => {
        const value = entry[filterField];
        if(!value){
            return false;
        }
        const valuedateOnly = new Date(parseInt(value,10)*1000).setHours(0,0,0,0);
        return new Date(valuedateOnly).getTime() === new Date(dateQuery).getTime();
    })
}

export const dateBefore = (filterDate, filterField, data) => {
    const filterdateOnly = new Date(filterDate*1000).setHours(0,0,0,0);
    return data.filter(entry => {
        const value = entry[filterField];
        if(!value){
            return false;
        }
        const valuedateOnly = new Date(parseInt(value,10)*1000).setHours(0,0,0,0);
        return valuedateOnly < filterdateOnly
    })
}

export const dateAfter = (filterDate, filterField, data) => {
    const filterdateOnly = new Date(filterDate*1000).setHours(0,0,0,0);
    return data.filter(entry => {
        const value = entry[filterField];
        if(!value){
            return false;
        }
        const valuedateOnly = new Date(parseInt(value,10)*1000).setHours(0,0,0,0);
        return valuedateOnly > filterdateOnly
    })
}

export const filterCallbacks = {
    "textContains": textContains,
    "textNotContains": textNotContains,
    "dateOn": dateOn,
    "dateBefore": dateBefore,
    "dateAfter": dateAfter
}