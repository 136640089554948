import React from "react";

import { defaultTheme } from "@amzn/storm-ui";

import styled from "styled-components";

import {
    filterPropsByPrefix,
    getHorizontalDirection,
    parseSpacing,
    spacingTokens

} from "../util/util";

const generateChildStyle = (height): string => {
    return `flex: ${height === "fill" ? "1 !important" : undefined};
    flexShrink: ${
        height && height !== "fill" && height !== "auto"
            ? `0 !important`
            : undefined
    };
    height: ${
        height !== "fill" &&
        height !== "fit" &&
        height !== undefined &&
        height !== null &&
        height !== ""
            ? `${height}${typeof height === "number" ? "px" : ""} !important`
            : undefined
    }`;
};

export const Parent = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  > * {
    box-sizing: border-box !important;
    margin: 0 0 ${(props): number => props.verticalSpacing} 0 !important;
  }
  > *:last-child {
    margin: 0 !important;
  }
  ${(props): string | undefined => {
    if (!Array.isArray(props.heights)) {
        return `> * {
          ${generateChildStyle(props.heights)}
        }`;
    }

    return props.heights.reduce((result, height, index) => {
        return `${result}
      > *:nth-child(${parseInt(index) + 1}){
        ${generateChildStyle(height)}
      }`;
    }, ``);
}}
`;

interface ColumnProps {
    alignmentHorizontal?:
        | "left"
        | "start"
        | "center"
        | "right"
        | "end"
        | "stretch";
    alignmentVertical?: "top" | "center" | "bottom" | "justify";
    "aria-*"?: string;
    backgroundColor?: "primary" | "secondary" | string;
    children?: React.ReactNode;
    className?: string;
    "data-*"?: string;
    height?: string | number;
    heights?: string | number | Array<string | number>;
    maxHeight?: string | number;
    maxWidth?: string | number;
    minHeight?: string | number;
    minWidth?: string | number;
    spacing?: string |  typeof spacingTokens;
    spacingInset?: string |  typeof spacingTokens;
    type?: "outline" | "fill";
    width?: string | number;
}

class Column extends React.Component<ColumnProps> {

    getBackgroundColor(): string {
        const { backgroundColor, type } = this.props;
        if (backgroundColor === "primary") {
            return defaultTheme.palette.white;
        } else if (backgroundColor === "secondary") {
            return defaultTheme.palette.gray[10];
        } else {
            if (type === "fill" && !backgroundColor) {
                return defaultTheme.palette.gray[10];
            } else {
                return backgroundColor ;
            }
        }
    }

    render(): React.ReactNode {
        const {
            alignmentHorizontal = "stretch",
            alignmentVertical = "top",
            children,
            className,
            height,
            heights,
            maxHeight,
            minHeight,
            maxWidth,
            minWidth,
            spacing = "medium",
            spacingInset,
            type,
            width,
        } = this.props;

        const spacingValues = parseSpacing(spacing);
        const verticalSpacing = spacingValues[0];
        const horizontalSpacing = spacingValues[1] || verticalSpacing;

        const ColumnStyles: any = {
            backgroundColor: this.getBackgroundColor(),
            alignItems: {
                left: "flex-start",
                right: "flex-end",
                center: "center",
                stretch: "stretch",
            }[getHorizontalDirection(alignmentHorizontal)],
            justifyContent: {
                top: "flex-start",
                bottom: "flex-end",
                center: "center",
                justify: "space-between",
            }[alignmentVertical],
            padding: parseSpacing(spacingInset).join(" "),
            height,
            maxHeight,
            minHeight,
            maxWidth,
            minWidth,
        };

        if (type === "outline") {
            ColumnStyles.borderRadius = "4px";
            ColumnStyles.border = `1px solid ${defaultTheme.palette.gray[300]}`;
        }

        if (width) {
            ColumnStyles.width = width;
        }

        return (
            <Parent
                className={className}
                style={ColumnStyles}
                heights={heights}
                horizontalSpacing={horizontalSpacing}
                verticalSpacing={verticalSpacing}
                {...filterPropsByPrefix(this.props, ["aria-", "data-"])}
            >
                {children}
            </Parent>
        );
    }
}

export default Column;