import React from "react";
import { withRouter } from "react-router-dom";
import Row from "../../components/row";
import UserContext from "../../context/userContext";
import apiClient from "../../util/apiClient";
import { DATA_COUNT, CEILING_BUCKET_SIZE } from "../../util/constants";
import Logger, { logPerformanceTime, logPageMetric } from "../../util/logger";
import { createEMFObject, processFeedbacks, toMiliSeconds, removeRecievedEntriesFromStoreFeedback, getTwoyearOldTimeStamp, getLastUpdateTimestamp, formatDateTime } from "../../util/util";
import BarChart from "./bar-chart/BarChart";
import TopCategory from "./top-category/TopCategory"
import Loader from "../../components/Loader";
import Filter from "../../components/filter/Filter";
import "./FeedbackTrends.scss"
import { PerformanceMarker, PerformanceMeasureName, Page } from '../../typings/enum';
import { subMonths, format, getQuarter, getYear } from "date-fns";
import { getFeedbacks } from "../../util/apiUtils";


class FeedbackTrends extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            initialDataLoaded: false,
            fullDataLoaded: false,
            cSatData: {},
            feedbackCountData: {},
            blockedRevenueData: {},
            topCategoriesData: {},
            feedbacks: [],
            csatQuaterlyData: new Map(),
            csatMonthlyData: new Map(),
            type_labelList:[],
            product_servicesList: [],
            category_servicesList: [],
            type_servicesList: [],
            account_salesTeam: [],
            account_salesVertical: [],
            account_recordType: [],
            account_channel: [],

        }
        this.transformDataForCsat = this.transformDataForCsat.bind(this);
        this.transformDataForFbCount = this.transformDataForFbCount.bind(this);
        this.transformDataForBlockedrevenue = this.transformDataForBlockedrevenue.bind(this);
        this.trnsformDataForTopCategory = this.trnsformDataForTopCategory.bind(this);
        this.getXDataForCategoryCount = this.getXDataForCategoryCount.bind(this);
        this.getYDataForCategoryCount = this.getYDataForCategoryCount.bind(this);
        this.getXDataForBar = this.getXDataForBar.bind(this);
        this.handleCSatViewSelection = this.handleCSatViewSelection.bind(this);
        this.handleFbCountViewSelection = this.handleFbCountViewSelection.bind(this);
        this.getLastMonthsArr = this.getLastMonthsArr.bind(this);
        this.getQuaterlyCsatData = this.getQuaterlyCsatData.bind(this);
        this.getMonthlyCsatData = this.getMonthlyCsatData.bind(this);
        this.getYDataForCsat = this.getYDataForCsat.bind(this);
        this.getYdataForFbCount = this.getYdataForFbCount.bind(this);
        this.getXDataForBlockedrevenue = this.getXDataForBlockedrevenue.bind(this);
        this.getYDataForBlockedrevenue = this.getYDataForBlockedrevenue.bind(this);
        this.loadStaticData = this.loadStaticData.bind(this);
        this.getStaticData = this.getStaticData.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        this.setFilteredFeedbacks = this.setFilteredFeedbacks.bind(this);
        this.saveStaticData = this.saveStaticData.bind(this);
        this.loadAccountData = this.loadAccountData.bind(this);
        this.processAccountInfoData = this.processAccountInfoData.bind(this);
        this.fetchFeedbackList = this.fetchFeedbackList.bind(this);
    }
    async fetchFeedbackList(requestsMade, bucketSize, deltaTime) {
        const { storeProp } = this.props;
        let lastUpdatefilter = `lastUpdateTimestamp:${deltaTime}`
        const response = await getFeedbacks(lastUpdatefilter, requestsMade,bucketSize);
        const { feedbacks, unfilteredFeedbacks } = this.state;
        const {
            data: { results: moreFeedbacks },
        } = response;

        const freshFeedbacks = processFeedbacks(moreFeedbacks);
        const storeFeedbacksAfterDeduplication = removeRecievedEntriesFromStoreFeedback(freshFeedbacks,feedbacks);
        const storeUnfilteredFeedbacksAfterDeduplication = removeRecievedEntriesFromStoreFeedback(freshFeedbacks,unfilteredFeedbacks);
        const updatedFeedbacks = [...freshFeedbacks, ...storeFeedbacksAfterDeduplication];
        const upDatedUnfilteredFb = [...freshFeedbacks, ...storeUnfilteredFeedbacksAfterDeduplication];
        this.setState({
            feedbacks: updatedFeedbacks,
            unfilteredFeedbacks: upDatedUnfilteredFb,
        });
        storeProp?.updateFeedbackList(updatedFeedbacks);
        this.transformDataForCsat('QoQ', false);
        this.transformDataForFbCount('QoQ', false);
        this.transformDataForBlockedrevenue(false);
        this.trnsformDataForTopCategory(false);
    }
    async componentDidMount() {
        const { token, user } = this.context;
        const { storeProp } = this.props;
        this.loadStaticData();
        this.loadAccountData();
        let deltaTime = getTwoyearOldTimeStamp();
        if(storeProp?.lastUpdatedTimeAllFeedback){
            deltaTime = Math.max(deltaTime, parseInt(storeProp.lastUpdatedTimeAllFeedback))
        }
        if (token && user) {
            performance.mark(PerformanceMarker.APILoadStart);
            const lastUpdatefilter = `lastUpdateTimestamp:${deltaTime}`;
            getFeedbacks(lastUpdatefilter, 0, DATA_COUNT)
                .then((response) => {
                    performance.mark(PerformanceMarker.APILoadEnd);
                    performance.measure(
                        `First_${PerformanceMeasureName.APILoadTime}`,
                        PerformanceMarker.APILoadStart,
                        PerformanceMarker.APILoadEnd
                    );
                    logPerformanceTime(`First_${PerformanceMeasureName.APILoadTime}`);
                    const processedfeedbacks = processFeedbacks(response.data.results);
                    const {feedbacksList: storeFeedbacks} = storeProp;
                    const storeFeedbacksAfterDeduplication = removeRecievedEntriesFromStoreFeedback(processedfeedbacks,storeFeedbacks);
                    const mergedFeedbacks = [...processedfeedbacks, ...storeFeedbacksAfterDeduplication];
                        this.setState({
                            feedbacks: mergedFeedbacks,
                            unfilteredFeedbacks: mergedFeedbacks,
                            initialDataLoaded: true
                        });
                        this.transformDataForCsat('QoQ', false);
                        this.transformDataForFbCount('QoQ', false);
                        this.transformDataForBlockedrevenue(false);
                        this.trnsformDataForTopCategory(false);
                        let requestsMade = DATA_COUNT;
                        let bucketSize = DATA_COUNT;
                        const appPromise = [];
                        if(requestsMade >= response.data.totalItemsCount) {
                            this.setState({
                                fullDataLoaded: true
                            })
                        }
                        while (requestsMade < response.data.totalItemsCount) {
                            bucketSize = CEILING_BUCKET_SIZE;

                            appPromise.push(this.fetchFeedbackList(requestsMade, bucketSize, deltaTime));
                            requestsMade += bucketSize;
                        }
                        Promise.all(appPromise).then((res)=>{
                            this.setState({
                                fullDataLoaded: true
                              })
                            logPageMetric(Page.Trends);
                        })
                        performance.mark(PerformanceMarker.AllAPICallEnd);
                        performance.measure(
                            `Total_${PerformanceMeasureName.APILoadTime}`,
                            PerformanceMarker.APILoadStart,
                            PerformanceMarker.AllAPICallEnd
                        );
                        logPerformanceTime(`Total_${PerformanceMeasureName.APILoadTime}`);
                })
                .catch((error) => {
                    Logger.sessionError(
                        `Feedback Trends componentDidMount 
                        ${error}`,
                        {
                            browserUrl: window.location.href,
                        }
                    );
                    this.setState({ isError: true });
                })
                .then(() => {
                    this.setState({ isLoading: false });
                });
        }
        Logger.sessionInfo("Trends tab visited", {
            emfLog: createEMFObject("Trends Visit", 1, "Count"),
        });
    }

    componentDidUpdate(prevProp, prevState) {
        const { storeProp } = this.props;
        const { twoYearLength, lastUpdatedTimeAllFeedback } = storeProp;
        const { user } = this.context;
        console.log(this.state.fullDataLoaded, prevState.fullDataLoaded)
        if( this.state.fullDataLoaded && prevState.fullDataLoaded !== this.state.fullDataLoaded){
          Logger.sessionInfo("full data loaded", prevState.fullDataLoaded, this.state.fullDataLoaded );
          Logger.sessionInfo(`verify cache validity in trends: ${user.email},${this.state.feedbacks.length},${twoYearLength},${formatDateTime(lastUpdatedTimeAllFeedback)},${formatDateTime(Math.floor(Date.now()/1000))}`);
          Logger.sessionInfo(`verifying cache reliability: ${user.email},${this.state.feedbacks.length},${twoYearLength},${formatDateTime(lastUpdatedTimeAllFeedback)},${formatDateTime(Math.floor(Date.now()/1000))}`);
          //validate final loaded data:
            if(twoYearLength!==this.state.feedbacks.length) {
                storeProp?.updateFeedbackList([]);
                storeProp?.setlastUpdatedTimeAllFeedback("");
                this.setState({
                    isError: true
                })
            }
            const latestUpdateTimeStamp = getLastUpdateTimestamp(this.state.feedbacks);
            storeProp?.updateFeedbackList(this.state.feedbacks);
            storeProp?.setlastUpdatedTimeAllFeedback(latestUpdateTimeStamp)
        }
    
    }

    loadAccountData() {
        apiClient
            .get("/accountinfo")
            .then(response => {
                this.processAccountInfoData(response?.data?.results)
            })
            .catch(err => {
                Logger.sessionError(`error occured while fetching account data ${err}`, {
                    browserUrl: window.location.href,
                    emfLog: createEMFObject(`FetchingAccountDataFailed`, 1, "Count", ),
                });
                this.setState({ isError: true });
            })
    }

    processAccountInfoData(accountInfoData) {
        for (const [accountDataKey, valueArray] of Object.entries(accountInfoData)) {
            let obj = [];
            valueArray.forEach(value => {
                let data = {
                    lineItem: value,
                };
                obj.push(data);
            })
            let stateObj = {};
            const stateDield = "account_" + accountDataKey;
            stateObj[stateDield] = obj;
            this.setState(stateObj);
        }
    }

    loadStaticData() {
        const params = {};
        apiClient
            .get("/static?size=10000", { params })
            .then((response) => {
                this.setState({
                    staticData: response?.data?.results?.map((stat) => {
                        return Object.assign(stat.fields, { id: stat.id });
                    }),
                }, () => {
                    let feedbackProducts = this.getStaticData("PRODUCT_SERVICES");
                    let obj = [];
                    feedbackProducts = feedbackProducts.filter(
                        (word) => word !== "All Products"
                    );
                    let uSet = new Set(feedbackProducts);
                    feedbackProducts = Array.from(uSet);
                    feedbackProducts.sort();
                    feedbackProducts.forEach((element) => {
                        let data = {
                            lineItem: element,
                        };
                        obj.push(data);
                    });
                    this.setState({
                        product_servicesList: obj
                    })
                    this.saveStaticData("FEEDBACK_CATEGORY", "category_servicesList");
                    this.saveStaticData("FEEDBACK_TYPE", "type_servicesList");
                    this.saveStaticData("FEEDBACK_LABEL", "type_labelList");
                });
                
            })
            .catch((error) => {
                Logger.sessionError(`Trends static ${error}`, {
                    browserUrl: window.location.href,
                });
                this.setState({ isError: true });
            })
            .then(() => {
                this.setState({ isLoading: false });
            });
    }

    saveStaticData(field, stateField) {
        let fieldData = this.getStaticData(field);
        fieldData.sort();
        let obj = [];
        fieldData.forEach((element) => {
            let data = {
            lineItem: element,
            };
            obj.push(data);
        });
        let stateObj = {};
        stateObj[stateField] = obj;
        this.setState(stateObj);
    }

    getStaticData(type, parent) {
        // type should be either CATEGORY or PRODUCT
        let { staticData } = this.state;
        let dataArr = [];
        let entities;
        if (staticData.length) {
            if (parent) {
                entities = staticData.filter((obj) => {
                    return obj.docType === type && obj.parent === parent;
                });
            } else {
                entities = staticData.filter((obj) => {
                    return obj.docType === type;
                });
            }

            for (let data of entities) {
                dataArr.push(data.value);
            }
        }
        return dataArr;
    }

    getLastMonthsArr(scale) {
        const months = [];
        const quarters = [];
        if (scale === 'quarterly') {
            for (let i = 0; i <= 23; i = i + 3) {
                const date = subMonths(new Date(), i)
                const quarter = `Q${getQuarter(date)}'${getYear(date)}`
                quarters.unshift(quarter);
            }

        }
        else {
            for (let i = 0; i <= 23; i++) {
                const date = format(subMonths(new Date(), i), 'MMM-yy')
                const key = date.split('-').join("'")
                months.unshift(key);
            }
        }
        return scale === 'quarterly' ? quarters : months;
    }

    getYDataForCsat(view, trasformedData, xData) {
        let yData = [];
        xData.forEach(xPeriod => {
            if (trasformedData.has(xPeriod)) {
                yData.push(trasformedData.get(xPeriod).avgCsat)
            }
            else {
                yData.push(0);
            }
        })
        return yData;
    }

    getXDataForBar(view) {
        if (view === 'QoQ') {
            return this.getLastMonthsArr('quarterly')
        }
        else {
            return this.getLastMonthsArr('monthly')
        }
    }

    transformDataForCsat(view = 'QoQ', loader = true) {
        const cSatTransformedData = view === 'QoQ' ? this.getQuaterlyCsatData() : this.getMonthlyCsatData();
        const xData = this.getXDataForBar(view);
        const yData = this.getYDataForCsat(view, cSatTransformedData, xData);
        const cSatData = {
            metrics: [
                {
                    "plotDataType": "BAR",
                    "xData": xData,
                    "yData": yData,
                    "formatType": "NUMBER",
                    "id": "csat",
                    "title": "Average CSAT",
                    "hintTitle": "Average CSAT for the period",
                    "hintText": "Average CSAT for the period",
                    "shortTitle": "Average CSAT",
                    "overrides": {
                        "yAxis": {
                            "domain": { y: [0, 5] }
                        }
                    }
                }
            ],
            chartheading: "Customer Satisfaction Score",
            view,
            loadingState: loader ? {
                status: "LOADING",
                message: "Generating View"
            } : { status: "SUCCEEDED" },
            handleViewSelection: this.handleCSatViewSelection
        };
        this.setState({ cSatData });
    }

    getMonthlyCsatData() {
        const { feedbacks } = this.state;
        const csatMonthlyData = new Map();
        feedbacks.forEach(feedback => {
            const date = format(toMiliSeconds(feedback.createdTimestamp),'MMM-yy')
            const key = date.split('-').join("'")

            if (!csatMonthlyData.has(key)) {
                csatMonthlyData.set(key, {
                    csatTotal: Number(feedback.csatScore),
                    totalFeedbackCount: 1,
                    avgCsat: Number(feedback.csatScore)
                })
            }
            else {
                const data = csatMonthlyData.get(key);
                let { csatTotal, totalFeedbackCount, avgCsat } = data;
                csatTotal += Number(feedback.csatScore);
                totalFeedbackCount += 1;
                avgCsat = Number((csatTotal / totalFeedbackCount).toFixed(2));
                csatMonthlyData.set(key, {
                    csatTotal,
                    totalFeedbackCount,
                    avgCsat
                })
            }
        })
        this.setState({
            csatMonthlyData
        })
        return csatMonthlyData;
    }

    getQuaterlyCsatData() {
        const { feedbacks } = this.state;
        const csatQuaterlyData = new Map();
        feedbacks.forEach(feedback => {
            const date = toMiliSeconds(feedback.createdTimestamp)
            const key = `Q${getQuarter(date)}'${getYear(date)}`

            if (!csatQuaterlyData.has(key)) {
                csatQuaterlyData.set(key, {
                    csatTotal: Number(feedback.csatScore),
                    totalFeedbackCount: 1,
                    avgCsat: Number(feedback.csatScore)
                })
            }
            else {
                const data = csatQuaterlyData.get(key);
                let { csatTotal, totalFeedbackCount, avgCsat } = data;
                csatTotal += Number(feedback.csatScore);
                totalFeedbackCount += 1;
                avgCsat = Number((csatTotal / totalFeedbackCount).toFixed(2));
                csatQuaterlyData.set(key, {
                    csatTotal,
                    totalFeedbackCount,
                    avgCsat
                })
            }
        })
        this.setState({
            csatQuaterlyData
        })
        return csatQuaterlyData;
    }

    transformDataForFbCount(view = 'QoQ', loader=true) {
        const cSatTransformedData = view === 'QoQ' ? this.getQuaterlyCsatData() : this.getMonthlyCsatData();
        const xData = this.getXDataForBar(view);
        const yData = this.getYdataForFbCount(cSatTransformedData, xData);
        const feedbackCountData = {
            metrics:[
                {
                    "plotDataType": "BAR",
                    "xData": xData,
                    "yData": yData,
                    "formatType": "NUMBER",
                    "id": "feedbackcount",
                    "title": "Feedback Count",
                    "hintTitle": "Feedback Count for the period",
                    "hintText": "Feedback Count for the period",
                    "shortTitle": "Feedback Count",
                }
            ],
            chartheading: "Feedback Count",
            view,
            loadingState: loader ? {
                status: "LOADING",
                message: "Generating View"
            } : { status: "SUCCEEDED" },
            handleViewSelection: this.handleFbCountViewSelection
        };
        this.setState({ feedbackCountData });
    }

    getYdataForFbCount(trasformedData, xData) {
        let yData = [];
        xData.forEach(xPeriod => {
            if (trasformedData.has(xPeriod)) {
                yData.push(trasformedData.get(xPeriod).totalFeedbackCount)
            }
            else {
                yData.push(0);
            }
        })
        return yData;
    }

    trnsformDataForTopCategory(loader=true) {
        const { feedbacks } = this.state;
        const feedbackCountForCategory = new Map();
        feedbacks.forEach(feedback => {
            let { feedBackCategoryType } = feedback;
            if (feedBackCategoryType === "" || feedBackCategoryType === undefined) {
                feedBackCategoryType = 'Unspecified';
            }
            if (feedbackCountForCategory.has(feedBackCategoryType)) {
                const count = feedbackCountForCategory.get(feedBackCategoryType);
                feedbackCountForCategory.set(feedBackCategoryType, count + 1);
            }
            else {
                feedbackCountForCategory.set(feedBackCategoryType, 1);
            }
        })
        let topCategories;
        const categoriesArr = Array.from(feedbackCountForCategory.keys());
        let topCategoriesTotalCount = 0;
        if (categoriesArr.length <= 5) {
            topCategories = new Map(feedbackCountForCategory);
        } else {
            topCategories = new Map();
            for (let i = 0; i < 5; i++) {
                let biggestCount = Number.MIN_SAFE_INTEGER;
                let biggestCountKey;
                for (let j = 0; j < categoriesArr.length; j++) {
                    if (feedbackCountForCategory.get(categoriesArr[j]) >= biggestCount
                        && !topCategories.has(categoriesArr[j])) {
                        biggestCount = feedbackCountForCategory.get(categoriesArr[j]);
                        biggestCountKey = categoriesArr[j];
                    }
                }
                topCategoriesTotalCount = topCategoriesTotalCount + biggestCount;
                topCategories.set(biggestCountKey, biggestCount);
            }
            topCategories.set('Others', feedbacks.length - topCategoriesTotalCount);
        }
        const xData = this.getXDataForCategoryCount(topCategories);
        const yData = this.getYDataForCategoryCount(xData, topCategories);
        const topCategoriesData = {
            loadingState: loader ? {
                status: "LOADING",
                message: "Generating View"
            } : { status: "SUCCEEDED" },
            xData,
            yData
        }
        this.setState({
            topCategoriesData
        })
    }

    getXDataForCategoryCount(topCategories) {
        let xData = Array.from(topCategories.keys());
        return xData;
    }

    getYDataForCategoryCount(xData, topCategories) {
        const { feedbacks } = this.state;
        let yData = [];
        for (let i = 0; i < xData.length; i++) {
            if (feedbacks.length === 0) {
                yData[i] = 0;
            } else {
                const res = Math.round((topCategories.get(xData[i]) / feedbacks.length) * 100)
                yData[i] = res;
            }
        }
        return yData;
    }

    transformDataForBlockedrevenue( loader=true) {
        const { feedbacks } = this.state;
        const blockedRevData = new Map();
        feedbacks.forEach(feedback => {
            let { salesTeam, revenueImpacted } = feedback;
            if(!salesTeam){
                salesTeam = "Others"
            }
            let revImpacted = 0;
            if (revenueImpacted) {
                revImpacted = Number(revenueImpacted);
            }
            if (blockedRevData.has(salesTeam)) {
                const revCountByteam = blockedRevData.get(salesTeam);
                blockedRevData.set(salesTeam, revCountByteam + revImpacted);
            }
            else {
                blockedRevData.set(salesTeam, revImpacted);
            }
        })
        const xData = this.getXDataForBlockedrevenue();
        const yData = this.getYDataForBlockedrevenue(xData, blockedRevData);
        const blockedRevenueData = {
            metrics:[
                {
                    "plotDataType": "BAR",
                    "xData": xData,
                    "yData": yData,
                    "formatType": "CURRENCY",
                    "currencyCode": "USD",
                    "id": "blockedrevenue",
                    "title": "Total Blocked Revenue",
                    "hintTitle": "Total Blocked Revenue for the period",
                    "hintText": "Total Blocked Revenue for the period",
                    "shortTitle": "Total Blocked Revenue",
                }
            ],
            chartheading: "Total Blocked Revenue",
            loadingState: loader ? {
                status: "LOADING",
                message: "Generating View"
            } : { status: "SUCCEEDED" }
        };
        this.setState({ blockedRevenueData });
    }

    getXDataForBlockedrevenue() {
        const { feedbacks } = this.state;
        const xData = new Set();
        feedbacks.forEach(feedback => {
            const team = feedback.salesTeam
            if (team) {
                xData.add(team);
            }
            else {
                xData.add('Others');
            }
        })
        return Array.from(xData);
    }

    getYDataForBlockedrevenue(xData, blockedRevData) {
        const yData = [];
        xData.forEach(team => {
            yData.push(blockedRevData.get(team))
        })
        return yData;
    }

    handleFbCountViewSelection(view) {
        Logger.sessionInfo("Trends BarChart View Selection changed", {
            emfLog: createEMFObject("ViewChangeClick", 1, "Count", {MoMQoQAction:"TrendsFbCount"}),
          });
        this.transformDataForFbCount(view, false);
    }

    handleCSatViewSelection(view) {
        Logger.sessionInfo("Trends BarChart View Selection changed", {
            emfLog: createEMFObject("ViewChangeClick", 1, "Count", {MoMQoQAction:"TrendsCSAT"}),
          });
        this.transformDataForCsat(view, false);

    }

    resetFilter() {
        const { unfilteredFeedbacks } = this.state
        this.setState({
            feedbacks: unfilteredFeedbacks,
        })
    }
    setFilteredFeedbacks(filteredFeedbacks, clearFilters = false) {
        Logger.sessionInfo("Filter applied on feedback Trends Page", {
            emfLog: createEMFObject("FilterApplied", 1, "Count", {FilterAction:"Trends"}),
          });
        const { unfilteredFeedbacks } = this.state;
        if (clearFilters) {
            this.setState({
                feedbacks: unfilteredFeedbacks
            }, () => {
                this.transformDataForCsat('QoQ',false);
                this.transformDataForFbCount('QoQ',false);
                this.transformDataForBlockedrevenue(false);
                this.trnsformDataForTopCategory(false);
            })
        } else {
            this.setState({
                feedbacks: filteredFeedbacks
            }, () => {
                this.transformDataForCsat('QoQ',false);
                this.transformDataForFbCount('QoQ',false);
                this.transformDataForBlockedrevenue(false);
                this.trnsformDataForTopCategory(false);
            })
        }
    }

    render() {
        const { initialDataLoaded, cSatData, feedbackCountData, blockedRevenueData, topCategoriesData, feedbacks, unfilteredFeedbacks, product_servicesList, category_servicesList, type_labelList, account_recordType, account_salesVertical } = this.state;
        const filterItems = [
            {
                itemName: "Product/Service",
                dataFieldName: "feedBackProductServicesType",
                filterComponent: "multiselectDropdown",
                dropdownItems: product_servicesList
            },
            {
                itemName: "Feedback Category",
                dataFieldName: "feedBackCategoryType",
                filterComponent: "multiselectDropdown",
                dropdownItems: category_servicesList
            },
            {
                itemName: "Feedback Label",
                 dataFieldName: "selectedLabelsPayload",
                filterComponent: "multiselectDropdown",
                dropdownItems: type_labelList
            },
            {
                itemName: "Account Record Type",
                dataFieldName: "accountRecordType",
                filterComponent: "multiselectDropdown",
                dropdownItems: account_recordType
            },
            {
                itemName: "Sales Team",
                dataFieldName: "salesTeam",
                filterComponent: "textFilter",
            },
            {
                itemName: "Vertical",
                dataFieldName: "accountVertical",
                filterComponent: "multiselectDropdown",
                dropdownItems: account_salesVertical
            },
            {
                itemName: "Submitted by",
                dataFieldName: "submittedByName",
                filterComponent: "textFilter",
            },
            {
                itemName: "Account Name",
                dataFieldName: "accountName",
                filterComponent: "textFilter",
            },
            {
                itemName: "Locale",
                dataFieldName: "accountMarket",
                filterComponent: "textFilter"
            }
        ]
        if (!initialDataLoaded) {
            return (
                <div className="loader">
                    <Loader />
                </div>
            )
        }
        return (
            <React.Fragment>
                <Row wrap="up" spacingInset="small" alignmentHorizontal="right" >
                    <Filter filterItems={filterItems} filterReset={this.resetFilter} feedbacks={feedbacks} unfilteredFeedbacks={unfilteredFeedbacks} setFilteredFeedbacks={this.setFilteredFeedbacks} />
                </Row>
                <Row alignmentVertical="top" spacingInset="500">
                    <BarChart
                        barData={cSatData}
                        helpText="The Customer Satisfaction Score, or CSAT, is a customer experience metric that measures happiness with a product, service, or customer support interaction. This score was calculated by taking the average of the customer satisfaction rating provided by the feedback form, across all feedback. A rating of 1 represents a dissatisfied consumer while a rating of a 5 represents an extremely satisfied consumer."
                    />
                    <BarChart
                        barData={feedbackCountData}
                        helpText="Feedback Count shows the total number of feedback submissions over a given time period, or volume of feedback submitted."
                    />
                </Row>
                <Row alignmentVertical="top" spacingInset="500">
                    <BarChart
                        barData={blockedRevenueData}
                        helpText="The Total Blocked Revenue by Sales Team shows the sum of blocked revenue from feedback submitters by vertical team, over a specific period of time. This metric helps identify areas of improvement, new revenue streams, or missed opportunities."
                    />
                    <TopCategory
                        topCategoriesData={topCategoriesData}
                        helpText="The Top 5 Feedback Categories metric highlights categories that have received the highest volume of feedback submissions. Analyzing these top categories provides insights into customer pain points, concerns, and areas that require attention."
                    />
                </Row>
            </React.Fragment>
        )
    }
}

FeedbackTrends.contextType = UserContext;
export default withRouter(FeedbackTrends);