import React from 'react';
import { Popper, Button, Dropdown, DropdownItem, DropdownItemGroup, Pill, TextButton } from '@amzn/storm-ui';
import FilterDialog from './FilterDialog';
import Logger from "../../util/logger";
import {filterCallbacks} from './filterLogic';
import { DateRangePicker } from '@amzn/storm-ui-date-picker';
import { subMonths, format } from 'date-fns';
import { toUnix, createEMFObject } from '../../util/util';

class Filter extends React.Component {
    constructor(props) {
        super(props);
        this.anchorRef = React.createRef();
        this.state = {
            active: false,
            openDialog: false,
            dropdownValue: {},
            activeFilterList: [],
            dialogData: null,
            activeFilterndex: 0,
            showDateRangePopper: false,
            selectedRange: {label: "Lifetime", period: 'LIFETIME', start: subMonths(new Date(), 24), end: new Date()},
            selectedPreset: {label: "Lifetime", period: 'LIFETIME', start: subMonths(new Date(), 24), end: new Date()}
        }
        this.handleClose = this.handleClose.bind(this);
        this.openFilterDialog = this.openFilterDialog.bind(this);
        this.closeFilterDialog = this.closeFilterDialog.bind(this);
        this.renderFilterPills = this.renderFilterPills.bind(this);
        this.removeFilterFromList = this.removeFilterFromList.bind(this);
        this.removeAllFilterFromList = this.removeAllFilterFromList.bind(this);
        this.updateActiveFilterList = this.updateActiveFilterList.bind(this);
        this.applyFilters = this.applyFilters.bind(this);
        this.renderFilterDialog = this.renderFilterDialog.bind(this);
        this.saveFilterToLocalStorage = this.saveFilterToLocalStorage.bind(this);
        this.renderDateRangePickerButton = this.renderDateRangePickerButton.bind(this);
        this.renderDateRangePicker = this.renderDateRangePicker.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.applyDateRangeFilter = this.applyDateRangeFilter.bind(this);
    }
    componentDidUpdate(prevProps, prevState) {
        const {unfilteredFeedbacks: prevUnfilteredFeedbacks} = prevProps;
        const { unfilteredFeedbacks } = this.props;
        if( unfilteredFeedbacks.length !== prevUnfilteredFeedbacks.length ) {
            this.applyFilters()
        }
    }
    componentDidMount() {
        let filterList;
        let dateRange;
        try{
            filterList = JSON.parse(localStorage.getItem('trendsFilter') || "[]");
            dateRange = JSON.parse(localStorage.getItem("trendsDateRange")) || "{}";
            Logger.sessionInfo(`parsed filterList: ${filterList} `);
            Logger.sessionInfo(`parsed trendsDateRange: ${dateRange} `);
        } catch (e) {
            Logger.sessionInfo(`parsed filterList with error: ${filterList} `);
            Logger.sessionInfo(`parsed trendsDateRange with error: ${dateRange}`);
            Logger.sessionError(`Not able to parse JSON String , ${e}`);
        }
        if(filterList){
            Logger.sessionInfo("Filter applied on trends Page", {
                emfLog: createEMFObject("LoadSavedFilter", 1, "Count", {FilterAction:"TrendsPage"}),
              });
            if(dateRange.filterPrefixLabel){
                this.setState({
                    selectedRange: dateRange.selectedRange
                },()=>this.updateActiveFilterList(filterList))
            }
            else{
                this.updateActiveFilterList(filterList);
            }
        }
    }
    saveFilterToLocalStorage(filterList) {
        Logger.sessionInfo("Filter applied on trends Page", {
            emfLog: createEMFObject("SavedFilter", 1, "Count", {FilterAction:"TrendsPage"}),
          });
        localStorage.setItem('trendsFilter',JSON.stringify(filterList));
    }
    handleClose() {
        this.setState({ active: false });
    }
    openFilterDialog(filterItem, dialogData = null, activeFilterndex = 0) {
        this.setState({
            openDialog: true,
            dialogData,
            activeFilterndex,
            dropdownValue: filterItem
        })
    }
    closeFilterDialog() {
        this.setState({
            openDialog: false
        })
    }
    removeFilterFromList(key) {
        const { activeFilterList } = this.state;
        const updatedActiveFilterList = [...activeFilterList];
        updatedActiveFilterList.splice(key, 1);
        this.setState({
            activeFilterList: updatedActiveFilterList,
        }, this.applyFilters);
        localStorage.setItem('trendsFilter',JSON.stringify(updatedActiveFilterList));
        Logger.sessionInfo("Filter applied on trends Page", {
            emfLog: createEMFObject("SavedFilter", 1, "Count", {FilterAction:"TrendsPage"}),
          });
    }
    removeAllFilterFromList() {
        const { setFilteredFeedbacks } = this.props;
        this.setState({
            activeFilterList: [],
            selectedRange: {label: "Lifetime", period: 'LIFETIME', start: subMonths(new Date(), 24), end: new Date()}
        }, () => setFilteredFeedbacks([], true));
        localStorage.setItem('trendsFilter',[]);
        localStorage.setItem('trendsDateRange',"{}");
        Logger.sessionInfo("Filter applied on trends Page", {
            emfLog: createEMFObject("SavedFilter", 1, "Count", {FilterAction:"TrendsPage"}),
          });
    }
    updateActiveFilterList(updatedFilterList) {
        this.setState({
            activeFilterList: updatedFilterList
        }, this.applyFilters);
        Logger.sessionInfo("Filter applied on trends Page", {
            emfLog: createEMFObject("SavedFilter", 1, "Count", {FilterAction:"TrendsPage"}),
          });
        for(const param in updatedFilterList){
            console.log(param);
            Logger.sessionInfo("Filter applied on trends Page", {
                emfLog: createEMFObject("SavedFilter", 1, "Count", {FilterAction:"TrendsPage", filterModel:param}),
            });
        }
        localStorage.setItem('trendsFilter',JSON.stringify(updatedFilterList));
    }
    renderFilterPills() {
        const { activeFilterList } = this.state;
        return (
            <React.Fragment>
                <TextButton onClick={this.removeAllFilterFromList}>Remove All</TextButton>
                {activeFilterList.map((activeFilter, index) => {
                    const { filterField, filterPrefixLabel, filterQueryVal } = activeFilter;
                    if(filterPrefixLabel === 'dateRangeFilter'){
                        return null
                    } 
                    const { itemName } = filterField;
                    let label;
                    if (typeof filterQueryVal === "string") {
                        const trimmedFilterQueryVal = filterQueryVal.length <= 10 ? filterQueryVal : filterQueryVal.substring(0, 11) + '...'
                        label = itemName + ' ' + filterPrefixLabel + ': ' + trimmedFilterQueryVal;
                    }
                    else if (typeof filterQueryVal == "number") {
                        let fullDate = new Date(filterQueryVal * 1000).getDate() + '-' + (new Date(filterQueryVal * 1000).getMonth() + 1) + '-' + new Date(filterQueryVal * 1000).getFullYear();
                        label = itemName + ' ' + filterPrefixLabel + ': ' + fullDate
                    }
                    return <Pill
                        label={label}
                        key={index}
                        onClose={() => this.removeFilterFromList(index)}
                        onClick={() => {
                            this.openFilterDialog(filterField, activeFilter, index)
                        }}
                    />
                })}
            </React.Fragment>

        )
    }
    renderFilterDialog() {
        const { dropdownValue, dialogData, activeFilterndex, activeFilterList } = this.state;
        return (
            <FilterDialog
                closeFilterDialog={this.closeFilterDialog}
                filterField={dropdownValue}
                updateActiveFilterList={this.updateActiveFilterList}
                activeFilterList = {activeFilterList}
                dialogData={dialogData}
                activeFilterndex={activeFilterndex}
                removeFilterFromList={this.removeFilterFromList}
            />
        )
    }
    applyFilters() {
        const { activeFilterList } = this.state;
        const { unfilteredFeedbacks, setFilteredFeedbacks } = this.props;
        let filteredFeedbacks = [...unfilteredFeedbacks]
        activeFilterList.forEach(filterObj => {
            if(filterObj.filterPrefixLabel==="dateRangeFilter"){
                let {selectedRange:{start,end}} = filterObj;
                if(typeof start === "string"){
                    start = new Date(start);
                    end = new Date(end);
                }
                filteredFeedbacks = filteredFeedbacks.filter(feedback => {
                    return parseInt(feedback.createdTimestamp,10) > toUnix(start) && parseInt(feedback.createdTimestamp,10) < toUnix(end)
                })
            } else {
                const { filterCallback, filterQueryVal, dataFieldName } = filterObj
                filteredFeedbacks = filterCallbacks[filterCallback](filterQueryVal, dataFieldName, [...filteredFeedbacks]);
            }
        })
        setFilteredFeedbacks(filteredFeedbacks);
    }
    renderDateRangePickerButton() {
        const { selectedRange } = this.state;
        return (<Button
            aria-label="Open date range picker"
            onClick={this.handleToggle}
            >
            {selectedRange.label? `Date Range: ${selectedRange.label}` : `Date Range: ${format( new Date(selectedRange.start) ,'dd-MMM-yyyy')} to ${format(new Date(selectedRange.end) ,'dd-MMM-yyyy')}`}
        </Button>)
    }
    renderDateRangePicker() {
        const { selectedRange } = this.state;
        return (
            <DateRangePicker
                selected={selectedRange}
                minDate={subMonths(new Date(), 24)}
                maxDate={new Date()}
                onSubmit={(event, submittedPreset)=>{
                    this.setState({
                        selectedRange: submittedPreset
                    },this.applyDateRangeFilter)
                    this.handleToggle();
                    
                }}
                onCancel={()=>{
                    this.setState({
                        showDateRangePopper: false
                    })
                }}
                cancelText="Cancel"
                saveText="Save"
                dropdownLabelOverride={()=>{

                }}
                onCloseButtonClick={() => {
                    this.setState({
                        showDateRangePopper: false
                    })
                }}
            />
        )
    }
    handleToggle() {
        const {showDateRangePopper} = this.state;
        this.setState({
            showDateRangePopper: !showDateRangePopper
        });
    }

    applyDateRangeFilter() {
        const { selectedRange, activeFilterList } = this.state;
        const activeFilterObj = {
            filterPrefixLabel: "dateRangeFilter",
            selectedRange
        }
        localStorage.setItem('trendsDateRange',JSON.stringify(activeFilterObj));
        let rangeIndex = null;
        activeFilterList.forEach((filter,index)=>{
            if(filter.filterPrefixLabel==="dateRangeFilter"){
                rangeIndex = index;
            }
        });
        let updatedFilterList = [...activeFilterList];
        if(rangeIndex!==null){
            updatedFilterList.splice(rangeIndex, 1);
        }
        updatedFilterList.push(activeFilterObj);
        this.updateActiveFilterList(updatedFilterList);
    }

    render() {
        const { openDialog, activeFilterList, showDateRangePopper } = this.state;
        const { filterItems } = this.props;
        return (
            <React.Fragment>
                {openDialog && this.renderFilterDialog()}
                {activeFilterList.length > 0 && this.renderFilterPills()}
                <Popper
                    id="date-range-picker-popper"
                    isOpen={showDateRangePopper}
                    withArrow={false}
                    position="bottom"
                    align="start"
                    autoFocus
                    trigger={this.renderDateRangePickerButton()}
                >
                    {this.renderDateRangePicker()}
                </Popper>
                <Dropdown
                    onChange={filterItem => this.openFilterDialog(filterItem)}
                    onOverrideLabel={() => 'filter'}
                >
                    <DropdownItemGroup label="Filters">
                        {filterItems.map((element, index) => (
                            <DropdownItem key={index} value={element}>{element.itemName}</DropdownItem>
                        ))}
                    </DropdownItemGroup>
                </Dropdown>
            </React.Fragment>
        )
    }
}

export default Filter;