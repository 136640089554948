import React, { useState } from "react";
import {
    Modal,
    Button,
    Alert
} from "@amzn/storm-ui";
import AttachToThemeDialog from "./AttachToThemeDialog";
import Row from "../../components/row";
import Loader from "../../components/Loader";
import Column from "../../components/column";

const AttachToThemeDialogModal = (props) => {
    const { closeAttachThemeModal, handleAttachTheme } = props;
    const [disabled, setDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const onSubmit = () => {
        try {
            setIsLoading(true);
            handleAttachTheme();
        } catch(error) {
            setIsLoading(false)
            setIsError(true);
        }
    }

    const renderForm = () => {
        if (isLoading || isError) {
            return (
              <div className="container-container-small">
                {isLoading && <Loader />}
                {isError && (
                  <Alert type="error">Something went wrong. Please try again.</Alert>
                )}
              </div>
            );
          }
        return (
            <Column className="container-container-small">
                <Row spacingInset="300" widths="100%">
                <AttachToThemeDialog {...props} changeApplyButtonDisabledProperty={setDisabled} />
                </Row>
                <Row alignmentHorizontal="end" spacingInset="300" width={"300px"}>
                    <Button onClick={closeAttachThemeModal}>Cancel</Button>
                    <Button disabled={disabled} primary={true} onClick={onSubmit}>Proceed</Button>
                </Row>
                </Column>
        )
    }

    return (
        <Modal
            width="50%"
            isOpen={true}
            header={`Attach to theme`}
            onClose={closeAttachThemeModal}
        >
            {
                renderForm()
            }
            
        </Modal>
    )
}

export default AttachToThemeDialogModal