import React from "react";
import { useHistory } from "react-router";
import {
    Modal,
} from "@amzn/storm-ui";
import CreateLabelsForm from "../admin-feedback-labels/CreateLabelsForm";
import { CreateFeedbackCategorizationForm } from "../admin-feedback-categorization/CreateFeedbackCategorizationForm";
import CreateTemplateForm from "../admin-feedback-templates/CreateTemplateForm";
import DeleteCategorizationForm from "../admin-feedback-categorization/DeleteCategorizationForm";

const AdminModalWrapper = (props) => {
    const { mode, adminEntity } = props;
    const history = useHistory();


    const closeCreateModal = () => {
        if(adminEntity==="labels"){
            history.push('/admin/labels');
        } else if(adminEntity==="categorization") {
            history.push('/admin/categorization');
        } else if(adminEntity==="template") {
            history.push('/admin/template');
        } else {
            history.push('/admin');
        }
    }

    return (
        <Modal
            isOpen={true}
            header={`${mode} ${adminEntity}`}
            onClose={closeCreateModal}
        >
            {adminEntity==="labels" && <CreateLabelsForm {...props} />}
            { adminEntity==="template" && <CreateTemplateForm {...props} />}
            {mode !== "Delete" && adminEntity==="categorization" && <CreateFeedbackCategorizationForm {...props} />}
            {mode==="Delete" && adminEntity==="categorization" && <DeleteCategorizationForm {...props} />}
        </Modal>
    )
}

export default AdminModalWrapper