import Logger from "./logger";
import apiClient from './apiClient'
import { PREV_2_YEARS } from "./constants";
import {createEMFObject} from "./util";


// Theme APIs

export const getThemebyId = async (id) => {
    const theme = await apiCaller(`/themes/${id}`, "GET", {})
    return theme;
}

export const getThemes = async (from, size) => {
    const themes = await apiCaller('/themes', "GET", {
        from,
        size
    })
    return themes
}

export const createTheme = async (submitObj) => {
    const theme = await apiCaller("/theme", "POST", submitObj);
    return theme;
}

export const updateTheme = async (submitObj,id, queryParam="") => {
    const theme = await apiCaller(`/theme/${id}${queryParam}`, "PUT", submitObj);
    return theme;
}

export const bulkUpdateTheme = async (submitObj) => {
    const feedback = await apiCaller("/updatethemes", "PUT", submitObj);
    return feedback;
}
export const deleteTheme = async (id) => {
    const theme = await apiCaller(`/theme/${id}`, "DELETE");
    return theme;
}

// Theme Feedback Mapping APIs

export const createFeedbackThemeMapping = async (submitObj) => {
    const feedbackThemeMapping = await apiCaller('/thememapping', "POST", submitObj)
    return feedbackThemeMapping;
}

export const getAllFeedbackThemeMappings = async () => {
    const themeMappings = await apiCaller('/thememappings', "GET", {
        from: 0,
        size: 50,
        sortby: "payload.createdTimestamp",
        filters: `days:${PREV_2_YEARS}`
    })
    return themeMappings;
}

export const getFeedbackThemeMapping = async (id) => {
    const fbMapping = await apiCaller(`/thememappings/${id}`, "GET", {
        from: 0,
        size: 50,
        sortby: "payload.createdTimestamp",
    })
    return fbMapping;
}



// review mapping APIs
export const getCases = async (from, size) => {
    const cases = await apiCaller(`/mappingapprovals`, "GET", {
        from,
        size,
        sortby: "payload.createdTimestamp",
        filters: `reviewStatus:pending`
    })
    return cases;
}

export const createOverrideMappingCase = async (submitObj) => {
    const overrideMappingCase = await apiCaller('/mappingapproval', "POST", submitObj)
    return overrideMappingCase;
}

export const updateOverrideMappingCase = async (submitObj) => {
    const overrideMappingCase = await apiCaller('/mappingapproval', "PUT", submitObj);
    return overrideMappingCase;
}


// Feedback APIs

export const getFeedbackByThemeId = async (id,filter="") => {
    const feedbacks = await apiCaller(`/feedbacksByThemeId/${id}`, "GET", {})
    return feedbacks;
}

export const getFeedbacks = async (filters, from, size) => {
    const feedbacks = await apiCaller("/feedbacks", "GET", {
        from,
        size,
        sortby: "payload.createdTimestamp",
        filters,
    });
    return feedbacks;
}

export const updateFeedback = async (submitObj, id) => {
    const feedback = await apiCaller(`/feedback/${id}`, "PUT", submitObj);
    return feedback;
}

export const getFeedback = async (id) => {
    const feedback = await apiCaller(`feedbacks/${id}`, "GET");
    return feedback
}

export const bulkUpdateFeedback = async (submitObj) => {
    const feedback = await apiCaller("/updatefeedbacks", "PUT", submitObj);
    return feedback;
}

// purge APIs
export const getPurgeStatus = async (id) => {
    const purgeStatus = await apiCaller(`/purge/${id}`, "GET");
    return purgeStatus
} 

export const deletePurgeRecord = async (id) => {
    const purgeStatus = await apiCaller(`/purge/${id}`, "DELETE");
    return purgeStatus
} 

//feature flags Api
export const getFeatureFlags = async () => {
    const flags = await apiCaller('/featureflag', "GET");
    return flags;
}




//API caller function
const apiCaller = (url, method, params={}) => {
    switch (method) {
        case "GET":
            try {
                return apiClient.get(url, { params })
            } catch (err) {
                Logger.sessionError(`${url} ${params} Failed`,{
                    emfLog: createEMFObject(`${method}${url}Failed`, 1, "Count",{GETapiFailed:url+"Failed"}),
                });
            }
            break;
        case "PUT":
            try {
                return apiClient.put(url, params)
            } catch (err) {
                Logger.sessionError(`${url} Failed`,{
                    emfLog: createEMFObject(`${method}${url}Failed`, 1, "Count", {PUTapiFailed:url+"Failed"}),
                });
            }
            break;
        case "POST":
            try {
                return apiClient.post(url, params)
            } catch (err) {
                Logger.sessionError(`${url} Failed`,{
                    emfLog: createEMFObject(`${method}${url}Failed`, 1, "Count", {POSTapiFailed:url+"Failed"}),
                });
            }
            break;
        default:
            try {
                return apiClient.delete(url, params)
            } catch (err) {
                Logger.sessionError(`${url} Failed`,{
                    emfLog: createEMFObject(`${method}${url}Failed`, 1, "Count", {DELETEapiFailed:url+"Failed"}),
                });
            }
            break;
    }
}