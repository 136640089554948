import React from "react";
import { withRouter } from "react-router-dom";
import Row from "../../../components/row";
import Column from "../../../components/column";
import { SeriesChart, DomainType } from '@amzn/unified-chart-components/core';
import { ButtonGroup, ButtonGroupItem, Text, Icon, Button} from "@amzn/storm-ui";
import AdaptiveHelpTip from '@amzn/storm-ui-adaptive-helptip';
import { caretLeft, caretRight } from "@amzn/storm-ui-icons";
import Logger from "../../../util/logger";
import { createEMFObject } from "../../../util/util";

const CHART_WINDOW = 10;
class BarChart extends React.Component {

    constructor(props){
        super(props);
        this.state={
            leftWindow: 0,
            rightWindow: CHART_WINDOW,
        }
    }

    render() {
        const { barData, helpText } = this.props;
        const { metrics, loadingState, handleViewSelection, chartheading, view} = barData;
        const { leftWindow, rightWindow } = this.state;
        let paginatedMetrics= []
        if(metrics){
            paginatedMetrics.push(Object.assign({},metrics[0]));
            paginatedMetrics[0].xData = metrics[0].xData.slice(leftWindow,rightWindow);
            paginatedMetrics[0].yData = metrics[0].yData.slice(leftWindow,rightWindow);
        }
        
        const scrollLeft= () => {
            Logger.sessionInfo("Trends BarChart Pagination viewed", {
                emfLog: createEMFObject("PaginationClick", 1, "Count", {PaginationAction:"Trends"}),
              });
            const {leftWindow, rightWindow} = this.state;
            
            if(leftWindow<=0){
                this.setState({
                    leftWindow:0,
                    rightWindow:CHART_WINDOW
                }, ()=>{
                    paginatedMetrics[0].xData = metrics[0].xData.slice(leftWindow,rightWindow);
                    paginatedMetrics[0].yData = metrics[0].yData.slice(leftWindow,rightWindow);
                })
            } else {
                this.setState({
                    leftWindow:leftWindow-CHART_WINDOW,
                    rightWindow: leftWindow
                }, ()=>{
                    paginatedMetrics[0].xData = metrics[0].xData.slice(leftWindow,rightWindow);
                    paginatedMetrics[0].yData = metrics[0].yData.slice(leftWindow,rightWindow);
                });
            }
        }
    
        const scrollRight= () => {
            Logger.sessionInfo("Trends BarChart Pagination viewed", {
                emfLog: createEMFObject("PaginationClick", 1, "Count", {PaginationAction:"Trends"}),
              });
            const {leftWindow, rightWindow} = this.state;
            const {barData:{metrics}} = this.props;
            if(rightWindow>=metrics[0].xData.length-1){
                this.setState({
                    leftWindow:metrics[0].xData.length-CHART_WINDOW,
                    rightWindow:metrics[0].xData.length
                }, ()=>{
                    paginatedMetrics[0].xData = metrics[0].xData.slice(leftWindow,rightWindow);
                    paginatedMetrics[0].yData = metrics[0].yData.slice(leftWindow,rightWindow);
                })
            } else {
                this.setState({
                    rightWindow: rightWindow+CHART_WINDOW,
                    leftWindow: rightWindow,
                }, ()=>{
                    paginatedMetrics[0].xData = metrics[0].xData.slice(leftWindow,rightWindow);
                    paginatedMetrics[0].yData = metrics[0].yData.slice(leftWindow,rightWindow);
                });
            }
        }
        return (
            <React.Fragment>
                <Column width="100%">
                    <Row>
                    <Column>
                    <Row spacing="100">
                        <h3>{chartheading}</h3>
                        <AdaptiveHelpTip>
                            <Text>{helpText}</Text>
                        </AdaptiveHelpTip>
                    </Row>
                    </Column>
                        {view &&
                        <ButtonGroup
                            name="View-type-filter"
                            label="Select view"
                            selectedValue={view}
                            onChange={(view) => {
                                handleViewSelection(view)
                            }}
                        >
                            <ButtonGroupItem value="QoQ" aria-label="Quaterly">
                                QoQ
                            </ButtonGroupItem>
                            <ButtonGroupItem value="MoM" aria-label="Monthly">
                                MoM
                            </ButtonGroupItem>
                        </ButtonGroup>}
                    </Row>
                    <SeriesChart
                        metrics={paginatedMetrics}
                        domain={{ type: DomainType.GENERIC }}
                        locale="en-US"
                        getTooltipTitle={(xValue) => xValue}
                        loadingState={loadingState}
                    />
                    {metrics && metrics[0].xData.length > CHART_WINDOW && <Row alignmentHorizontal="right" spacing="300" >
                        <Button onClick={scrollLeft} disabled={leftWindow<=0}><Icon type={caretLeft} /> </Button>
                        <Button onClick={scrollRight}  disabled={metrics && rightWindow>=metrics[0].xData.length}><Icon type={caretRight} /> </Button>
                    </Row>}

                </Column>
            </React.Fragment>
        )
    }
}

export default withRouter(BarChart);