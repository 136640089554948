import React, { useState } from "react";
import {
    Divider,
} from "@amzn/storm-ui";
import {
    Dropdown,
    DropdownItem,
} from "@amzn/storm-ui"
import { ThemeProvider } from "styled-components";
import { overrideZIndex } from "../../../util/util";
import CreateFeedbackType from "./CreateFeedbackType";
import CreateFeedbackCategory from "./CreateFeedbackCategory";
import CreateFeedbackProductServices from "./CreateProductService";

export const CreateFeedbackCategorizationForm = (props) => {

    const [entity, setEntity] = useState("");

    const entities = ["Feedback type", "Feedback category", "Product/Services"];


    return (
        <ThemeProvider theme={overrideZIndex}>
            <Dropdown
                onChange={(val)=>setEntity(val)}
                selectedValue={entity}
                onOverrideLabel={(dropdownItem, value) => {
                    if (value) { return dropdownItem; }
                    return 'Choose entity to create';
                }}
            >
                {entities.map((entityItem, key)=>(
                    <DropdownItem key={key} value={entityItem}>{entityItem}</DropdownItem>
                ))}
            </Dropdown>
            <Divider/>
            {entity==="Feedback type" && <CreateFeedbackType {...props}/> }
            {entity==="Feedback category" && <CreateFeedbackCategory {...props}/>}
            {entity==="Product/Services" && <CreateFeedbackProductServices {...props}/>}
        </ThemeProvider>
    )
}