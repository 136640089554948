import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
    Divider,
    Button,
    TextButton,
    TextAreaFormGroup,
    Alert
} from "@amzn/storm-ui";
import {
    Dropdown,
    DropdownItem,
} from "@amzn/storm-ui"
import { Form, FormRow } from "@amzn/storm-ui-column-form-kit";
import { ThemeProvider } from "styled-components";
import { overrideZIndex, getStaticData, createEMFObject } from "../../../util/util";
import Row from "../../../components/row";
import { useStore } from "../../../store";
import apiClient from "../../../util/apiClient";
import Logger from "../../../util/logger";
import endpoints from "../../../urlConfig";

const CreateLabelsForm = (props) => {
    const { mode, triggerSetEditLabelsObj, changeApplyButtonDisabledProperty } = props;
    const history = useHistory();
    const staticData = useStore((state) => state.staticData);
    const userInfo = useStore((state) => state.userInfo);
    const [feedBackCategoryTypes, setFeedBackCategoryTypes] = useState([]);

    const [feedBackCategoryType, setFeedBackCategoryType] = useState();
    const [labelInput, setLabelInput] = useState("");
    const [notes, setNotes] = useState("");
    const [isError, setIsError] = useState(false);
    const [formEdited, setFormEdited] = useState(false);
    const [editedEntities, setEditedEntities] = useState({});

    const { updateStaticData } = useStore();
    const {staticEndpoint, staticOperations} = endpoints

    useEffect(() => {
        setFeedBackCategoryTypes(getStaticData("FEEDBACK_CATEGORY", "", staticData).sort());
        if(mode==="Edit"){
            const { selectedRowItems } = props;
            const { rowData: {feedbackCategory, feedbackLabels} } = selectedRowItems[0];
            setFeedBackCategoryType(feedbackCategory);
            setLabelInput(feedbackLabels);
        }
    //eslint-disable-next-line
    }, [])


    const closeCreateModal = () => {
        history.push('/admin/labels');
    }

    const createLabelSuccess = (res) => {
        Logger.sessionInfo(`successfully created label: ${labelInput} under category: ${feedBackCategoryType}`, {
            emfLog: createEMFObject(`CreateFeedbackLabelsCounts`, 1, "Count"),
        });
        const params = {
            size: 10000
        }
        apiClient
          .get(staticEndpoint, { params })
          .then((response) => {
            updateStaticData(response?.data?.results?.map((stat) => {
                return Object.assign(stat.fields, { id: stat.id });
              }));
              history.push('/admin/labels');
          })
          .catch((error) => {
            Logger.sessionError(`fetching staticdata failed ${error}`, {
              browserUrl: window.location.href,
            });
            setIsError(true);
          })
          
    }

    const handleSubmit = () => {
        let submitObj = {};
        const {user:{email}} = userInfo
        submitObj = {
            entityType: "label",
            operationType: "create",
            "input" :{
                   fields:{
                   "docType" : "FEEDBACK_LABEL",
                   "parent" : feedBackCategoryType,
                   "value" : labelInput,
                   "updatedBy": email,
                   "notes" : "testing API",
                   "entityValue": "labelInput",
                   "operationType": "create"
                   }  
                }
        }
        apiClient.post(staticOperations, submitObj)
        .then(createLabelSuccess)
        .catch(err => {
            Logger.sessionError(`Failed to create label: ${labelInput} under category: ${feedBackCategoryType} error:${err}`,{
                emfLog: createEMFObject(`CreateFeedbackLabelFailed`, 1, "Count", {FeedbackLabel:"CreateFeedbackLabelFailed"}),
            });
            setIsError(true);
        })
        
    }


    useEffect(()=>{
        if(feedBackCategoryType && labelInput && notes && triggerSetEditLabelsObj) {
            triggerSetEditLabelsObj({
                selectedFeedbackType: feedBackCategoryType,
                labelsValue: labelInput,
                notes: notes
            })
            if(changeApplyButtonDisabledProperty && formEdited){
                changeApplyButtonDisabledProperty(false);
            } else{
                changeApplyButtonDisabledProperty(true);
            }
        }
        else {
            if(changeApplyButtonDisabledProperty){
                changeApplyButtonDisabledProperty(true);
            }
        }
    // eslint-disable-next-line
    },[labelInput, feedBackCategoryType, notes]);

    if(isError){
        return(
            <Alert type="error">Something went wrong. Please try again.</Alert>
        )
    }

    const validateEdit = (value, entity) => {
        const editedEntitiesState = Object.assign({}, editedEntities);
        if(!value){
            delete editedEntitiesState[entity]
            setEditedEntities(editedEntitiesState);
        }
        if(mode === "Edit" && value){
            const { selectedRowItems } = props;
            const { rowData } = selectedRowItems[0];
            if(value!==rowData[entity]){
                editedEntitiesState[entity] = true;
            }
            else {
                delete editedEntitiesState[entity];
            }
            setEditedEntities(editedEntitiesState);
            if(Object.keys(editedEntitiesState).length>0){
                setFormEdited(true);
            }
            else{
                setFormEdited(false);
            }
        }

    }

    return (
            <ThemeProvider theme={overrideZIndex}>
                <Form id="createLabel" >
                    <FormRow
                        label="Feedback category"
                        isSectionHeader
                        id="feedback-category-row"
                        controlComponents={[
                            (componentId) => (
                                <Dropdown
                                    error={!feedBackCategoryType}
                                    fullWidth
                                    id={componentId}
                                    onChange={(value) => {
                                        validateEdit(value, "feedbackCategory");
                                        setFeedBackCategoryType(value);
                                    }}
                                    onOverrideLabel={(dropdownItem, value) => {
                                        if (value) {
                                            return dropdownItem;
                                        }
                                        return "Feedback category";
                                    }}
                                    selectedValue={feedBackCategoryType}
                                >
                                    {feedBackCategoryTypes.map((subCategory, index) => (
                                        <DropdownItem key={subCategory} value={subCategory}>
                                            {subCategory}
                                        </DropdownItem>
                                    ))}
                                </Dropdown>
                            ),
                        ]}
                    />
                    <Divider />
                    <FormRow
                        label="Feedback label"
                        isSectionHeader
                        id="feedback-category-row"
                        controlComponents={[
                            (componentId) => (
                                <TextAreaFormGroup
                                    placeholder= "Create feedback label"
                                    value= {labelInput}
                                    onChange={(event)=>{
                                        validateEdit(event.target.value, "feedbackLabels");
                                        setLabelInput(event.target.value)
                                    }}
                                    statusType={feedBackCategoryType && !labelInput ? "error": undefined}
                                />
                            ),
                        ]}
                    />
                    <Divider />
                    <FormRow
                        label="Remarks"
                        isSectionHeader
                        id="feedback-category-row"
                        controlComponents={[
                            (componentId) => (
                                <TextAreaFormGroup
                                    placeholder= "Remarks"
                                    value= {notes}
                                    onChange={(event)=>{
                                        setNotes(event.target.value)
                                    }}
                                    statusType={labelInput && !notes ? "error": undefined}
                                />
                            ),
                        ]}
                    />
                    <Divider />
                    {mode!=="Edit" &&
                    <Row alignmentHorizontal="right">
                        <TextButton
                            onClick={closeCreateModal}
                        >
                            Cancel
                        </TextButton>
                        <Button
                            disabled={!labelInput || !notes || !feedBackCategoryType}
                            primary={true}
                            onClick={() =>
                                handleSubmit()
                            }
                        >
                            {`${mode} Label`}
                        </Button>
                    </Row>}
                </Form>
            </ThemeProvider>
    )
}

export default CreateLabelsForm;