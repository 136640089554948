import apiClient from "./apiClient";
import Logger from "./logger";

export const getTemplates = async () => {
    try{
        const res = await apiClient.get("/templates?size=10000", {});
        return res?.data?.results
    } catch(err) {
        Logger.sessionError(`error getting templates ${err}`);
        return err;
    }
}