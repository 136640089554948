import React from "react";
import {
    InlineMessage,
    Modal,
    Button,
    Text
} from "@amzn/storm-ui";
import Column from "../column";
import Row from "../row";

const ActionConfirmationComponent = (props) => {
    const { action, ActionWarningMessage, closeModal, confirmAction } = props;
    return (
        <Modal
            width="50%"
            isOpen={true}
            header={`Confirm action`}
            onClose={closeModal}
        >
            <Column className="container-container-small">
                <Row widths="100%">
                    <Text type="span">Are you sure you want to {action}?</Text>
                </Row>
                <Row widths="100%">
                    <InlineMessage messageType="warning" message={ActionWarningMessage} />
                </Row>
                <Row alignmentHorizontal="end" spacingInset="300" width={"300px"}>
                    <Button primary onClick={closeModal}>No</Button>
                    <Button onClick={confirmAction}>Yes</Button>
                </Row>
            </Column>
        </Modal>
    )
}

export default ActionConfirmationComponent;