import { getAuthenticated } from "./../util/util";
import Logger from "./../util/logger";


export function parseJwt(token) {
  try {
    if (token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
  
      return JSON.parse(jsonPayload);
    }
  } catch (error) {
    Logger.sessionError(`Auth token parsing Exception, ${error}`);
    getAuthenticated();
  }
  
}
