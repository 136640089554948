import { TextButton } from "@amzn/storm-ui";
import * as React from "react";
import { useHistory } from "react-router";

import Row from "../row";

const CellLinkRenderer = (props) => {
  const history = useHistory();
  const { value } = props;
  const onClickHandler = () => {
    history.push(`/feature/${value}`);
  };

  return (
    <Row widths="100px">
      <TextButton onClick={onClickHandler}>{value}</TextButton>
    </Row>
  );
};

export default CellLinkRenderer;
