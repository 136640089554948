import * as React from "react";
import { withRouter } from "react-router-dom";

import {
  Alert,
  Text,
  Button,
  TextAreaFormGroup,
  CurrencyInput,
  SearchInput,
  ButtonGroup,
  ButtonGroupItem,
  DropdownItemGroup,
  Divider,
  TextButton,
  FileUploadButton,
  InlineMessage,
  UnorderedList,
  ListItem,
} from "@amzn/storm-ui";
import {
  Dropdown,
  DropdownItem,
} from "@amzn/storm-ui"
import { Form, FormRow } from "@amzn/storm-ui-column-form-kit";
import {
  MultiSelect,
  MultiSelectItem,
  MultiSelectSearchPanel,
} from "@amzn/storm-ui-multi-select";

import Row from "../../components/row";
import Loader from "../../components/Loader";

import Logger, {logPageMetric} from "../../util/logger";
import apiClient from "../../util/apiClient";
import { createEMFObject, throttle, overrideZIndex } from "../../util/util";

import "../../styles/common.scss";
import "./FeedbackSubmitPage.scss";

import UserContext from "../../context/userContext";
import FilterDropdown from '@amzn/storm-ui-filter-dropdown';
import { ThemeProvider } from 'styled-components';
import Column from "../../components/column";
import { WHY_IS_THIS_IMPORTANT_DROPDOWN_ITEMS } from "../../util/constants";
import { Page } from "../../typings/enum";
import ActionConfirmationComponent from "../../components/action-confirmation/ActionConfirmation";

const CSAT_Score = "csatScore";
const ACCOUNT_NAME = "ACCOUNT_NAME";
const SIZMEK_ACCOUNT_NAME = "SIZMEK_ACCOUNT_NAME"
const FEEDBACK_TYPE = "FEEDBACK_TYPE";
const FEEDBACK_CATEGORY = "FEEDBACK_CATEGORY";
const PRODUCT_SERVICES = "PRODUCT_SERVICES";
const CUSTOMER_QUOTE = "CUSTOMER_QUOTE";
const WHY_IS_THIS_IMPORTANT = "WHY_IS_THIS_IMPORTANT";
const ADDITIONAL_CONTEXT = "ADDITIONAL_CONTEXT";
const BLOCKED_REVENUE = "BLOCKED_REVENUE";
const OPPORTUNITY = "OPPORTUNITY";
const LABELS = "LABELS";
const ATTACHMENTS = "ATTACHMENTS";
const AGENCY_ACCOUNT = "AGENCYACCOUNT"

const CSAT_Score_KEY = "csatScore";
const ACCOUNT_NAME_KEY = "accountId";
const AGENCY_ACCOUNT_ID = "associatedAgencyAccount";
const SIZMEK_ACCOUNT_NAME_KEY = "sizmekAccountId";
const FEEDBACK_TYPE_KEY = "feedBackType";
const FEEDBACK_CATEGORY_KEY = "feedBackCategoryType";
const PRODUCT_SERVICES_KEY = "feedBackProductServicesType";
const CUSTOMER_QUOTE_KEY = "customerVerbatim";
const WHY_IS_THIS_IMPORTANT_KEY = "whyThisImportant";
const ADDITIONAL_CONTEXT_KEY = "additionalContext";
const BLOCKED_REVENUE_KEY = "revenueImpacted";
const OPPORTUNITY_KEY = "opportunityId";
const LABELS_KEY = "selectedLabelsPayload";
const ATTACHMENTS_KEY = "attachmentDetails"
const VALIDFILETYPE = [
  "application/vnd.ms-excel", 
  "text/csv",
  "image/png", 
  "image/jpeg",
  "text/plain"
];
const MODES = ["edit","clone","template"]

class SubmitFeedbackPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      feedback: {},
      isLoading: false,
      isFeedbackDetailsLoading: false,
      isError: false,
      accounts: [],
      staticData: [],
      formContext: {},
      validateNext: false,
      validateSubmit: false,
      nextClicked: false,
      categories: [],
      feedbacktypes: [],
      products: [],
      feedbackId: "",
      features: [],
      oldFeatureId: "",
      opportunities: [],
      accountSearchQuery: "",
      featureSearchQuery: "",
      opportunitySearchQuery: "",
      isSearchLoading: false,
      sizmekAccountLoading: false,
      agencyAccountLoading: false,
      whileSearchText: "Search field is empty",
      searchSuggestions: [],
      hasSizmekAccounts: false,
      selectedAgencyAccount: [],
      showAgency: false,
      sizmekAccountsQuery: [],
      agencyAccountsQuery: [],
      filteredAgencyAccount: [],
      createNewFeature: false,
      isSearchDropDownOpen: false,
      user: "",
      token: "",
      selectedValuesProductServices: [],
      filteredOptions: [],
      searchObjWithGroup: [],
      searchObjWithGroupSearch: [],
      allProduct: false,
      labelFilteredOptions: [],
      savedFeedbackdetails: {},
      validateEdit: false,
      editedFields: {},
      searchType:"",
      searchText: "",
      attachmentValidationError: {},
      attachmentsMetaData: [],
      opportunityActivity: "",
      whyThisImportantInvalid: false,
      isLongAccountN:false,
      isLongOpportunityName: false,
      accountSelectionAllowed: true,
      agencyAccountError: false,
      // openConfirmationModal: false,
    };
    this.initialState = JSON.parse(JSON.stringify(this.state));
    this.handleNext = this.handleNext.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlSubmitPostRequest=this.handlSubmitPostRequest.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.setContextValue = this.setContextValue.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleAccountSelection = this.handleAccountSelection.bind(this);
    this.handleOpportunitySelection =
      this.handleOpportunitySelection.bind(this);
    this.handleFeatureSelection = this.handleFeatureSelection.bind(this);
    this.getStaticData = this.getStaticData.bind(this);
    this.validationNext = this.validationNext.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.mappingForMultiselectSearch =
      this.mappingForMultiselectSearch.bind(this);
    this.handleEditValidation = this.handleEditValidation.bind(this);
    this.onUpload = this.onUpload.bind(this);
    this.validateFileName = this.validateFileName.bind(this);
    this.validateFileType = this.validateFileType.bind(this);
    this.validateFileSize = this.validateFileSize.bind(this);
    this.handleRemoveAttachment = this.handleRemoveAttachment.bind(this);
    this.putToS3Bucket = this.putToS3Bucket.bind(this);
    this.uploadFilesThroughPresignedUrl = this.uploadFilesThroughPresignedUrl.bind(this);
    this.fileWithSameNameExists = this.fileWithSameNameExists.bind(this);
    this.getTemplateDetails = this.getTemplateDetails.bind(this);
    this.handleConfirmationModalClose = this.handleConfirmationModalClose.bind(this);
    this.handleCancelConfirmationClick = this.handleCancelConfirmationClick.bind(this);
    performance.mark("FeedbackSubmitPage");
  }

  handleEditValidation(field, key, value) {
    const { savedFeedbackdetails, editedFields } = this.state;
    const {mode} = this.props;
    if(mode !== "edit"){
      return
    }
    if(field===ATTACHMENTS){
      if(value !== 0) {
        editedFields[field] = true;
        this.setState({
          validateEdit: true,
          editedFields,
        });
      }
      else {
        if (editedFields[field]) {
          delete editedFields[field];
          this.setState({
            editedFields,
          });
        }
      }
    } 
    else{
    if (savedFeedbackdetails[key] !== value) {
      editedFields[field] = true;
      this.setState({
        validateEdit: true,
        editedFields,
      });
    } else {
      if (editedFields[field]) {
        delete editedFields[field];
        this.setState({
          editedFields,
        });
        }
      }
    }
    if (Object.keys(editedFields).length === 0) {
      this.setState({
        validateEdit: false,
      });
    }
  }

  validationSubmit() {
    const { formContext } = this.state;
    const allowWholeNumber = new RegExp(/^\+?(0|[1-9]\d*)$/);
    let errorMessage = [];
    if (
      formContext.accountName === null ||
      formContext.accountName === undefined
    ) {
      errorMessage.push("Account name is missing");
      this.setState({
        accountN: true,
      });
    } else {
      this.setState({
        accountN: false,
      });
    }
    if (
        this.props.mode === 'edit' &&
        this.state.accountSelectionAllowed &&
        formContext.associatedAgencyAccount
    ) {
      this.setState({agencyAccountError: true});
      errorMessage.push("Advertiser Account name is missing");
    }
    else {
      this.setState({agencyAccountError: false});
    }
    if (
      formContext.customerVerbatim === null ||
      formContext.customerVerbatim === undefined ||
      formContext.customerVerbatim === ""
    ) {
      errorMessage.push("Customer Quote is missing");
      this.setState({
        customerV: true,
      });
    } else {
      this.setState({
        customerV: false,
      });
    }
    if (
      formContext.feedBackCategoryType === null ||
      formContext.feedBackCategoryType === undefined ||
      formContext.feedBackCategoryType === ""
    ) {
      errorMessage.push("Feedback Category is missing ");
      this.setState({
        feedBackC: true,
      });
    } else {
      this.setState({
        feedBackC: false,
      });
    }
    if (
      formContext.feedBackType === null ||
      formContext.feedBackType === undefined ||
      formContext.feedBackType === ""
    ) {
      errorMessage.push("Feedback type is missing ");
      this.setState({
        feedBackT: true,
      });
    } else {
      this.setState({
        feedBackT: false,
      });
    }
    if (
      formContext.feedBackProductServicesType === null ||
      formContext.feedBackProductServicesType === undefined ||
      formContext.feedBackProductServicesType === ""
    ) {
      errorMessage.push("Feedback product/services is missing ");
      this.setState({
        feedBackP: true,
      });
      this.setState({
        selectedValuesProductServices: [],
      });
    } else {
      let dataProductServices = formContext.feedBackProductServicesType;
      let productList = dataProductServices.split(",");
      this.setState({
        selectedValuesProductServices: productList,
      });
      this.setState({
        feedBackP: false,
      });
    }
    if (
      formContext.revenueImpacted &&
      allowWholeNumber.test(formContext.revenueImpacted)
    ) {
      this.setState({
        currencyInvalid: false,
      });
    } else {
      errorMessage.push("Revenue Impacted is missing ");
      this.setState({
        currencyInvalid: true,
      });
    }
    if(
      formContext.whyThisImportant === null ||
      formContext.whyThisImportant === undefined ||
      formContext.whyThisImportant === ""
    ) {
      errorMessage.push("whyThisImportant is missing ");
      this.setState({
        whyThisImportantInvalid: true,
      });
    } else {
      this.setState({
        whyThisImportantInvalid: false,
      });
    }
    if (
      formContext.csatScore === null ||
      formContext.csatScore === undefined ||
      formContext.csatScore === "" ||
      formContext.csatScore === "0"
    ) {
      errorMessage.push("Customer Satisfaction Score is missing ");
      this.setState({
        satisfactionScoreRequired: true,
      });
    } else {
      this.setState({
        satisfactionScoreRequired: false,
      });
    }
    if (errorMessage.length === 0) {
      this.setState({
        validateSubmit: true,
      });
    } else {
      this.setState({
        validateSubmit: false,
      });
    }
  }
  renderErrorMessage() {
    return "Invalid revenue amount. Revenue Impacted must be a valid number. Insert 0 if no revenue is impacted";
  }

  validationNext() {
    // check if mandatory fields are user inputted
    // for positive, account information, csat, customer verbatim and feedback category and sub-category is mandatory
    const { formContext } = this.state;
    const { accountId, csatScore, customerVerbatim } = formContext;
    if (accountId && csatScore && customerVerbatim) {
      this.setState(
        {
          validateNext: true,
        },
        this.validationSubmit()
      );
    } else {
      this.setState({
        validateNext: false,
      });
    }
  }

  setContextValue(key, value) {
    let { formContext } = this.state;
    formContext[key] = value;
    this.setState(
      {
        formContext: formContext,
      },
      this.validationNext()
    );
  }
  setContextValueForList(key, value) {
    let { formContext } = this.state;
    formContext[key] = value.toString();
    this.setState(
      {
        formContext: formContext,
      },
      this.validationNext()
    );
  }

  onSearchInput = () => {
    let params = {};
    const { searchType, searchText } = this.state;


    if (!searchText.trim()) {
      // handling clear button
      searchType === "accounts" && this.handleAccountSelection(null);
      searchType === "features" && this.handleFeatureSelection(null);
      searchType === "opportunities" && this.handleOpportunitySelection(null);

      this.setState({
        searchSuggestions: [],
        isSearchLoading: false,
        whileSearchText: "Search field is empty",
      });
      this.validationSubmit();
      return;
    }

    this.setState({
      isSearchLoading: true,
      whileSearchText: "Searching for " + searchText,
    });

    let searchUrl = "/search/";
    // search type will have values like "accounts", "opportunities" or "features"
    if (searchType) {
      searchUrl =
        searchUrl +
        searchType +
        "/" +
        encodeURIComponent(searchText) +
        "?size=10";
    }

    apiClient
      .get(searchUrl, { params })
      .then((response) => {
        if (response?.data?.results?.length > 0) {
          const results = response.data.results.map((result) => {
            return Object.assign(result.fields, { id: result.id });
          });
          this.setState({
            searchSuggestions: results,
            whileSearchText: "Results for " + searchText,
            isSearchLoading: false,
          });
        } else {
          this.setState({
            searchSuggestions: [],
            whileSearchText: "No results found for " + searchText,
            isSearchLoading: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          isSearchLoading: false,
          searchSuggestions: [],
          whileSearchText: "No results found for " + searchText,
        });
      })
      .finally(() => {
        this.setState({ isSearchLoading: false });
      });
  };

  onSearchInputThrottle = throttle(this.onSearchInput, 1000);

  submitSearchInput = (searchText, searchType) => {
    this.onSearchInput(searchText, searchType);
  };

  handleFeatureSelection(featureObj) {
    let { formContext } = this.state;

    if (!featureObj) {
      for (let key of [
        "featureId",
        "advertisingProduct",
        "advertisingSubProduct",
        "featureName",
        "featureDescription",
      ]) {
        delete formContext[key];
      }
    } else {
      let feedbackFeatureObj = {
        featureId: featureObj.id,
        advertisingProduct: featureObj.advertisingProduct,
        advertisingSubProduct: featureObj.advertisingSubProduct,
        featureName: featureObj.featureName,
        featureDescription: featureObj.description,
      };
      formContext = Object.assign(formContext, feedbackFeatureObj);
    }

    this.setState(
      {
        formContext,
        featureSearchQuery: featureObj ? featureObj.featureName : "",
        searchText: "",
        searchSuggestions: [],
        whileSearchText: "Search field is empty",
      },
      this.validationSubmit()
    );
  }

  handleOpportunitySelection(opportunityObj) {
    let { formContext } = this.state;

    if (!opportunityObj) {
      for (let key of Object.keys(formContext)) {
        if (key.startsWith("opportunity") && key !== "opportunityValue") {
          delete formContext[key];
        }
      }
    } else {
      let feedbackOpporObj = {
        opportunityId: opportunityObj.id,
        opportunityName: opportunityObj.name,
        opportunityStage: opportunityObj.stage,
      };
      formContext = Object.assign(formContext, feedbackOpporObj);
    }

    this.setState(
      {
        formContext,
        opportunitySearchQuery: opportunityObj ? opportunityObj.name : "",
        searchText: "",
        searchSuggestions: [],
        whileSearchText: "Search field is empty",
      },
      this.validationNext()
    );
  }
  getAgencyAccounts(accountobj) {
    //ToDo: remove hard code of size 500
      apiClient
        .get('agencyaccountinfo', {
          params: {
            from: 0,
            size: 500,
            filters: `agencyAccountId:${accountobj.id}`
          }
        })
        .then((response) => {
          if (response?.data?.results?.length > 0) {
            const results = response.data.results.map((result) => {
              let agencyAccountFields = {
                id: result.fields.advertiserAccountId,
                name: result.fields.name,
              };
              return JSON.stringify(agencyAccountFields);
            });
            this.setState({agencyAccountsQuery: results, filteredAgencyAccount: results, showAgency: true}, );
          } else {
            this.setState({agencyAccountsQuery: [], showAgency: false});
          }
        })
        .catch((error) => {
          Logger.sessionError(`Agency Accounts Error ${error}`, {
            browserUrl: window.location.href,
          });
        })
        .finally(() => {
          this.setState({agencyAccountLoading: false});
        });
  }


  getSizmekAccounts(accountObj) {
    if(this.state.hasSizmekAccounts) {
      apiClient
        .get('sizmekaccountinfos', {
          params: {
            from: 0,
            size: 50,
            filters: `account:${accountObj.id}`
          }
        })
        .then((response) => {
          if (response?.data?.results?.length > 0) {
            const results = response.data.results.map((result) => {
              return Object.assign(result.fields, {id: result.id});
            });
            this.setState({sizmekAccountsQuery: results });
          } else {
            this.setState({sizmekAccountsQuery: [] ,hasSizmekAccounts: false});
          }
        })
        .catch((error) => {
          Logger.sessionError(`Sizmek Accounts Error ${error}`, {
            browserUrl: window.location.href,
          });
        })
        .finally(() => {
          this.setState({sizmekAccountLoading: false });
        });
    }
    else{
      this.setState({sizmekAccountLoading: false });
    }
  }

  handleAccountSelection(accountObj) {
    let { formContext } = this.state;

    if (!accountObj) {
      for (let key of Object.keys(formContext)) {
        if (key.startsWith("account")) {
          delete formContext[key];
        }
      }
    } else {
      let feedbackAccountObj = {
        accountOwnerEmail: accountObj.ownerEmail,
        accountId: accountObj.id,
        accountOwnerName: accountObj.ownerName,
        accountName: accountObj.name,
        accountOwnerId: accountObj.ownerId,
        accountType: accountObj.type,
        accountRecordType: accountObj.recordType,
        accountMarket: accountObj.market,
        accountSegment: accountObj.segment,
        accountVertical: accountObj.salesVertical,
        accountChannel: accountObj.channel,
        customerContact: accountObj.primaryClientContact,
        salesTeam: accountObj.salesTeam,
        isSizmek: accountObj.isSizmek,
      };
      formContext = Object.assign(formContext, feedbackAccountObj);
      if(accountObj.type==="Agency") {
        this.getAgencyAccounts(accountObj);
      } else {
        this.setState({agencyAccountLoading: false});
      }
    }

    this.setState(
      {
        formContext,
        accountSearchQuery: accountObj ? accountObj.name : "",
        searchText: "",
        searchSuggestions: [],
        whileSearchText: "Search field is empty",
        // hasSizmekAccounts: ((accountObj && accountObj.isSizmek) === "True") ? true : false,
      },() => {
          this.validationNext();
          this.getSizmekAccounts(accountObj);
        }
    );
  }

  handleSizmekAccountSelection(sizmekAccObj){
    let { formContext } = this.state ;
    if (!sizmekAccObj) {
      for (let key of Object.keys(formContext)) {
        if (key.startsWith("sizmekAccount")) {
          delete formContext[key];
        }
      }
    } else {
      let feedbackAccountObj = {
        sizmekAccount: sizmekAccObj.name,
        sizmekAccountId: sizmekAccObj.id,
      };
      formContext = Object.assign(formContext, feedbackAccountObj);
    }
    this.setState({formContext});
  }

  handleAgencyAccountSelection(agencyAccObj) {
    let {formContext} = this.state;
    if (!agencyAccObj) {
      for (let key of Object.keys(formContext)) {
        if (key.toLocaleLowerCase().includes("agencyaccount")) {
          delete formContext[key];
        }
      }
    } else {
      formContext["associatedAgencyAccount"] = agencyAccObj.join(";");
      formContext["agencyAccountName"] = agencyAccObj.map((item) => JSON.parse(item).name).join(",");
    }
    this.setState({
      formContext: formContext,
      selectedAgencyAccount: agencyAccObj ? agencyAccObj : [] ,
    });
  }

  handleReset() {
    this.setState({
      formContext: {},
    });
  }

  getMatch(a, b) {
    let matches = [];
    for (let i = 0; i < a.length; i++) {
      for (let e = 0; e < b.length; e++) {
        let s1 = a[i].item.label;
        let s2 = b[e].item.label;
        if (s1 === s2) {
          matches.push(s1);
        }
      }
    }
    return matches;
  }


  async putToS3Bucket(url, data) {
    let formData = new FormData();
    formData.append('data',data);
    try {
      await fetch(url,{
        method:'Put',
        headers: {
          'Content-Type': `${data.type}`,
        },
        body : Buffer.from(data.data, 'base64')
      })
      Logger.sessionInfo("file successfully uploaded to s3 ", JSON.stringify({name: data.metaData.name, type:data.metaData.type, size: data.metaData.size}));
    } catch(err){
      Logger.sessionError(`failed to upload file ${JSON.stringify({name: data.metaData.name, type:data.metaData.type, size: data.metaData.size})} to s3 ${err}`);
    }
  }

  async uploadFilesThroughPresignedUrl(resp) {
    const { attachmentsMetaData } = this.state;
    if(resp?.data?.feedbackSummary?.attachments) {
      const {attachments} = resp.data.feedbackSummary
      for(let i = 0; i<attachmentsMetaData.length; i++) {
        const respAttachmentName = attachmentsMetaData[i].name;
        if(attachments[respAttachmentName]) {
          const allClientURL = attachments[respAttachmentName];
          for(const clientUrl of allClientURL){
            await this.putToS3Bucket(clientUrl, {data: attachmentsMetaData[i][respAttachmentName], type: attachmentsMetaData[i].type, metaData: attachmentsMetaData[i] });
          }
        }
      }
    }
  }


  metricForSizmekAccount(){
    const { formContext } = this.state;
    let { sizmekAccount , isSizmek } = formContext;
    if(isSizmek === "True") {
      Logger.sessionInfo("Selected Account has Sizmek Accounts", {
        emfLog: createEMFObject("AccountHasSizmekAccounts", 1, "Count"),
      });
    }
    if(sizmekAccount !== undefined) {
      Logger.sessionInfo("Sizmek Account added", {
        emfLog: createEMFObject("SizmekAccountAdded", 1, "Count"),
      });
    }
  }

  handleSubmit() {
    this.setState({ isLoading: true });
    const { formContext, attachmentsMetaData } = this.state;
    this.metricForSizmekAccount();
    Logger.sessionInfo(`Feedback submit request `, "submit feedback page", {
      browserUrl: window.location.href,
    });
    const postFeedbackRequest = "/feedback";
    let submitData = {};

    submitData["type"] = "createFeedback";
    submitData["fields"] = formContext;
    if(attachmentsMetaData.length>0){
      Logger.sessionInfo("Attachment added", {
        emfLog: createEMFObject("AttachmentAdded", 1, "Count", {Attachment:"AttachmentAddedWithFeedback"}),
      });
      let filesName = "";
      for(let i = 0; i<attachmentsMetaData.length; i++) {
        filesName = filesName + attachmentsMetaData[i].name+','+attachmentsMetaData[i].type+';';
      };
      filesName=filesName.slice(0,-1);
      formContext["attachmentDetails"] = filesName;
    }
    let { featureId, isRevenueBlocked } = formContext;
    delete formContext["selectedValuesProductServices"];
    if (isRevenueBlocked !== undefined) {
      if (isRevenueBlocked === "true") {
        delete formContext["opportunityValue"];
        formContext["isRevenueBlocked"] = "true";
      } else {
        //delete formContext["revenueImpacted"];
        formContext["isRevenueBlocked"] = "false";
      }
    }


    if (featureId) {
      let featureArr = [];
      featureArr.push(featureId);
      submitData["relations"] = { featureId: featureArr };
    } else {
      submitData["relations"] = null;
    }
    this.handlSubmitPostRequest(postFeedbackRequest, submitData);
  }

  handlSubmitPostRequest(postFeedbackRequest, submitData) {
    apiClient
        .post(postFeedbackRequest, submitData)
        .then(async (response) =>
            await this.handleSubmitSuccess(response)
        )
        .catch((error) => this.handleSubmitFailure(error));

    performance.measure("FeedbackSubmitPage", "FeedbackSubmitPage");
    Logger.sessionInfo("Time spent on Create Feedback Page", {
      emfLog: createEMFObject(
          "TimeSpendOnCreateFeedbackPage",
          performance.getEntriesByName("FeedbackSubmitPage")?.[0]?.duration,
          "Milliseconds"
      ),
    });
    performance.clearMeasures();
  }

  handleEdit() {
    const { feedbackId, oldFeatureId } = this.state;
    this.setState({ isLoading: true });
    const { formContext, user, attachmentsMetaData } = this.state;
    this.metricForSizmekAccount();
    Logger.sessionInfo(`Feedback edit request `, "submit feedback page", {
      browserUrl: window.location.href,
    });
    let feedbackRequest = `/feedback/${feedbackId}`;
    let submitData = {};

    submitData["type"] = "updateFeedback";

    
    if(!formContext["attachments"]){
      delete formContext["attachments"];
    }
    if(attachmentsMetaData.length>0){
      Logger.sessionInfo("Attachment added", {
        emfLog: createEMFObject("AttachmentAdded", 1, "Count", {Attachment:"AttachmentAddedWithFeedback"}),
      });
      let filesName = "";
      for(let i = 0; i<attachmentsMetaData.length; i++) {
        filesName = filesName + attachmentsMetaData[i].name+','+attachmentsMetaData[i].type+';';
      };
      filesName=filesName.slice(0,-1);
      formContext["attachmentDetails"] = filesName;
    }

    let { featureId, isRevenueBlocked } = formContext;
    delete formContext["selectedValuesProductServices"];
    if (isRevenueBlocked !== undefined) {
      if (isRevenueBlocked === "true") {
        delete formContext["opportunityValue"];
        formContext["isRevenueBlocked"] = "true";
      } else {
        //delete formContext["revenueImpacted"];
        formContext["isRevenueBlocked"] = "false";
      }
    }


    if (featureId) {
      let featureArr = [];
      featureArr.push(featureId);
      submitData["relations"] = { featureId: featureArr };
    } else {
      submitData["relations"] = null;
    }

    if (oldFeatureId !== featureId) {
      formContext["oldFeatureId"] = oldFeatureId;
    }
    submitData["fields"] = { ...formContext, lastEditedBy: user.email };

    let input = {};
    input["input"] = submitData;

    apiClient
      .put(feedbackRequest, input)
      .then(async (response) => await this.handleEditSuccess(response))
      .catch((error) => this.handleSubmitFailure(error));
  }

  handleEditForCreateAndNew() {
    const { feedbackId, oldFeatureId } = this.state;
    this.setState({ isLoading: true });
    const { formContext, user } = this.state;
    let feedbackRequest = `/feedback/${feedbackId}`;
    let submitData = {};

    submitData["type"] = "updateFeedback";

    this.metricForSizmekAccount();

    let { featureId, isRevenueBlocked } = formContext;
    delete formContext["selectedValuesProductServices"];
    if (isRevenueBlocked === true) {
      delete formContext["opportunityValue"];
      formContext["isRevenueBlocked"] = "true";
    } else if (isRevenueBlocked === false) {
      // delete formContext["revenueImpacted"];
      formContext["isRevenueBlocked"] = "false";
    }


    if (featureId) {
      let featureArr = [];
      featureArr.push(featureId);
      submitData["relations"] = { featureId: featureArr };
    } else {
      submitData["relations"] = null;
    }

    if (oldFeatureId !== featureId) {
      formContext["oldFeatureId"] = oldFeatureId;
    }
    submitData["fields"] = { ...formContext, lastEditedBy: user.email };

    let input = {};
    input["input"] = submitData;

    apiClient
      .put(feedbackRequest, input)
      .then((response) => this.handleEditSuccessForCreateAndNew(response))
      .catch((error) => this.handleSubmitFailure(error));
  }

  async handleSubmitSuccess(response) {
    const {opportunityActivity} = this.state;
    const {mode} = this.props;
    
    performance.mark("uploadFiles");
    await this.uploadFilesThroughPresignedUrl(response);
    if (response?.data?.feedbackSummary) {
      const feedbackId = response.data.feedbackSummary.feedbackId;
      let feedbackSource = "dashboard";
      if(opportunityActivity) {
        feedbackSource = opportunityActivity;
      } else if(mode==="clone"){
        feedbackSource = "clone";
      }
      Logger.sessionInfo(`new feedback got created ${feedbackId}`, {
        emfLog: createEMFObject("NewFeedbackCreated", 1, "Count", {feedbackSource}),
      });
      performance.measure("uploadFiles", "uploadFiles");
      Logger.sessionInfo("Time spent on uploading files", {
        emfLog: createEMFObject(
          "TimeSpendOnUploadingFiles",
          performance.getEntriesByName("uploadFiles")?.[0]?.duration,
          "Milliseconds"
        ),
      });
      performance.clearMarks("uploadFiles");
      window.location.replace("/feature");
    }
  }

  handleSubmitForcreateAndNew() {
    this.setState({ isLoading: true });
    const { formContext } = this.state;
    Logger.sessionInfo(`Feedback submit request `, "submit feedback page", {
      browserUrl: window.location.href,
    });
    const postFeedbackRequest = "/feedback";
    let submitData = {};
    submitData["type"] = "createFeedback";
    submitData["fields"] = formContext;

    let { featureId, isRevenueBlocked } = formContext;
    this.metricForSizmekAccount();
    delete formContext["selectedValuesProductServices"];
    if (isRevenueBlocked === true) {
      delete formContext["opportunityValue"];
      formContext["isRevenueBlocked"] = "true";
    } else if (isRevenueBlocked === false) {
      //delete formContext["revenueImpacted"];
      formContext["isRevenueBlocked"] = "false";
    }


    if (featureId) {
      let featureArr = [];
      featureArr.push(featureId);
      submitData["relations"] = { featureId: featureArr };
    } else {
      submitData["relations"] = null;
    }

    apiClient
      .post(postFeedbackRequest, submitData)
      .then((response) => this.handleSubmitSuccessForCreateAndNew(response))
      .catch((error) => this.handleSubmitFailure(error));

    performance.measure("FeedbackSubmitPage", "FeedbackSubmitPage");
    Logger.sessionInfo("Time spent on Create Feedback Page", {
      emfLog: createEMFObject(
        "TimeSpendOnCreateFeedbackPage",
        performance.getEntriesByName("FeedbackSubmitPage")?.[0]?.duration,
        "Milliseconds"
      ),
    });
    performance.clearMeasures();
  }
  handleSubmitSuccessForCreateAndNew(response) {
    if (response?.data?.feedbackSummary) {
      const feedbackId = response.data.feedbackSummary.feedbackId;
      Logger.sessionInfo(`new feedback got created ${feedbackId}`, {
        emfLog: createEMFObject("NewFeedbackCreated", 1, "Count"),
      });
      this.setState({ isLoading: false });
      this.initialState.formContext = {
        feedBackCategoryType: "",
        feedBackType: "",
        revenueImpacted: null,
        csatScore: undefined,
        customerVerbatim: "",
        whyThisImportant: "",
        additionalContext: "",
        customerContact: "",
        category: "",
        feedBackProductServicesType: "",
        selectedValuesProductServices: [],
        advertisingSubProduct: undefined,
        subCategory: undefined,
        source: "",
        selectedLabelsPayload: "",
      };
      this.setState(this.initialState);
      this.loadStaticData();
      localStorage.setItem("newFeedbackCreated", true);
      //       window.location.replace("/feature");
    }
  }

  handleEditSuccessForCreateAndNew(response) {
    if (response?.data?.feedbackSummary) {
      const feedbackId = response.data.feedbackSummary.feedbackId;
      Logger.sessionInfo(`feedback got updated ${feedbackId}`);
      this.setState({ isLoading: false });
      this.initialState.formContext = {
        feedBackCategoryType: "",
        feedBackType: "",
        revenueImpacted: null,
        csatScore: undefined,
        customerVerbatim: "",
        whyThisImportant: "",
        additionalContext: "",
        customerContact: "",
        category: "",
        feedBackProductServicesType: "",
        selectedValuesProductServices: [],
        advertisingSubProduct: undefined,
        subCategory: undefined,
        source: "",
        selectedLabelsPayload: ""
      };
      this.setState(this.initialState);
      this.loadStaticData();
      localStorage.setItem("newFeedbackCreated", true);
      //       window.location.replace("/feature");
    }
  }

  async handleEditSuccess(response) {
    const { history } = this.props;
    performance.mark("uploadFiles");
    await this.uploadFilesThroughPresignedUrl(response);
    //ToDo: feedbackId to change feedbackIds
    const feedbackIds = response.data.feedbackSummary.feedbackId;
    if (response?.data?.feedbackSummary) {
      Logger.sessionInfo(`Feedback edited ${feedbackIds}`, {
        emfLog: createEMFObject("FeedbackEdit", 1, "Count"),
      });
      performance.measure("uploadFiles", "uploadFiles");
      Logger.sessionInfo("Time spent on uploading files", {
        emfLog: createEMFObject(
          "TimeSpendOnUploadingFiles",
          performance.getEntriesByName("uploadFiles")?.[0]?.duration,
          "Milliseconds"
        ),
      });
      performance.clearMarks("uploadFiles");
      history.push("/feedback/" + feedbackIds[0]);
    }
  }

  handleSubmitFailure(error) {
    this.setState({
      showUserNotification: true,
      isLoading: false,
      alertType: "error",
      alertHeader: `feedback submit`,
      alertContent: "We have experienced a unexpected error, please try again.",
    });
    Logger.sessionError(`feedback submit error - ${error}`, {
      emfLog: createEMFObject("FeedbackSubmitError", 1, "Count"),
    });
    window.setTimeout(() => {
      this.setState({ showUserNotification: false });
    }, 5000);
  }

  handleCancelClick() {
    this.props.setOpenConfirmationModal(true);
  }
  handleConfirmationModalClose() {
    Logger.sessionInfo("User confirmed not to close the modal", {
      emfLog: createEMFObject("CloseModalReject", 1, "Count"),
    });
    this.props.setOpenConfirmationModal(false);
  }

  handleCancelConfirmationClick() {
    const { history, mode } = this.props;
    Logger.sessionInfo("User confirmed to close the modal", {
      emfLog: createEMFObject("CloseModalConfirm", 1, "Count"),
    });
    if (MODES.includes(mode)) {
      history.push("/");
    } else {
      this.props.onClose();
    }
  }

  handleNext() {
    let { formContext, feedbackType } = this.state;
    let csatScore = formContext[CSAT_Score];
    if (parseInt(csatScore) >= 4) {
      feedbackType = "positive";
    } else {
      feedbackType = "negative";
    }
    if (parseInt(csatScore) === 0) {
      feedbackType = "positive";
    }
    this.setContextValue("feedbackType", feedbackType);
    this.setContextValue("sentiment", feedbackType);
    // load the next part of form
    // disable earlier fields
  }

  getStaticData(type, parent) {
    // type should be either CATEGORY or PRODUCT
    let { staticData } = this.state;
    let dataArr = [];
    let entities;
    if (staticData.length) {
      if (parent) {
        entities = staticData.filter((obj) => {
          return obj.docType === type && obj.parent === parent;
        });
      } else {
        entities = staticData.filter((obj) => {
          return obj.docType === type;
        });
      }

      for (let data of entities) {
        dataArr.push(data.value);
      }
    }
    return dataArr;
  }

  getStaticDataForParent(type, value) {
    // type should be either CATEGORY or PRODUCT
    const params = {};
    let dataArr = [];
    apiClient
      .get("/static?size=10000", { params })
      .then((response) => {
        let staticData = response?.data?.results?.map((stat) => {
          return Object.assign(stat.fields, { id: stat.id });
        });
        let entities;
        if (staticData.length) {
          entities = staticData.filter((obj) => {
            return obj.docType === type && obj.value === value;
          });

          for (let data of entities) {
            dataArr.push(data.parent);
          }
          if (type === "PRODUCT_SERVICES" && dataArr.length > 0) {
            this.setContextValue("feedBackCategoryType", dataArr[0]);
            this.setContextValue("subCategory", dataArr[0]);
            this.getStaticDataForParent("FEEDBACK_CATEGORY", dataArr[0]);
            this.validationSubmit();
          }
          if (type === "FEEDBACK_CATEGORY" && dataArr.length > 0) {
            this.setContextValue("feedBackType", dataArr[0]);
            this.setContextValue("category", dataArr[0]);
            this.validationSubmit();
          }
        }
      })
      .catch((error) => {
        Logger.sessionError(`FeatureTable static ${error}`, {
          browserUrl: window.location.href,
        });
        this.setState({ isError: true });
      })
      .then(() => {
        this.setState({ isLoading: false });
      });
  }

  onSearchInputSingleData = (searchText, searchId, searchType) => {
    let params = {};
    if (searchText !== undefined && searchText !== null && searchText !== "") {
      if (!searchText.trim()) {
        // handling clear button
        searchType === "accounts" && this.handleAccountSelection(null);
        searchType === "features" && this.handleFeatureSelection(null);
        searchType === "opportunities" && this.handleOpportunitySelection(null);

        this.setState({
          whileSearchText: "Search field is empty",
          isCreateNewModalOpen: null,
        });
        return;
      }

      this.setState({
        whileSearchText: "Searching for " + searchText,
        isCreateNewModalOpen: null,
      });

      let searchUrl = "/search/";
      // search type will have values like "accounts", "opportunities" or "features"
      if (searchType) {
        searchUrl =
          searchUrl +
          searchType +
          "/" +
          encodeURIComponent(searchText) +
          "?size=100";
      }

      apiClient
        .get(searchUrl, { params })
        .then((response) => {
          if (response?.data?.results?.length > 0) {
            const results = response.data.results.map((result) => {
              return Object.assign(result.fields, { id: result.id });
            });
            if (searchType === "accounts") {
              results.forEach((account) => {
                if (account.id === searchId) {
                  this.handleAccountSelection(account);
                }
              });
              this.validationSubmit();
            }
            if (searchType === "opportunities") {
              this.handleOpportunitySelection(results[0]);
              this.validationSubmit();
            }
            this.setState({
              isCreateNewModalOpen: true,
            });
          } else {
            this.setState({
              whileSearchText: "No results found for " + searchText,
              isCreateNewModalOpen: null,
            });
          }
        })
        .catch(() => {
          this.setState({
            whileSearchText: "No results found for " + searchText,
          });
        })
        .finally(() => {
          this.setState({ isSearchLoading: false });
        });
    }
  };

  loadFormDataAutoFill() {
    const { history } = this.props;
    const location = history.location;
    const search_params = location.search;
    if (search_params !== "" && search_params !== undefined) {
      this.setState({ isSearchDropDownOpen: false });
      this.setState({ isSearchDropDownOpenOp: false });
      const params = new URLSearchParams(search_params);
      const name = params.get("name");
      const type = params.get("type");
      const searchId = params.get("account_id");
      if (
        name !== undefined &&
        name !== null &&
        searchId !== undefined &&
        searchId !== null
      ) {
        this.onSearchInputSingleData(name, searchId, "accounts");
      }
      if (type === "Activities" || type === "Opportunities") {
        this.setState({
          opportunityActivity: type
        })
        const product_services = params.get("services");
        let productList = product_services.split(",");
        if (productList !== undefined && productList.length !== 0) {
          this.getStaticDataForParent("PRODUCT_SERVICES", productList[0]);
          this.setContextValueForList(
            "feedBackProductServicesType",
            productList
          );
          this.setContextValueForList("advertisingSubProduct", productList);
          this.setContextValue("selectedValuesProductServices", productList);
          this.validationSubmit();
          this.setContextValue(CSAT_Score, "0");
          this.handleNext();
        }
      }
      if (type === "Opportunities") {
        const opportunities = params.get("opportunities");
        const opportunities_id = params.get("opportunities_id");
        const opportunities_stage = params.get("opportunities_stage");
        //this.onSearchInputSingleData(opportunities, "opportunities");
        let data = {
          name: opportunities,
          id: opportunities_id,
          stage: opportunities_stage,
        };
        this.handleOpportunitySelection(data);
      } else if (type === "Activities") {
        const context = params.get("context");
        this.setContextValue("additionalContext", context);
      }
      this.validationSubmit();
    }
  }

  loadFormData() {
    const { history } = this.props;
    const location = history.location;
    const search_params = location.search;
    if (search_params !== "" && search_params !== undefined) {
      const params = new URLSearchParams(search_params);
      const type = params.get("type");
      if (type === "Activities") {
        const product_services = params.get("services");
        let productList = product_services.split(",");
        const context = params.get("context");
        this.setContextValue("additionalContext", context);
        let accounts = JSON.parse(
          localStorage.getItem("account_details") || "[]"
        );
        const feedback_category = localStorage.getItem("feedback-category");
        const feedback_type = localStorage.getItem("feedback-type");
        if (
          feedback_type !== undefined &&
          feedback_type !== "" &&
          feedback_type !== null &&
          feedback_type !== "null"
        ) {
          this.setContextValue("feedBackType", feedback_type);
          this.setContextValue("category", feedback_type);
          this.setContextValue("feedBackCategoryType", feedback_category);
          this.setContextValue("subCategory", feedback_category);
          this.setContextValueForList(
            "feedBackProductServicesType",
            productList
          );
          this.setContextValueForList("advertisingSubProduct", productList);
          this.setContextValue("selectedValuesProductServices", productList);
        }
        this.handleAccountSelection(accounts[0]);
        this.setState({ isSearchDropDownOpen: false });
        this.validationSubmit();
        this.setContextValue(CSAT_Score, "0");
        this.handleNext();
      } else if (type === "Opportunities") {
        const product_services = params.get("services");
        let productList = product_services.split(",");
        let accounts = JSON.parse(
          localStorage.getItem("account_details") || "[]"
        );
        let opportunities = JSON.parse(
          localStorage.getItem("opportunity_details") || "[]"
        );
        const feedback_category = localStorage.getItem("feedback-category");
        const feedback_type = localStorage.getItem("feedback-type");
        if (
          feedback_type !== undefined &&
          feedback_type !== "" &&
          feedback_type !== null &&
          feedback_type !== "null"
        ) {
          this.setContextValue("feedBackType", feedback_type);
          this.setContextValue("category", feedback_type);
          this.setContextValue("feedBackCategoryType", feedback_category);
          this.setContextValue("subCategory", feedback_category);
          this.setContextValueForList(
            "feedBackProductServicesType",
            productList
          );
          this.setContextValueForList("advertisingSubProduct", productList);
          this.setContextValue("selectedValuesProductServices", productList);
        }
        this.handleAccountSelection(accounts[0]);
        this.setState({ isSearchDropDownOpen: false });
        this.handleOpportunitySelection(opportunities[0]);
        this.validationSubmit();
        this.setState({ isSearchDropDownOpenOp: false });
        this.setContextValue(CSAT_Score, "0");
        this.handleNext();
      }

      if (type === "Blockers") {
        const revenueImpacted = params.get("revenueImpacted");
        let accounts = JSON.parse(
          localStorage.getItem("account_details") || "[]"
        );
        this.setContextValue("revenueImpacted", revenueImpacted);
        this.handleAccountSelection(accounts[0]);
        this.setState({ isSearchDropDownOpen: false });
        this.validationSubmit();
        this.setContextValue(CSAT_Score, "0");
        this.handleNext();
        const context = params.get("context");
        this.setContextValue("additionalContext", context);
      }
    }
  }

  loadStaticData() {
    this.setState({ isLoading: true });
    const params = {};
    apiClient
      .get("/static?size=10000", { params })
      .then((response) => {
        this.setState({
          staticData: response?.data?.results?.map((stat) => {
            return Object.assign(stat.fields, { id: stat.id });
          }),
        }, () => {
          let categories = this.getStaticData("CATEGORY");
          let products = this.getStaticData("PRODUCT");
          let feedbacktypes = this.getStaticData("FEEDBACK_TYPE");
          this.setState({
            categories,
            products,
            feedbacktypes,
          });
        });
        
      })
      .catch((error) => {
        Logger.sessionError(`FeatureTable static ${error}`, {
          browserUrl: window.location.href,
        });
        this.setState({ isError: true });
      })
      .then(() => {
        this.setState({ isLoading: false });
      });
  }


  componentDidMount() {
    const { token, user } = this.context;
    this.setState({
      token,
      user,
    });
    const { mode } = this.props;
    this.loadStaticData();
    this.loadFormDataAutoFill();
    const { id } = this.props.match.params;
    if(id && MODES.includes(mode) && mode!=='template'){
      this.setState({
        feedbackId: id,
        validateNext: true,
      }, ()=>{
        this.getFeedbackDetails(id);
      })
    }
    if(id && mode==='template'){
      this.setState({
        templateId: id,
        validateNext: true,
      }, ()=>{
        this.getTemplateDetails(id);
      })
    }
    Logger.sessionInfo("User landed on submit feedback page", {
      emfLog: createEMFObject("ACFSubmitFeedbackPageHits", 1, "Count"),
    });
    logPageMetric(Page.SubmitFeedbackPage);
  }

  getTemplateDetails(id) {
    this.setState({ isLoading: true });
    apiClient.get(`/templates/${id}`)
    .then((response) => {
      let templateDetails = Object.assign(
        {},
        response.data?.template?.fields
      );
      const {fields:{feedbackType,feedbackCategory,productServices,feedbackLabel,additionalContext}} = response.data?.template;
      const fields = {
        feedBackType: feedbackType,
        category: feedbackType,
        feedBackCategoryType: feedbackCategory,
        subCategory: feedbackCategory,
        feedBackProductServicesType: productServices,
        additionalContext,
        selectedLabelsPayload: feedbackLabel,

      }
      this.setState({
        formContext: fields,
        templateDetails,
      });
      if (productServices) {
        let productList = productServices.split(",");
        this.setContextValueForList(
          "feedBackProductServicesType",
          productList
        );
        this.setContextValueForList("product_services", productList);
        this.setContextValueForList("advertisingSubProduct", productList);
        this.setContextValue("selectedValuesProductServices", productList);
        this.setState({
          selectedValuesProductServices: productList
        })
      }
    })
    .catch(err=>{
      Logger.sessionError(`template fetch failed, ${err}`, {
        browserUrl: window.location.href,
      });
      this.setState({ isError: true });
    })
    .then(() => {
      this.setState({ isLoading: false });
    });
  }

  getFeedbackDetails(id) {
    this.setState({ isFeedbackDetailsLoading: true });
    const { mode } = this.props;
    apiClient
      .get(`/feedbacks/${id}`)
      .then((response) => {
        let savedFeedbackdetails = Object.assign(
          {},
          response.data?.feedback?.fields
        );
        const fields = response.data?.feedback?.fields;
        const featureName = response.data?.meta?.featureName;
        const featureId = fields?.featureId;
        const featureStatus = response.data?.meta?.featureStatus;
        const featureDescription = response.data?.meta?.featureDescription;
        fields["featureName"] = featureName;
        fields["featureStatus"] = featureStatus;
        fields["featureDescription"] = featureDescription;
        if (fields["revenueImpacted"]) {
          fields["revenueImpacted"] = fields["revenueImpacted"].split(".")[0];
        }
        this.setState({
          isFeedbackDetailsLoading: false,
          formContext: fields,
          nextClicked: true,
          oldFeatureId: featureId,
          whileSearchText: featureName,
          searchText: featureName,
          featureSearchQuery: featureName,
          opportunitySearchQuery: fields.opportunityName,
          accountSearchQuery: fields.accountName,
          savedFeedbackdetails,
        });
        const { formContext } = this.state;
        let product_services = formContext["feedBackProductServicesType"]
          ? formContext["feedBackProductServicesType"]
          : formContext["advertisingSubProduct"];
        if (formContext["feedBackProductServicesType"]) {
          let productList = product_services.split(",");
          this.setContextValueForList(
            "feedBackProductServicesType",
            productList
          );
          this.setContextValueForList("product_services", productList);
          this.setContextValueForList("advertisingSubProduct", productList);
          this.setContextValue("selectedValuesProductServices", productList);
        }
        if(mode==="edit"){
          // this.setState({hasSizmekAccounts: true}, () => {
          //   this.getSizmekAccounts({id: fields.accountId})
          // });
          let agencyAccountArr = "";
          if(savedFeedbackdetails["accountType"]==="Agency"){
            if(formContext["agencyAssociatedAccountId"] && formContext["agencyAssociatedAccountName"]){
              agencyAccountArr = formContext["associatedAgencyAccount"].split(";");
              this.setState({accountSelectionAllowed: false});
            }
            this.setState({agencyAccountLoading: true, selectedAgencyAccount: agencyAccountArr},() => {
              this.getAgencyAccounts({id: fields.accountId});
            })
          }
        }
        if (
          mode==="clone" &&
          formContext["source"] &&
          formContext["source"] === "template"
        ) {
          let productList = product_services.split(",");
          this.setContextValueForList(
            "feedBackProductServicesType",
            productList
          );
          this.setContextValueForList("product_services", productList);
          this.setContextValueForList("advertisingSubProduct", productList);
          this.setContextValue("selectedValuesProductServices", productList);
          let categoryValue = formContext["category"];
          this.setContextValue("feedBackType", categoryValue);
          let subCategoryValue = formContext["subCategory"];
          this.setContextValue("feedBackCategoryType", subCategoryValue);
        }

        if (mode==="clone") {
          const itemsToDelete = ["revenueImpacted", "csatScore", "opportunityId", "opportunityName", "opportunityStage", "opportunityValue", "accountName", "attachmentDetails", "attachments"];
          itemsToDelete.forEach(field=>{
            delete formContext[field];
          })
          this.setState({ accountSearchQuery: "" });
          if (formContext["translatedCustomerVerbatim"]) {
            delete formContext["translatedCustomerVerbatim"];
          }
          if (formContext["translatedAdditionalContext"]) {
            delete formContext["translatedAdditionalContext"];
          }
          if (formContext["translatedWhyThisImportant"]) {
            delete formContext["translatedWhyThisImportant"];
          }
          this.setState({ opportunitySearchQuery: "" });
        }
        this.validationSubmit();
      })
      .catch((error) => {
        Logger.sessionError(`FeedbackDetail fetch submitPage ${error}`, {
          browserUrl: window.location.href,
        });
        this.setState({ isError: true });
      });
  }

  mappingForMultiselectSearch = (list) =>
    list.map((key) => ({ item: { label: key, key } }));


  async onUpload(e) {
    if(e.target.files.length === 0) {
      return null
    }
    const ValidFileName = this.validateFileName(e.target.files);
    const validFileType = this.validateFileType(e.target.files);
    const ValidFileSize = this.validateFileSize(e.target.files);

    if(ValidFileName && validFileType && ValidFileSize) {
      let { attachmentsMetaData, attachmentValidationError } = this.state;
      const { mode } = this.props;
      for(let i = 0; i<e.target.files.length; i++){
        const fileNameKey = e.target.files[i].name.split('.')[0]
        const metaData = {
          name: e.target.files[i].name.split('.')[0],
          type: e.target.files[i].type,
          size: e.target.files[i].size,
        };
        try{
          metaData[fileNameKey] = await new Blob([e.target.files[i]], metaData).arrayBuffer();
        } catch(err) {
          Logger.sessionError(`unable to convert to array Buffer, ${err}`);
        }
        if(attachmentsMetaData) {
          if(this.fileWithSameNameExists(e.target.files[i].name.split('.')[0], attachmentsMetaData)){
            attachmentValidationError['name'] = "Files with same name not allowed";
            this.setState({
              attachmentValidationError,
            });
            return
          }
          attachmentsMetaData.push(metaData);
        }
        else {
          attachmentsMetaData = [metaData];
        }
        
      }

      if(mode === 'edit'){
        this.handleEditValidation(
          ATTACHMENTS,
          ATTACHMENTS_KEY,
          attachmentsMetaData.length
        );
      }
      this.setState({
        attachmentsMetaData,
      });
    }
  }

  fileWithSameNameExists(file, existingFileObjects){
    for(let i = 0; i< existingFileObjects.length; i++){
      if(file===existingFileObjects[i].name){
        return true;
      }
    }
    return false;
  }

  validateFileName(files) {
    const { attachmentValidationError } = this.state;
    for( let i = 0; i<files.length; i++){
      const file = files.item(i);
      if(file.name.length>50) {
        attachmentValidationError['name'] = "File name too long";
        this.setState({
          attachmentValidationError,
        });
        return false;
      }
    }
    delete attachmentValidationError['name'];
    this.setState({
      attachmentValidationError
    })
    return true;
  }

  validateFileType(files) {
    const { attachmentValidationError } = this.state;
    for( let i = 0; i<files.length; i++){
      const file = files.item(i);
      if(!VALIDFILETYPE.includes(file.type) ) {
        attachmentValidationError['type'] = "Invalid file type! Please select .txt .xls, .csv., .png or .jpeg";
        this.setState({
          attachmentValidationError
        });
        return false;
      }
    }
    delete attachmentValidationError['type']
    this.setState({
      attachmentValidationError
    })
    return true;
  }

  validateFileSize(files) {
    const { attachmentValidationError } = this.state;
    for( let i = 0; i<files.length; i++){
      const file = files.item(i);
      if(file.size > 14000000 ) {
        attachmentValidationError['size'] = "Upload a file with a max size of 14 MB";
        this.setState({
          attachmentValidationError
        });
        return false;
      }
    }
    delete attachmentValidationError['size']
    this.setState({
      attachmentValidationError
    })
    return true;
  }

  handleRemoveAttachment(key) {
    const {attachmentsMetaData} = this.state;
    const { mode } = this.props;
    attachmentsMetaData.splice(key,1);
    if(mode === 'edit'){
      this.handleEditValidation(
        ATTACHMENTS,
        ATTACHMENTS_KEY,
        attachmentsMetaData.length
      );
    }
    this.setState({
      attachmentsMetaData
    })
  }

  alertUploadError() {
    const { attachmentValidationError } = this.state;
    return (
      Object.keys(attachmentValidationError).map((error,key) => (
        <InlineMessage key={key} messageType="error" message={attachmentValidationError[error]} />
      ))
    )
  }

  render() {
    const { isError, isLoading, attachmentsMetaData } = this.state;
    const { mode,openConfirmationModal } = this.props;
    if(openConfirmationModal){
      return(
        <ActionConfirmationComponent 
          action="close the form"
          ActionWarningMessage="Your progress will be lost!"
          closeModal= {this.handleConfirmationModalClose}
          confirmAction = {this.handleCancelConfirmationClick}
        />
      )
    }
    if (isLoading || isError) {
      return (
        <div className="container-container">
          {isLoading && <Loader />}
          {isError && (
            <Alert type="error">Something went wrong. Please try again.</Alert>
          )}
        </div>
      );
    }
    const {
      formContext,
      feedbacktypes,
      accountSearchQuery,
      opportunitySearchQuery,
      isSearchLoading,
      sizmekAccountLoading,
      agencyAccountLoading,
      whileSearchText,
      isSearchDropDownOpen,
      isSearchDropDownOpenOp,
      hasSizmekAccounts,
      sizmekAccountsQuery,
      agencyAccountsQuery,
      filteredAgencyAccount,
      validateSubmit,
      currencyInvalid,
      accountN,
      feedBackC,
      feedBackT,
      feedBackP,
      customerV,
      satisfactionScoreRequired,
      selectedValuesProductServices,
      filteredOptions,
      allProduct,
      searchObjWithGroup,
      searchObjWithGroupSearch,
      labelFilteredOptions,
      validateEdit,
      attachmentValidationError,
      selectedAgencyAccount,
      whyThisImportantInvalid,
      isLongAccountN,
      isLongOpportunityName,
      accountSelectionAllowed,
      agencyAccountError
    } = this.state;
    const {
      csatScore,
      customerVerbatim,
      revenueImpacted,
      whyThisImportant,
      additionalContext,
      feedBackType,
      feedBackCategoryType,
      sizmekAccount,
      selectedLabelsPayload
    } = formContext;

    const overRideLabelWrapper = (calledField) => {
      const OverrideLabel = (selectedValues, selectedItems) => {
        if (selectedValues.length === 0) {
          return `Choose one or multiple ${calledField}`;
        }
        const prefix = (
          <span key={`prefix-${selectedValues.length}`}>
            ( {selectedValues.length} - values for {calledField} selected )
          </span>
        );
        //return [prefix, ' ' ,label];
        return [prefix];
      };
      return OverrideLabel;
    };
    const renderDropdownItems = (items) =>
      items.map((category, index) => (
        <MultiSelectItem key={category.item.label} value={category.item.label}>
          {category.item.label}
        </MultiSelectItem>
      ));
    const renderAllDropDownValues = (data) => {
      let items = [];
      for (let category of data) {
        items.push(
          <DropdownItemGroup label={category.group}></DropdownItemGroup>
        );
        let dropDownValues = category.values;
        for (let dropDown of dropDownValues) {
          items.push(
            <MultiSelectItem
              key={dropDown.item.label}
              value={dropDown.item.label}
            >
              {dropDown.item.label}
            </MultiSelectItem>
          );
        }
      }
      return items;
    };
    let feedBackCategoryTypes = this.getStaticData(
      "FEEDBACK_CATEGORY",
      feedBackType
    );
    let fetchedLabels = this.getStaticData("FEEDBACK_LABEL",feedBackCategoryType);
    let feedBackProductServicesTypes = this.getStaticData(
      "PRODUCT_SERVICES",
      feedBackCategoryType
    );
    if (feedBackProductServicesTypes.length === 0) {
      feedBackProductServicesTypes = ["Others"];
    }
    if (
      feedBackProductServicesTypes.length === 1 &&
      feedBackProductServicesTypes[0] === "All Products"
    ) {
      if (!allProduct) {
        this.setState({ allProduct: true });
      }
      feedBackProductServicesTypes = this.getStaticData("PRODUCT_SERVICES", "");
      feedBackProductServicesTypes = feedBackProductServicesTypes.filter(
        (word) => word !== "All Products"
      );
      feedBackProductServicesTypes = feedBackProductServicesTypes.filter(
        (word) => word !== "NONE"
      );
      let feedbackCategoryGroup = this.getStaticData("FEEDBACK_CATEGORY");
      feedbackCategoryGroup = feedbackCategoryGroup.filter(
        (word) =>
          word !== "International Expansion / Multi-Marketplace" &&
          word !== "Marketing Resources & Insights" &&
          word !== "Other" &&
          word !== "General"
      );
      let uSet1 = new Set(feedbackCategoryGroup);
      feedbackCategoryGroup = Array.from(uSet1);
      feedBackProductServicesTypes.push(...feedbackCategoryGroup);
      let uSet = new Set(feedBackProductServicesTypes);
      feedBackProductServicesTypes = Array.from(uSet);
    } else {
      if (allProduct) {
        this.setState({ allProduct: false });
      }
    }
    feedBackProductServicesTypes.sort();
    feedBackCategoryTypes.sort();
    feedbacktypes.sort();
    fetchedLabels.sort();
    if (feedBackType === "" || feedBackType === undefined) {
      feedBackCategoryTypes = [];
      feedBackProductServicesTypes = [];
    }
    if (
      feedBackType === "" ||
      feedBackType === undefined ||
      feedBackCategoryType === "" ||
      feedBackCategoryType === undefined
    ) {
      feedBackProductServicesTypes = [];
      fetchedLabels = [];
    }
    if (allProduct && searchObjWithGroup.length === 0) {
      let feedbackCategoryGroup = this.getStaticData("FEEDBACK_CATEGORY");
      feedbackCategoryGroup = feedbackCategoryGroup.filter(
        (word) =>
          word !== "International Expansion / Multi-Marketplace" &&
          word !== "Marketing Resources & Insights" &&
          word !== "Other"
      );
      feedbackCategoryGroup.forEach((word) => {
        let servicesData = this.getStaticData("PRODUCT_SERVICES", word);
        servicesData.sort();
        if (servicesData[0] !== "All Products") {
          let items = servicesData.map((key) => {
            if (word === "Custom / Other" && key === "Other") {
              key = "Other ";
            }
            if (word === "Creative" && key === "General") {
              key = "General ";
            }
            let item = {
              label: key,
              key: key,
            };
            return { item };
          });
          let data = {
            group: word,
            values: items,
          };
          searchObjWithGroup.push(data);
        }
      });
    }
    const mappingforFilterDropDown = items => items.map(item=>item.item)
    const onLabelFilterChange = (items) => {
      items=mappingforFilterDropDown(items)
      if (
        items.length === fetchedLabels.length
      ) {
        this.setState({
          labelFilteredOptions: fetchedLabels,
        });
      } else {
        this.setState({ labelFilteredOptions: items });
      }
    };

    const onLabelFilterClear = () => {
      this.setState({
        labelFilteredOptions: fetchedLabels,
      });
    };

    const onProductServicesFilterChange = (items) => {
      const filterItems = this.mappingForMultiselectSearch(
        feedBackProductServicesTypes
      );
      if (items.length === filterItems.length) {
        this.setState({ filteredOptions: filterItems });
        if (allProduct) {
          this.setState({ searchObjWithGroupSearch: searchObjWithGroup });
        }
      } else {
        this.setState({ filteredOptions: items });
        if (allProduct) {
          let obj = [];
          searchObjWithGroup.forEach((grp) => {
            let group = grp.group;
            let val = grp.values;
            let intersection = this.getMatch(val, items);
            if (intersection !== undefined && intersection.length > 0) {
              let dataItem = intersection.map((key) => {
                let item = {
                  label: key,
                  key: key,
                };
                return { item };
              });
              let data = {
                group: group,
                values: dataItem,
              };
              obj.push(data);
            }
          });

          if (obj.length === 0 && items.length > 0) {
            for (const itemp of items) {
              searchObjWithGroup.forEach((grp) => {
                let group = grp.group;
                let val = grp.values;
                let itemVal = itemp.item.key;
                if (group.indexOf(itemVal) !== -1) {
                  let dataItem = val.map((key) => {
                    let item = {
                      label: key.item.key,
                      key: key.item.key,
                    };
                    return { item };
                  });
                  let data = {
                    group: group,
                    values: dataItem,
                  };
                  obj.push(data);
                }
              });
            }
          }
          this.setState({ searchObjWithGroupSearch: obj });
        }
      }
    };

    const onProductServicesFilterClear = () => {
      const filterItems = this.mappingForMultiselectSearch(
        feedBackProductServicesTypes
      );
      this.setState({ filteredOptions: filterItems });
      if (allProduct) {
        this.setState({ searchObjWithGroupSearch: searchObjWithGroup });
      }
    };

    const renderMultiSelectSearchPanel = (
      itemList,
      filterChangeCallback,
      filterClearCallback
    ) => (
      <MultiSelectSearchPanel
        items={itemList.map((key) => {
          return { label: key, key };
        })}
        options={{ keys: ["label"], threshold: 0.3 }}
        onFilterChange={filterChangeCallback}
        onFilterClear={filterClearCallback}
      />
    );

    return (
      <ThemeProvider theme={overrideZIndex}>
      <div style={{ width: "650px" }}>
        <Form id="new-feedback">
          <FormRow
            helpTipText={`Include the name of the account (advertiser, agency, customer) that is providing feedback. For internal feedback, use "CLF Internal Feedback". For templates, use "CLF Feedback Templates`}
            label="Account Name"
            id="account-name-row"
            optionalTag="Required"
            isSectionHeader
            controlComponents={[
              (componentId) => (
                <div
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  style={{ position: "relative" }}
                >
                  <SearchInput
                    fullWidth
                    id={componentId}
                    placeholder="Search for account name"
                    error={accountN}
                    disabled={!accountSelectionAllowed}
                    statusType={isLongAccountN?'error':""}
                    message={isLongAccountN?"Account name too long. Maximum of 50 characters allowed" : ""}
                    onChange={({ target }) => {
                      this.setState({
                          searchText:target.value,
                          accountSearchQuery:target.value,
                          searchType: "accounts",
                          showAgency: false,
                          hasSizmekAccounts: false
                        },
                        this.onSearchInputThrottle
                      );
                      this.handleSizmekAccountSelection(null);
                      this.handleAgencyAccountSelection(null);
                      if(target.value.length<=50){
                        this.setState({
                            searchText:target.value,
                            accountSearchQuery:target.value,
                            searchType: "accounts",
                            hasSizmekAccounts: false,
                            isSearchDropDownOpen: true,
                            isLongAccountN: false
                          },
                          this.onSearchInputThrottle
                        );
                        this.handleSizmekAccountSelection(null);
                      } else {
                        this.setState({
                          searchText: target.value, accountSearchQuery: target.value, searchType: "accounts", searchSuggestions: [],
                          isSearchLoading: false,
                          isSearchDropDownOpen: false,
                          isLongAccountN: true
                        });
                      }
                    }}
                    onClear={() => {
                      this.setState({
                        searchText: "", accountSearchQuery: "", searchType: "accounts", searchSuggestions: [],
                        isSearchLoading: false,
                        whileSearchText: "Search field is empty",
                        showAgency: false,
                      });
                      this.handleAccountSelection(null);
                      this.setContextValue("customerContact", "");
                      this.validationSubmit();
                    }}
                    value={accountSearchQuery}
                    onFocus={() => {
                      this.setState({ isSearchDropDownOpen: true });
                    }}
                  />
                  {sizmekAccountLoading ? (
                      <Row alignmentHorizontal="center" spacingInset="100">
                        <Loader small={true}/>
                      </Row>
                  ) : (
                      <div />
                  )}
                  {agencyAccountLoading ? (
                      <Row alignmentHorizontal="center" spacingInset="100">
                        <Loader small={true}/>
                      </Row>
                  ) : (
                      <div />
                  )}
                  {isSearchDropDownOpen ? (
                    <div className="searchDropdown">
                      <Text
                        className="searchHeader"
                        fontSize="tiny"
                        textColor="tertiary"
                      >
                        {whileSearchText}
                      </Text>
                      {isSearchLoading ? (
                        <Row alignmentHorizontal="center" spacingInset="400">
                          <Loader />
                        </Row>
                      ) : (
                        <div />
                      )}
                      {this.state.searchSuggestions.map((item) => {
                        return (
                          <div
                            className="searchItem"
                            key={item}
                            onClick={() => {
                              this.handleSizmekAccountSelection(null);
                              this.handleAgencyAccountSelection(null);
                              this.setState({sizmekAccountLoading: true});
                              this.setState({agencyAccountLoading: true});
                              this.handleAccountSelection(item);
                              this.setState({ isSearchDropDownOpen: false });
                              this.validationSubmit();
                              this.handleNext();
                              this.handleEditValidation(
                                ACCOUNT_NAME,
                                ACCOUNT_NAME_KEY,
                                item.id
                              );
                              this.handleEditValidation(
                                  SIZMEK_ACCOUNT_NAME,
                                  SIZMEK_ACCOUNT_NAME_KEY,
                                  ""
                              );
                            }}
                          >
                            {item?.name}
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
              ),
            ]}
          />
          {(hasSizmekAccounts) &&
            <FormRow
                id="account-name-row"
                label="Amazon Ads Server"
                isSectionHeader
                controlComponents={[
                  (componentId) => (
                      <Dropdown
                          fullWidth
                          id={componentId}
                          onChange={(item) => {
                            this.handleSizmekAccountSelection(item);
                            this.validationSubmit();
                            this.handleNext();
                            this.handleEditValidation(
                                SIZMEK_ACCOUNT_NAME,
                                SIZMEK_ACCOUNT_NAME_KEY,
                                item.id
                            );
                          }}
                          error={accountN}
                          onOverrideLabel={(dropdownItem, value) => {
                            if (value) {
                              return value;
                            }
                            return "Amazon Ads Server";
                          }}
                          selectedValue={sizmekAccount}
                      >
                        {
                          sizmekAccountsQuery.map((sizmekAcc, index) => (
                            <DropdownItem key={sizmekAcc.sizmekId} value={sizmekAcc}>
                              {sizmekAcc.name}
                            </DropdownItem>
                        ))}
                      </Dropdown>
                  ),
                ]}
            />
          }
          {(this.state.showAgency) &&
              <FormRow
                  id="agency-account-name-row"
                  label="Associated Accounts"
                  isSectionHeader
                  controlComponents={[
                    (componentId) => (
                        <Column spacing="200" >
                          {(agencyAccountError && accountSelectionAllowed) &&
                              <InlineMessage messageType="error" message="Feedback for your agency cannot be modified to direct advertiser feedback. Please create a new feedback for the direct advertiser account" />
                          }
                          <MultiSelect
                              name="AgencyAccount"
                              selectedValues={selectedAgencyAccount}
                              fullWidth={true}
                              disabled={!accountSelectionAllowed}
                              onChange={(item) => {
                                  this.handleAgencyAccountSelection(item);
                                  this.validationSubmit();
                                  this.handleNext();
                                  this.handleEditValidation(
                                      AGENCY_ACCOUNT,
                                      AGENCY_ACCOUNT_ID,
                                      item.join(";")
                                  );
                              }}
                              withSearchPanel={
                                <MultiSelectSearchPanel
                                  items= {agencyAccountsQuery.map((key) => {
                                      return { label: JSON.parse(key).name, key };
                                    })}
                                  options={{keys:["label"], threshold:0.3}}
                                  onFilterChange={(items)=> {
                                    if(items.length !== agencyAccountsQuery.length) {
                                      const results = items.map((result) => {
                                        return result.item.key;
                                      });
                                      this.setState({filteredAgencyAccount: results});
                                    } else {
                                      this.setState({filteredAgencyAccount: agencyAccountsQuery});
                                    }
                                  }}
                                  onFilterClear={ () => this.setState({filteredAgencyAccount: agencyAccountsQuery})}
                                />
                              }
                              onOverrideLabel={overRideLabelWrapper("Asssociated Accounts")}
                              error={agencyAccountError}
                          >
                            {
                              filteredAgencyAccount.map((agencyAcc)=>(
                                  <MultiSelectItem key={agencyAcc} value={agencyAcc}>
                                    {JSON.parse(agencyAcc).name}
                                  </MultiSelectItem>
                              ))
                            }
                          </MultiSelect>
                        </Column>
                    )
                  ]}
              >

              </FormRow>
          }
          <Divider />
          <FormRow
            helpTipText="Select what area of advertising experience the anecdote is related to"
            label="Feedback Type"
            isSectionHeader
            id="feedback-type-row"
            optionalTag="Required"
            controlComponents={[
              (componentId) => (
                <Dropdown
                  fullWidth
                  id={componentId}
                  onChange={(value) => {
                    this.setContextValue("feedBackType", value);
                    this.setContextValue("category", value);
                    this.setContextValue("feedBackCategoryType", "");
                    this.setContextValue("feedBackProductServicesType", "");
                    this.setContextValue("selectedValuesProductServices", []);
                    this.setContextValue("selectedLabelsPayload", "");
                    this.setState({
                      selectedValuesProductServices: [],
                    });
                    this.validationSubmit();
                    this.handleEditValidation(
                      FEEDBACK_TYPE,
                      FEEDBACK_TYPE_KEY,
                      value
                    );
                  }}
                  error={feedBackT}
                  onOverrideLabel={(dropdownItem, value) => {
                    if (value) {
                      return dropdownItem;
                    }
                    return "Feedback Type";
                  }}
                  selectedValue={feedBackType}
                >
                  {feedbacktypes.map((category, index) => (
                    <DropdownItem key={category} value={category}>
                      {category}
                    </DropdownItem>
                  ))}
                </Dropdown>
              ),
            ]}
          />
          <FormRow
            label="Feedback Category"
            isSectionHeader
            id="feedback-category-row"
            optionalTag="Required"
            controlComponents={[
              (componentId) => (
                <Dropdown
                  error={feedBackC && !feedBackT}
                  fullWidth
                  id={componentId}
                  onChange={(value) => {
                    this.setContextValue("feedBackCategoryType", value);
                    this.setContextValue("subCategory", value);
                    this.setContextValue("feedBackProductServicesType", "");
                    this.setContextValue("selectedValuesProductServices", []);
                    this.setContextValue("selectedLabelsPayload", "");
                    this.setState({
                      selectedValuesProductServices: [],
                    });
                    this.validationSubmit();
                    this.handleEditValidation(
                      FEEDBACK_CATEGORY,
                      FEEDBACK_CATEGORY_KEY,
                      value
                    );
                  }}
                  onOverrideLabel={(dropdownItem, value) => {
                    if (value) {
                      return dropdownItem;
                    }
                    return "Feedback Category";
                  }}
                  selectedValue={feedBackCategoryType}
                >
                  {feedBackCategoryTypes.map((subCategory, index) => (
                    <DropdownItem key={subCategory} value={subCategory}>
                      {subCategory}
                    </DropdownItem>
                  ))}
                </Dropdown>
              ),
            ]}
          />
          <Divider />
          <FormRow
            label="Product/Service"
            isSectionHeader
            id="product-row"
            optionalTag="Required"
            controlComponents={[
              () => (
                <div className={feedBackP && !feedBackC ? "error-state" : ""}>
                  <MultiSelect
                    name="product-multi-select"
                    fullWidth
                    onChange={(value) => {
                      this.setContextValueForList(
                        "feedBackProductServicesType",
                        value
                      );
                      this.setContextValueForList(
                        "advertisingSubProduct",
                        value
                      );
                      this.setContextValue(
                        "selectedValuesProductServices",
                        value
                      );
                      this.validationSubmit();
                      this.handleEditValidation(
                        PRODUCT_SERVICES,
                        PRODUCT_SERVICES_KEY,
                        value.toString()
                      );
                    }}
                    onOverrideLabel={overRideLabelWrapper("Product/Services")}
                    selectedValues={selectedValuesProductServices}
                    withSearchPanel={renderMultiSelectSearchPanel(
                      feedBackProductServicesTypes,
                      onProductServicesFilterChange,
                      onProductServicesFilterClear
                    )}
                  >
                    {allProduct
                      ? renderAllDropDownValues(searchObjWithGroupSearch)
                      : renderDropdownItems(filteredOptions)}
                  </MultiSelect>
                </div>
              ),
            ]}
          />
          <FormRow
              helpTipText="Labels provide granular classification under select categories, and help route your feedback to the right team. Please select one, if available for your feedback category"
              label="Feedback Labels"
              isSectionHeader
              id="labels-row"
              controlComponents={[
                (componentId) => (
                    <FilterDropdown
                        searchInputId="filtered-dropdown-search-input"
                        placeholder="Search Label"
                        onFilterChange={onLabelFilterChange}
                        onFilterClear={onLabelFilterClear}
                        fullWidth
                        id={componentId}
                        onChange={(value) => {
                          this.setContextValue(
                              "selectedLabelsPayload",
                              value
                          );
                          this.validationSubmit();
                          this.handleEditValidation(
                              LABELS,
                              LABELS_KEY,
                              value
                          );
                        }}
                        onOverrideLabel={(dropdownItem, value) => {
                          if (value) {
                            return value;
                          }
                          return "Feedback Labels";
                        }}
                        selectedValue={selectedLabelsPayload}
                        items={fetchedLabels}
                    >
                      {labelFilteredOptions.map((category, index) => (
                          <DropdownItem key={category} value={category}>
                            {category}
                          </DropdownItem>
                      ))}
                    </FilterDropdown>
                ),
              ]}
          />
          <FormRow
            helpTipText="Rank your customer's satisfaction based on the Feedback Category or Product/Service selected with 1 being 'extremely unsatisfied' and 5 being 'extremely satisfied' ."
            label="Customer Satisfaction Score"
            isSectionHeader
            id="csat-row"
            optionalTag="Required"
            controlComponents={[
              () => (
                <React.Fragment>
                  <Text>How would you rate the customer's satisfaction?</Text>
                  <Text>1-Extremely Unsatisfied to 5-Extremely Satisfied</Text>
                  <div
                    className={
                      satisfactionScoreRequired
                        ? "error-state error-state-compact"
                        : ""
                    }
                  >
                    <ButtonGroup
                      name="device-type-filter"
                      onChange={(value) => {
                        this.setContextValue(CSAT_Score, value);
                        this.handleNext();
                        this.validationSubmit();
                        this.handleEditValidation(
                          CSAT_Score,
                          CSAT_Score_KEY,
                          value
                        );
                      }}
                      selectedValue={csatScore}
                    >
                      <ButtonGroupItem value="1">1</ButtonGroupItem>
                      <ButtonGroupItem value="2">2</ButtonGroupItem>
                      <ButtonGroupItem value="3">3</ButtonGroupItem>
                      <ButtonGroupItem value="4">4</ButtonGroupItem>
                      <ButtonGroupItem value="5">5</ButtonGroupItem>
                    </ButtonGroup>
                  </div>
                </React.Fragment>
              ),
            ]}
          />
          <FormRow
            helpTipText="This field should capture the advertiser's or customer's feedback, in their own words."
            label="Customer Quote"
            isSectionHeader
            id="customer-quote-row"
            optionalTag="Required"
            controlComponents={[
              (componentId) => (
                <TextAreaFormGroup
                  fullWidth
                  id={componentId}
                  onChange={(event) => {
                    this.setContextValue(
                      "customerVerbatim",
                      event.target.value
                    );
                    let source =
                      window.self !== window.top ? "salesforce" : "ACF Portal";
                    this.setContextValue("source", source);
                    this.validationSubmit();
                    this.handleEditValidation(
                      CUSTOMER_QUOTE,
                      CUSTOMER_QUOTE_KEY,
                      event.target.value
                    );
                  }}
                  statusType={customerV?'error':undefined}
                  value={customerVerbatim}
                />
              ),
            ]}
          />
          <FormRow
            helpTipText={<ul>
              <li>Product Bug: Technical or product issue with existing feature</li>
              <li>New Feature Request: A new feature ask or technical limitation for a product, service or tool</li>
              <li>General customer sentiment: Broad feedback or perspective from advertiser, account or internal</li>
              <li>Product launch feedback: Related to a new beta or a launch</li>
              <li>Revenue blocker/decline: Detailing why revenue dropped or is blocked</li>
            </ul>}
            label="Why is this important"
            isSectionHeader
            id="important-row"
            optionalTag="Required"
            controlComponents={[
              (componentId) => (
              <Dropdown
                  fullWidth
                  id={componentId}
                  onChange={(value) => {
                    this.setContextValue(
                      "whyThisImportant",
                      value
                    );
                    this.handleEditValidation(
                      WHY_IS_THIS_IMPORTANT,
                      WHY_IS_THIS_IMPORTANT_KEY,
                      value
                    );
                  }}
                  onOverrideLabel={(dropdownItem, value) => {
                    if (value) {
                      return value;
                    }
                    return "Why is this important";
                  }}
                  selectedValue={whyThisImportant}
                  error={customerVerbatim && whyThisImportantInvalid}
                >
                  {WHY_IS_THIS_IMPORTANT_DROPDOWN_ITEMS.map((imp, index) => (
                    <DropdownItem key={imp} value={imp.toLowerCase()}>
                      {imp}
                    </DropdownItem>
                  ))}
                </Dropdown>
              ),
            ]}
          />
          <FormRow
            helpTipText="Insert other information related to the feedback, including your perspective on the customer's situation or answering product-specific questions."
            label="Additional Context"
            isSectionHeader
            id="context-row"
            controlComponents={[
              (componentId) => (
                <React.Fragment>
                <Column spacing="200" >
                <TextAreaFormGroup
                  fullWidth
                  id={componentId}
                  onChange={(event) => {
                    this.setContextValue(
                      "additionalContext",
                      event.target.value
                    );
                    this.handleEditValidation(
                      ADDITIONAL_CONTEXT,
                      ADDITIONAL_CONTEXT_KEY,
                      event.target.value
                    );
                  }}
                  value={additionalContext}
                />
                  <FileUploadButton
                    inputId="file-uploader"
                    label="Attachments"
                    onChange={this.onUpload}
                    multiple
                  />
                  {/* <label for="attach">Upload</label>
                  <input type="file" onChange={this.onUpload} id="attach"/> */}
                  {Object.keys(attachmentValidationError).length>0 && 
                    this.alertUploadError()
                  }
                  {
                    attachmentsMetaData && attachmentsMetaData.length>0 && 
                    <UnorderedList>
                      {attachmentsMetaData.map((attachment,key)=> (
                          <ListItem key={key}>
                            <span>
                              {attachment.name}
                              <TextButton onClick={(e)=>this.handleRemoveAttachment(key)}>
                                 remove
                              </TextButton>
                            </span>
                          </ListItem>
                      ))}
                      </UnorderedList>
                  }
                </Column>
                </React.Fragment>
              ),
            ]}
          />
          <FormRow
            helpTipText="Include total blocked revenue for the associated feedback. 
          Insert “0” if no revenue is blocked. Estimate by analyzing historical data based on the account, product, or tool selected. Identify data points from past campaigns or ad spend based on the region, vertical, or similar accounts to help estimate. Accuracy is important to help teams measure impact."
            label="Blocked Revenue"
            isSectionHeader
            id="revenue-row"
            optionalTag="Required"
            controlComponents={[
              (componentId) => (
                <CurrencyInput
                  id={componentId}
                  fullWidth
                  currency="USD"
                  locale="en-US"
                  onChange={(event) => {
                    this.setContextValue("revenueImpacted", event.target.value);
                    this.validationSubmit();
                    this.handleEditValidation(
                      BLOCKED_REVENUE,
                      BLOCKED_REVENUE_KEY,
                      `${event.target.value}.0`
                    );
                  }}
                  statusType={currencyInvalid?'error':undefined}
                  message={
                    currencyInvalid ? this.renderErrorMessage() : undefined
                  }
                  value={revenueImpacted}
                />
              ),
            ]}
          />
          <FormRow
            helpTipText="Connect this feedback to an existing opportunity in Salesforce."
            label="Opportunity"
            isSectionHeader
            id="opportunity-row"
            controlComponents={[
              (componentId) => (
                <div
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  style={{ position: "relative" }}
                >
                  <SearchInput
                    fullWidth
                    id={componentId}
                    placeholder="Search for opportunity.."
                    statusType={isLongOpportunityName?"error": ""}
                    message={isLongOpportunityName ? "Opportunity text too long, maximum of 50 characters allowed" : "" }
                    onChange={({ target }) => {
                      if(target.value.length<=50){
                        this.setState({searchText: target.value, searchType: "opportunities",opportunitySearchQuery: target.value, isSearchDropDownOpenOp: true, isLongOpportunityName: false }, this.onSearchInputThrottle);
                      } else {
                        this.setState({
                          searchText: "", searchType: "opportunities", opportunitySearchQuery: target.value, searchSuggestions: [],
                          isSearchLoading: false,
                          isSearchDropDownOpenOp: false,
                          isLongOpportunityName: true
                        });
                      }
                    }}
                    onClear={() => {
                      this.setState({
                        searchText: "", searchType: "opportunities", opportunitySearchQuery: "", searchSuggestions: [],
                        isSearchLoading: false,
                        whileSearchText: "Search field is empty",
                      });
                      this.handleOpportunitySelection(null);
                      this.handleEditValidation(
                        OPPORTUNITY,
                        OPPORTUNITY_KEY,
                        ""
                      );
                    }}
                    onFocus={() => {
                      this.setState({ isSearchDropDownOpenOp: true });
                    }}
                    value={opportunitySearchQuery}
                  />
                  {isSearchDropDownOpenOp ? (
                    <div className="searchDropdown">
                      <Text
                        className="searchHeader"
                        fontSize="tiny"
                        textColor="tertiary"
                      >
                        {whileSearchText}
                      </Text>
                      {isSearchLoading ? (
                        <Row alignmentHorizontal="center" spacingInset="400">
                          <Loader />
                        </Row>
                      ) : (
                        <div />
                      )}
                      {this.state.searchSuggestions.map((item) => {
                        return (
                          <div
                            className="searchItem"
                            key={item}
                            onClick={() => {
                              this.handleOpportunitySelection(item);
                              this.validationSubmit();
                              this.setState({
                                isSearchDropDownOpenOp: false,
                              });
                              this.handleEditValidation(
                                OPPORTUNITY,
                                OPPORTUNITY_KEY,
                                item.id
                              );
                            }}
                          >
                            {item?.name}
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
              ),
            ]}
          />
          <Divider />
          <Row alignmentHorizontal="right">
            <TextButton
              alert={this.state.alert}
              onClick={this.handleCancelClick}
            >
              Cancel
            </TextButton>
            <Button
              disabled={
                mode === 'edit'
                  ? validateEdit
                    ? validateSubmit
                      ? false
                      : true
                    : true
                  : !validateSubmit
              }
              loading={this.state.loading}
              alert={this.state.alert}
              onClick={() =>
                mode === 'edit'
                  ? this.handleEditForCreateAndNew()
                  : this.handleSubmitForcreateAndNew()
              }
            >
              {"Submit & Create New"}
            </Button>
            <Button
              disabled={
                mode === 'edit'
                  ? validateEdit
                    ? validateSubmit
                      ? false
                      : true
                    : true
                  : !validateSubmit
              }
              primary={true}
              loading={this.state.loading}
              alert={this.state.alert}
              onClick={() =>
                mode === 'edit' ? this.handleEdit() : this.handleSubmit()
              }
            >
              {"Submit"}
            </Button>
          </Row>
        </Form>
      </div>
      </ThemeProvider>
    );
  }
}

SubmitFeedbackPage.contextType = UserContext;

export default withRouter(SubmitFeedbackPage);
