import * as React from "react";
import PropTypes from "prop-types";
import Column from "../column";
import Row from "../row";
import {Divider} from "@amzn/storm-ui";

class ObjectRow extends React.Component {
  render() {
    const { columnData, divider=true, spacingInset="none"} = this.props;

    return <><Row>
    {columnData.map(({dataKey, dataValue}, key) => (
      <Column key={key} width={`${100/columnData.length}%`} spacingInset={spacingInset}>
        <Row>
          <Column width="10%" alignmentHorizontal="left">
            {dataKey}
          </Column>
          <Column alignmentHorizontal="right">
            {dataValue}
          </Column>
        </Row>
        
      </Column>
      ))}
    </Row>
    {divider && <Divider />}
    </>
  }
}

ObjectRow.propTypes = {
  columnData: PropTypes.shape([]).isRequired,
};

export default ObjectRow;
