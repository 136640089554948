import * as React from "react";
import { withRouter } from "react-router-dom";
import Column from "../../components/column";
import Row from "../../components/row";
import "./FeedbackSuccessPage.scss";
import {getQueryParams} from "../../util/util";
import {  Text, Link } from '@amzn/storm-ui';

class FeedbackSuccessPage extends React.Component {
  constructor(props) {
    super(props);
    document.title = `Amazon Advertising Customer Feedback Portal`;
    this.state = {
      feedbackLink: ""
    }
  }

  componentDidMount() {
    let queryObj = getQueryParams(this.props.location?.search);
    let feedbackLink;
    if(queryObj["feedbackid"]) {
      feedbackLink = "/feedback/"+ queryObj["feedbackid"];
    }
    this.setState({feedbackLink: feedbackLink})
  }


  render() {
    let {feedbackLink} = this.state;
    return (
      <div className="success-container">
          <Row type="fill" backgroundColor="#053D65"  widths={["100px", "grid-2"]} spacingInset="large">
              <Column alignmentVertical="top" spacingInset="0 small large small">
                 <span className="icon-font">&#127881;</span>
              </Column>
              <Column spacingInset="medium">
                    <Row>
                         <Text  className="white-font">Thank you!</Text>
                    </Row>
                    <Row>
                         <Text type="h100" className="white-font">Your feedback has been submitted!</Text>
                         {feedbackLink && <Link href={feedbackLink}>here</Link>}
                    </Row>
                    <Link href={"/feature"} className="light-blue-font">View all features</Link>
                    <Link href={"/submitfeedback"} className="light-blue-font">Submit new feedback</Link>
              </Column>
          </Row>
      </div>
    );
  }
}

export default withRouter(FeedbackSuccessPage);
