import React, {useState, useEffect} from "react";
import { useHistory } from "react-router";
import { Table } from "../../components/unified-data-table/unified-data-table";
import Column from "../../components/column";
import { Text, Button, Icon, Alert } from "@amzn/storm-ui";
import Row from "../../components/row";
import {
    FooterPanelContainer,
    PanelGroup,
    Pagination,
    ClientSideExportButton,
    udcMultiSelectWithSearchFilter,
    MultiSelectWithSearchFilter,
    FilterBar,
    TextOperationValueFilter,
    ValidationStatusEnum,
    HeaderPanelContainer,
    AddFilterSelector,
    BulkActionSelector,
    setInitialFilterModel,
    CurrencyCellRenderer,
    CurrencyOperationValueFilter,
    DateRangeFilter,
    udcDateRangeFilter,
    ColumnManager, DateOperationValueFilter,
    NumberOperationValueFilter, setInitialColumnState
} from "@amzn/unified-data-table-components/pack";
import { download } from "@amzn/storm-ui-icons";
import {PAGINATION_CONSTANT, THEME_PRIORITY, THEME_STATUS, TABLE_ROWS_COUNT, SOURCE, IS_THEME_ADMIN, IS_MAPPING_REVIEWER} from "../../util/constants";
import { deleteTheme, getThemes, updateTheme } from "../../util/apiUtils";
import { comaSeparatedFilterComparator } from "../../util/tableFiltering";
import { useStore } from "../../store";
import CreateFeedbackThemesform from "./CreateFeedbackThemesform";
import Loader from "../../components/Loader";
import {formatDateTime, createEMFObject, getEnvUrl, dateRangeFilterComparator, jsonParseData} from "../../util/util";
import Logger, {logPageMetric} from "../../util/logger";
import { Page } from "../../typings/enum";
import ThemeNameCellWithQuote from "./ThemeNameCellWithQuote";
import TemplateLinkRenderer from "../admin-page/admin-feedback-templates/TemplateLinkRenderer";
import {getDateTimeCellComparator} from "@amzn/unified-data-table-components/core";
import {formatInTimeZone} from "date-fns-tz";
import {enUS} from "date-fns/locale";
import CustomBulkActionComponent from "../admin-page/custom-bulk-action-component/CustomBulkActionDialog";

const FeedbackThemes = (props) => {
    const history = useHistory();
    const [gridApi, setGridApi] = useState();
    const staticData = useStore((state) => state.staticData);
    const [productServices, setProductServices] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [duplicateThemeName, setDuplicateThemeName] = useState(false);
    const currency = "USD";
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const userInfo = useStore((state) => state.userInfo);
    const { user } = userInfo;
    let editValues = {};
    const { userPermissions } = useStore();

    useEffect(() => {
      let servicessList = staticData.filter(data => data.docType === "PRODUCT_SERVICES");
      let obj = [];
      servicessList = servicessList.filter(
        (word) => word.value !== "All Products"
      );
      let uSet = new Set(servicessList);
      servicessList = Array.from(uSet);
      servicessList.sort();
      servicessList.forEach((element) => {
        let data = {
          lineItem: element.value,
        };
        obj.push(data);
      });
      setProductServices(obj);
      Logger.sessionInfo("Themes Visited", {
        emfLog: createEMFObject("PageVisits", 1, "Count", {PageVisits:"FeedbackThemesVisits"}),
      });
      logPageMetric(Page.ThemeTablePage);
      // eslint-disable-next-line
    }, []);


    const filterString = {
      filterComponentProps: {
        filterOptions: {
          contains: { label: "contains", default: true },
          notContains: { label: "not contains", default: false },
          equals: { label: "equals", default: false },
          notEqual: { label: "not equal to", default: false },
        },
        inputParams: {
          placeholder: "Enter filter here",
        },
        validate: (input) => {
          if (input === null || input === undefined) {
            return {
              status: ValidationStatusEnum.Error,
              message: "Input cannot be empty",
            };
          }
          return {
            status: ValidationStatusEnum.Success,
          };
        },
      },
    };

    const handleExport = () => {
        gridApi.exportDataAsCsv({
          allColumns: true,
          onlySelected: false,
          columnKeys: [
            "themeName",
            "productServices",
            "themeStatus",
            "themePriority",
            "additionalContext",
            "ThemeblockedRevenue",
            "feedbackCount",
            "tag",
            "source",
            "createdTimestamp",
            "lastUpdateTimestamp",
            "link",
             "id",
             "submittedByName",
             "lastModifiedByName"
          ],
            processCellCallback: (params)  => {
                if(params.column.colId === "createdTimestamp" || params.column.colId === "lastUpdateTimestamp"){
                    return formatDateTime(params.value)
                } else {
                    return params.value;
                }
            },
          fileName: "exportThemes.csv",
        });
      }
    const footerElement = (
        <FooterPanelContainer>
          <PanelGroup align="start">
            <ClientSideExportButton 
              isDisabled={(rows, pageSize)=>(
                rows === 0 ? true: false
                )} 
              onExport={handleExport} 
              label={<Icon type={download} />}>
            </ClientSideExportButton>
          </PanelGroup>
          <PanelGroup align="end">
            <Pagination pageSizes={PAGINATION_CONSTANT} />
          </PanelGroup>
        </FooterPanelContainer>
      );
    
    const filterGroups = [
      {
        colId: "themeName",
        filterName: "Theme Name",
        filterComponent: TextOperationValueFilter,
        filterString,
      },
      {
        colId: "submittedByName",
        filterName: "Created by alias",
        filterComponent: TextOperationValueFilter,
        filterString,
      },
        {
            colId: "lastModifiedByName",
            filterName: "Modified by alias",
            filterComponent: TextOperationValueFilter,
            filterString,
        },
      {
        colId: "productServices",
        filterName: "Product/Service",
        filterComponent: MultiSelectWithSearchFilter,
        filterComponentProps: {
          shouldAllowAddRemoveAll: true,
          filterOptions: {
            clientSideOptionRows: productServices,
            getOptionLabel: (data) => data.lineItem,
            getOptionValue: (data) => data.lineItem,
            getOptionQuickFilterText: (data) => data.lineItem,
          },
        },
      },
      {
        colId: "themeStatus",
        filterName: "Theme Status",
        filterComponent: MultiSelectWithSearchFilter,
        filterComponentProps: {
          shouldAllowAddRemoveAll: true,
          filterOptions: {
            clientSideOptionRows: THEME_STATUS,
            getOptionLabel: (data) => data,
            getOptionValue: (data) => data,
            getOptionQuickFilterText: (data) => data,
          },
        },
      },
      {
        colId: "themePriority",
        filterName: "Priority",
        filterComponent: MultiSelectWithSearchFilter,
        filterComponentProps: {
          shouldAllowAddRemoveAll: true,
          filterOptions: {
            clientSideOptionRows: THEME_PRIORITY,
            getOptionLabel: (data) => data,
            getOptionValue: (data) => data,
            getOptionQuickFilterText: (data) => data,
          },
        },
      },
        {
            colId: "lastUpdateTimestamp",
            filterName: "Last Modified Date",
            filterComponent: DateOperationValueFilter,
            filterComponentsProps: {
                formatInputValue: (value) =>
                    formatInTimeZone(value, timezone, "PP", { locale: enUS }),
            },
            formatDisplayedValue: (value) =>
                formatInTimeZone(value, timezone, "PP", { locale: enUS }),
        },
        {
            colId: "revenueImpacted",
            filterName: "Blocked revenue",
            filterComponent: CurrencyOperationValueFilter,
        },
        {
            colId: "source",
            filterName: "Source",
            filterComponent: MultiSelectWithSearchFilter,
            filterComponentProps: {
                shouldAllowAddRemoveAll: true,
                filterOptions: {
                    clientSideOptionRows: SOURCE,
                    getOptionLabel: (data) => data,
                    getOptionValue: (data) => data,
                    getOptionQuickFilterText: (data) => data,
                },
            },
        },
        {
            colId: "feedbackCount",
            filterName: "Feedback count",
            filterComponent: NumberOperationValueFilter,
            filterString,
        },
    ];

    const bulkActionDefs = [
      {
        bulkActionComponent: (props) => <CreateFeedbackThemesform {...props} mode="Edit" />,
        bulkActionComponentProps: {
          duplicateThemeName,
          mode: "Edit",
          editedPayload: (map) => {
            editValues = {
              ...map
            }
          }
        },
        bulkActionId: 'editRowAction',
        label: 'Edit',
        getDisabledStatus: (selectedRowItem) => ({
          disabled: false,
        }),
        getSelectedRowLabel: (rowItem) => "Edit theme",
        onApply: async (params, values) => {  
          console.log(values); 
          console.log(editValues);
          const {themeStatus, selectedProductService, themeNameInput, additionalContext: description, themePriority} = editValues;
          const {selectedRowItems} = params;
          let {rowData: {id, themeName, additionalContext, productServices, updateSource, source}} = selectedRowItems[0];
          id = encodeURIComponent(id);
          const isNameChanges = (themeName !== themeNameInput).toString();
          const hasProductServiceChanged = (selectedProductService!==productServices).toString();
          const hasDescriptionChanged = (additionalContext!==description).toString();
          setIsLoading(true);
          const updateThemeObj = {
            themeId: id,
            input: {
                fields: {
                  ...selectedRowItems[0].rowData,
                  themeStatus,
                  productServices: selectedProductService,
                  themeName: themeNameInput,
                  additionalContext: description,
                  themePriority,
                  isNameChanges,
                  hasDescriptionChanged,
                  hasProductServiceChanged
                }
            }
          }
          try{
              const createdTheme = await updateTheme(updateThemeObj, id);
              if(!userPermissions[IS_MAPPING_REVIEWER] && (updateSource==='dataScience' || source==='dataScience') && (isNameChanges|| hasDescriptionChanged || hasProductServiceChanged)) {
                if(sessionStorage!==null) {
                  sessionStorage.setItem("isMappingApprovalCaseCreated", true);
                }
              }
              if(createdTheme?.data?.themeSummary === "Theme with same name is already exist so can not process the update request") {
                setDuplicateThemeName(true);
              } else {
                Logger.sessionInfo(`theme got updated ${id}`, {
                    emfLog: createEMFObject("UpdateTheme", 1, "Count"),
                });
                updateThemeSuccess(createdTheme)
              }
          }catch(err) {
              Logger.sessionError(`Theme Update error - ${err}`, {
                  emfLog: createEMFObject("ThemeUpdateError", 1, "Count"),
              });
              setIsError(true);
          }
          setIsLoading(false);
        },
      },
      {
        bulkActionComponent: CustomBulkActionComponent,
        bulkActionComponentProps: {
          
        },
        bulkActionId: 'deleteRowAction',
        label: 'Delete',
        getDisabledStatus: (selectedRowItem) => ({
          disabled: false,
        }),
        getSelectedRowLabel: (rowItem) => "Delete theme",
        onApply: async (params) => {
          const {selectedRowItems} = params;
          let {rowData: {id}} = selectedRowItems[0];
          id = encodeURIComponent(id);
          setIsLoading(true);
          try{
              const deletedTheme = await deleteTheme(id);
              Logger.sessionInfo(`theme got deleted ${id}`, {
                emfLog: createEMFObject("DeleteTheme", 1, "Count"),
              });
              updateThemeSuccess(deletedTheme)
          }catch(err) {
              Logger.sessionError(`Theme Update error - ${err}`, {
                  emfLog: createEMFObject("DeleteTheme", 1, "Count"),
              });
              setIsError(true);
          }
          setIsLoading(false);
        },
      },

    ]

    const updateThemeSuccess = () => {
      window.location.replace("/themes");
    }

    const checkThemeAdmin = () => {
      try{
          const ldapgrp = JSON.parse(user["custom:ldapgrp"]);
          if (ldapgrp && ldapgrp.includes("theme-admin")) {
            return true;
          }
          return false;
      } catch(e){
          Logger.sessionError(`unable to parse user token ${e}`);
          return false;
      }
    }


    const columnDefs = [
        {
          colId: "checkboxCol",
          checkboxSelection: (params)=>{
            return params.data.reviewStatus!=="pending"
          },
          headerComponent: null,
          width:30,
          cellStyle: {
            cursor: "default"
          },
          hide: !userPermissions[IS_THEME_ADMIN],
          alwaysHidden: !checkThemeAdmin(),
          hideWhenResetToDefault: !checkThemeAdmin(),
        },
        {
            colId: 'themeName',
            headerName: 'Theme name',
            field: 'themeName',
            sortable: true,
            filter: "agTextColumnFilter",
            // cellRenderer: 'ThemeNameCellWithQuote',
            filterParams: {
              filterOptions: ["contains"],
              textCustomComparator: comaSeparatedFilterComparator
            },
        },
        {
            colId: 'productServices',
            headerName: 'Product/Service',
            field: 'productServices',
            sortable: true,
            filter: udcMultiSelectWithSearchFilter,
        },
        {
            colId: 'themeStatus',
            headerName: 'Status',
            field: 'themeStatus',
            sortable: true,
            filter: udcMultiSelectWithSearchFilter,
        },
        {
            colId: 'ThemeblockedRevenue',
            headerName: 'Blocked Revenue',
            field: 'blockedRevenue',
            sortable: true,
            cellRenderer: CurrencyCellRenderer,
            cellRendererParams: {
                getFormattedValue: (value, params) =>
                    value === null || value === undefined
                        ? "EMPTY_CELL_VALUE"
                        : new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: currency,
                        }).format(value),
            },
            filter: "agNumberColumnFilter",
        },
        {
            colId: 'themePriority',
            headerName: 'Priority',
            field: 'themePriority',
            sortable: true,
            filter: udcMultiSelectWithSearchFilter,
        },
        {
          colId: 'additionalContext',
          headerName: 'Theme description',
          sortable: false,
          field: 'additionalContext',
        },
        {
            colId: "submittedByName",
            field: "submittedByName",
            sortable: true,
            headerName: "Created by",
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains"],
                textCustomComparator: comaSeparatedFilterComparator,
            },
        },
        {
            colId: 'tag',
            headerName: 'Tag',
            hideWhenResetToDefault: true,
            hide: true,
            sortable: false,
            field: 'tag',
        },
        {
            colId: "createdTimestamp",
            headerName: 'Created time stamp',
            field: 'createdTimestamp',
            valueFormatter:(params) => formatDateTime(params.data.createdTimestamp),
            sortable: true,
            filter: udcDateRangeFilter,
            sort: "desc",
            filterValueGetter: (params) => {
                return  formatDateTime(params.data.createdTimestamp);
            },
            filterParams: {
                comparator: dateRangeFilterComparator,
            },
        },
        {
            colId: "lastUpdateTimestamp",
            headerName: 'Last modified',
            hideWhenResetToDefault: true,
            hide: true,
            filterParams: {
                comparator: getDateTimeCellComparator,
            },
            valueFormatter:(params) => formatDateTime(params.data.lastUpdateTimestamp),
            valueGetter: (params) => Number(params.data.lastUpdateTimestamp),
            filterValueGetter: (params) => {
                return  Number(params.data.lastUpdateTimestamp)*1000;
            },
            sortable: true,
            filter: "agDateColumnFilter",
        },
        {
            colId: "lastModifiedByName",
            field: "lastModifiedByName",
            sortable: true,
            headerName: "Modified by",
            hideWhenResetToDefault: true,
            hide: true,
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains"],
                textCustomComparator: comaSeparatedFilterComparator,
            },
        },
        {
            colId: "source",
            headerName: 'Source',
            field: 'source',
            valueGetter: params => {
              return params.data.source === 'dataScience' ? 'Sales AI':'ACF portal'
            },
            minWidth: 175,
            hide: false,
            filter: udcMultiSelectWithSearchFilter,
        },
        {
            colId: "link",
            headerName: 'Sharable link',
            field: 'link',
            minWidth: 175,
            hideWhenResetToDefault: true,
            cellRenderer: 'templateLinkRenderer',
            hide: true,
        },
        {
            colId: 'feedbackCount',
            headerName: 'Feedback count',
            valueGetter:  (params) => !Number.isNaN(parseInt(params.data.feedbackCount))? parseInt(params.data.feedbackCount):0,
            sortable: true,
            filter: "agNumberColumnFilter",
        },
        {
            colId: "id",
            field: "id",
            headerName: "Theme Id",
            sortable: true,
            lockVisible: true,
            hide: true,
            hideWhenResetToDefault: true,
            filter: "agTextColumnFilter",
        },
        ];

    const headerElement = (
        <>
            <FilterBar filterGroups={filterGroups} />
            <HeaderPanelContainer>
                <PanelGroup align="start">
                    { userPermissions[IS_THEME_ADMIN] && <BulkActionSelector
                        labels={
                            {
                                selectorText: "Action",
                                // dialogTitle: "Row Action",
                                // getRowNameDisplayText: ()=>"",
                                saveChanges: "Proceed",
                                getRowsSelectedDisplayText: (count) => `${count} Row selected`
                            }
                        }
                        bulkActionDefs={bulkActionDefs}
                    />}
                </PanelGroup>
                <PanelGroup align="end">
                    <DateRangeFilter
                        filterColId="createdTimestamp"
                        customPeriodId="CUSTOM"
                        secondaryViewElementId="portal"
                        zone="America/Toronto"
                    />
                    <AddFilterSelector filterGroups={filterGroups} />
                    <ColumnManager columnExtendedDefs={columnDefs}/>
                </PanelGroup>
            </HeaderPanelContainer>
        </>
    )
    const processedThemeList = (themeList) => {
        const filteredthemeList = themeList.filter(theme => !theme.fields.isDeleted ||  theme.fields.isDeleted!=="1") 
        return filteredthemeList.map((theme) => {
            let link = getEnvUrl(theme.fields.id, "themes");
            return Object.assign({}, {...theme.fields, link: link})
        });
    }
    const fetchThemes = async (api) => {
      try {
        const initialThemeResponse = await getThemes(0, TABLE_ROWS_COUNT);
        api.setRowData(processedThemeList(initialThemeResponse.data.results));
        setTableData(processedThemeList(initialThemeResponse.data.results));
        let requestsMade = TABLE_ROWS_COUNT;
        let bucketSize = TABLE_ROWS_COUNT;
        let index = 1;
        const ceilingBucketSize = 2000;
        while (requestsMade < initialThemeResponse.data.totalItemsCount) {
          index++;
          if (bucketSize < ceilingBucketSize) {
            bucketSize = TABLE_ROWS_COUNT * index;
          }
          const themeResponse = await getThemes(requestsMade, bucketSize)
            // const { feedbacks } = this.state;
            const {
              data: { results: moreTableData },
            } = themeResponse;

            const freshTableData = processedThemeList(moreTableData);
            setTableData(tableData.concat(...freshTableData));
            if (api) {
              api.applyTransaction({
                add: freshTableData,
              });
            }
          requestsMade += bucketSize;
        }
      } catch (error) {
        Logger.sessionError(
          `ThemeTablePage componentDidMount useEffect 
          ${error}`,
          {
            browserUrl: window.location.href,
          }
        );
        setIsError(true);
      }
    }
    const onGridReady = async (event) => {
        const { api } = event;
        setGridApi(api);
        // 1. For client side row model, you need to explicitly decide when to show loading overlay
        api.showLoadingOverlay();
        await fetchThemes(api);
        if(localStorage.getItem("themesFilterData")) {
          Logger.sessionInfo(`Filter applied on Themes Page ${localStorage.getItem("themesFilterData")}`, {
            emfLog: createEMFObject("LoadSavedFilter", 1, "Count", {FilterAction:"ThemesPage"}),
          });
          setInitialFilterModel(event.api, JSON.parse(localStorage.getItem("themesFilterData")));
        }
        if(localStorage.getItem("themesColumnData")) {
            setInitialColumnState({columnApi: event.columnApi, initialColumnState: jsonParseData(localStorage.getItem("themesColumnData"))});
            Logger.sessionInfo("intial columns state applied on themes Page", {
                emfLog: createEMFObject("LoadSavedColumn", 1, "Count", {FilterAction:"FeedbackTable"}),
            });
        }
    }
    const createModalOpen = () => {
        history.push('/themes/create');
    }
    if (isLoading || isError) {
      return (
        <Row alignmentHorizontal="center" spacingInset="400">
          {isLoading && <Loader />}
          {isError && (
            <Alert type="error">Something went wrong. Please try again.</Alert>
          )}
        </Row>
      );
    }
    return (
        <Column spacingInset="400">
            <Text type="h2">Feedback themes</Text>

            <Row >
                <Text type="span">This functionality allows admins to create & edit feedback themes. To download the list of all feedback themes below, click ‘export’ on the bottom left.</Text>
                {userPermissions[IS_THEME_ADMIN] && <Button primary={true} onClick={() => createModalOpen()}>Create feedback theme</Button>}
            </Row>
            <Table
                onGridReady={onGridReady}
                frameworkComponents= {{
                  'ThemeNameCellWithQuote' : ThemeNameCellWithQuote,
                  'templateLinkRenderer' : TemplateLinkRenderer
                }}
                rowStyle = {{ background: 'black' }}
                onFilterChanged={(event) => {
                  const filterModel = event.api.getFilterModel();
                  localStorage.setItem("themesFilterData" ,JSON.stringify(filterModel));
                  // console.log(Object.keys(filterModel).join(','));
                  
                  Logger.sessionInfo("Filter applied on Themes Page", {
                    emfLog: createEMFObject("FilterApplied", 1, "Count", {FilterAction:"ThemesPage"}),
                  });
                  for(const param in filterModel){
                    console.log(param);
                    Logger.sessionInfo("Filter applied on Themes Page", {
                      emfLog: createEMFObject("FilterApplied", 1, "Count", {FilterAction:"ThemesPage", filterModel:param}),
                    });
                  }
                  
                }}
                gridOptions={{
                    columnDefs,
                    // onRowClicked: (params) => {
                    //   console.log(params.node.data.id);
                    //   history.push("/themes/" + params.node.data.id);
                    // },
                    onCellClicked: (params) => {
                      if (params.column.colId === "checkboxCol" || params.column.colId === "link") {
                        return;
                      } else {
                        const id = encodeURIComponent(params.node.data.id);
                        history.push("/themes/" + id);
                      }
    
                    },
                    rowData: tableData,
                    getRowStyle: params => {
                      return {cursor: "pointer"}
                    },
                    pagination: true,
                    getRowId: (rowData) => {
                        return rowData.data.id
                    },
                    rowSelection: 'single',
                    isRowSelectable: rowNode => true,
                    enableCellTextSelection: true,
                    rowHeight: 60,
                    getRowHeight: undefined,
                    defaultColDef: {
                      wrapText: true,
                      autoHeight: true,
                    },
                }}
                tableFooter={footerElement}
                onColumnPresetChanged={(event) => {
                    Logger.sessionInfo("Column Preset applied on themes Page", {
                        emfLog: createEMFObject("ColumnPresetApplied", 1, "Count", {ColumnPresetAction:"FeedbackTable"}),
                    });
                    const columnState = event.columnApi.getColumnState();
                    localStorage.setItem("themesColumnData" ,JSON.stringify(columnState))
                }}
                headerPanel={headerElement}
            />
        </Column>
    )
}

export default FeedbackThemes;