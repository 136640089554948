import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import {
    Text,
    BreadcrumbGroup,
    Breadcrumb,
    TextButton,
    Dropdown,
    DropdownItem,
    Link,
    Button,
    Icon,
    Alert
} from "@amzn/storm-ui";
import { getThemebyId, getFeedbacks } from "../../util/apiUtils";
import ObjectRow from "../../components/object-row/ObjectRow";
import Column from "../../components/column";
import { useParams } from 'react-router-dom';
import AssociatedFeedbacks from "./AssociatedFeedbacks";
import Row from "../../components/row";
import { THEME_STATUS, THEME_PRIORITY, IS_THEME_ADMIN } from "../../util/constants";
import { updateTheme } from "../../util/apiUtils";
import Logger, {logPageMetric} from "../../util/logger";
import Loader from "../../components/Loader";
import { getPhoneToolLink } from "../../util/helperFunctions";
import { createEMFObject , getEnvUrl } from "../../util/util";
import FeedbackThemeModalWrapper from "./FeedbackThemeModalWrapper";
import { TABLE_ROWS_COUNT } from "../../util/constants";
import { Page } from "../../typings/enum";
import {copy} from "@amzn/storm-ui-icons";
import { useStore } from "../../store";


const FeedbackThemeDetails = (props) => {
    const history = useHistory();
    const [themeInfo, setThemeInfo] = useState();
    const [childrenFeedbacks, setChildrenFeedbacks] = useState([]);
    const [ childFeedbackLoading, setChildFeedbackLoading ] = useState(true);
    const [ selectedStatus, setSelectedStatus ] = useState("");
    const [ selectedPriority, setSelectedPriority ] = useState("");
    const [ openEditModel , setOpenEditModel] = useState(false);
    const [ updatingStatus, setUpdatingStatus ] = useState(false);
    const [ updatingPriority, setUpdatingPriority ] = useState(false);
    const [ themeEdited , setThemeEdited] = useState(false);
    const [ statusPriorityUpdated, setStatusPriorityUpdated] = useState(false);
    const [ disableEdit, setDisableEdit ] = useState(false);
    const [ showBanner, setShowBanner ] = useState(false);
    const [ showWaitText, setShowWaitText ] = useState(false);

    const {id} = useParams();

    const { userPermissions } = useStore();


    useEffect(() => {
        async function fetchData() {
            if(id){
                //fetch theme details
                const themeDetails = await getThemebyId(id);
                setThemeInfo(themeDetails?.data?.theme?.fields);
                const shouldDisableEdit = themeDetails?.data?.theme?.fields?.reviewStatus === "pending" || false;
                setDisableEdit(shouldDisableEdit);
                setShowBanner(shouldDisableEdit);
                if(shouldDisableEdit){
                    setShowWaitText(false);
                }
    
                // fetch child feedback details
                const childfeedbacks = await getFeedbackFilterOnThemeId(id);
                setChildrenFeedbacks(childfeedbacks?.data?.feedbackIdList || []);
                setChildFeedbackLoading(false);
            }
            logPageMetric(Page.ThemeDetailPage);
        }
        displayBanner();
        fetchData();
        Logger.sessionInfo("Theme details Visited", {
            emfLog: createEMFObject("PageVisits", 1, "Count", {PageVisits:"FeedbackThemeDetailsVisits"}),
          });
        // eslint-disable-next-line
    }, []);

    // eslint-disable-next-line
    useEffect(async () => {
        if ((themeEdited && id) || (statusPriorityUpdated && id)) {
            setUpdatingStatus(true);
            const themeDetails = await getThemebyId(id);
            const shouldDisableEdit = themeDetails?.data?.theme?.fields?.reviewStatus === "pending" || false;
            setThemeInfo(themeDetails?.data?.theme?.fields);
            setDisableEdit(shouldDisableEdit)
            setShowBanner(shouldDisableEdit);
            if(shouldDisableEdit){
                setShowWaitText(false);
            }

            setUpdatingStatus(false);
        }
        setThemeEdited(false);
        setStatusPriorityUpdated(false);
        displayBanner();
        // eslint-disable-next-line
    },[themeEdited, statusPriorityUpdated]);

    const displayBanner = () => {
        if(sessionStorage != null) {
            const isMappingupdated = sessionStorage.getItem("isMappingupdated");
            const isMappingApprovalCaseCreated = sessionStorage.getItem("isMappingApprovalCaseCreated") 
            if(isMappingApprovalCaseCreated === "true") {
                setDisableEdit(true);
                setShowBanner(true);
            }else if(isMappingupdated) {
                setShowWaitText(true);
            }
            sessionStorage.removeItem("isMappingupdated");
            sessionStorage.removeItem("isMappingApprovalCaseCreated");
        }
    }


    const getFeedbackFilterOnThemeId = async (id) => {
        id = decodeURIComponent(id);
        getFeedbacks( `parentTheme:${id}`, 0, TABLE_ROWS_COUNT)
        .then((response) => {
            setChildrenFeedbacks(response.data.results);
            let requestsMade = TABLE_ROWS_COUNT;
            let bucketSize = TABLE_ROWS_COUNT;
            let index = 1;
            const ceilingBucketSize = 2000;
            while (requestsMade < response.data.totalItemsCount) {
              index++;
              if (bucketSize < ceilingBucketSize) {
                bucketSize = TABLE_ROWS_COUNT * index;
              }
                getFeedbacks(`parentTheme:${id}`,requestsMade, bucketSize).then((response) => {
                const {
                    data: {results: moreFeedbacks},
                } = response;
                setChildrenFeedbacks(childrenFeedbacks => childrenFeedbacks.concat(...moreFeedbacks));
                }).catch((error) => {
                Logger.sessionError(
                    `FeedbackThemeDetailPage getFeedback api paginated call failure from
                     ${error}`,
                    {
                        browserUrl: window.location.href,
                    }
                );
                });
              requestsMade += bucketSize;
            }
          })
          .catch((error) => {
            Logger.sessionError(
                `FeedbackThemeDetailPage FeedbackTable useEffect,
                 ${error}`,
                {
                  browserUrl: window.location.href,
                }
            );
          });
    }

    const updateThemeStatus =  async (val) => {
        // const { id } = themeInfo
        setSelectedStatus(val);
        setUpdatingStatus(true);
        const updateThemeObj = {
            themeId: id,
            input: {
                fields: {
                    ...themeInfo,
                    themeStatus: val
                }
            }
        }
        await updateTheme(updateThemeObj, id, "?updatestatus=true");
        Logger.sessionInfo(`updated themeStatus successfully ${id}`);

        setUpdatingStatus(false);
        setStatusPriorityUpdated(true);
    }

    const createModalOpen = () => {
        setOpenEditModel(true);
    }
    const copyUrlCTAHandler = ()  => {
      const url = getEnvUrl(encodeURIComponent(id), "themes");
      window.navigator.clipboard.writeText(url);
      Logger.sessionInfo(`Copy url cta clicked`, {
        emfLog: createEMFObject(`CopyUrlThemeClick`, 1, "Count"),
    });
   }

    const updateThemePriority =  async (val) => {
        // const { id } = themeInfo
        setSelectedPriority(val);
        setUpdatingPriority(true);
        const updateThemeObj = {
            themeId: id,
            input: {
                fields: {
                    ...themeInfo,
                    themePriority: val
                }
            }
        }
        await updateTheme(updateThemeObj, id);
        Logger.sessionInfo(`updated themePriority successfully ${id}`);

        setUpdatingPriority(false);
        setStatusPriorityUpdated(true);
    }


    
    // const refreshChildFeedbacks = async () => {
    //     console.log("refreshing")
    //     setChildFeedbackLoading(true);
    //     const childfeedbacks = await getFeedbackFilterOnThemeId(id);
    //     setChildrenFeedbacks(childfeedbacks?.data?.feedbackIdList || []);
    //     setChildFeedbackLoading(false);
    // }

    const renderStatusDropdown = () => {
        return (
            <Row>
                <Dropdown
                    disabled={childrenFeedbacks?.length===0 || disableEdit || !userPermissions[IS_THEME_ADMIN]}
                    onChange={(val) => updateThemeStatus(val)}
                    selectedValue={selectedStatus}
                    onOverrideLabel={(dropdownItem, value) => {
                        return themeInfo?.themeStatus || 'Update status';
                    }}
                    transparentButton={true}
                >
                    {THEME_STATUS.map((themeStatus, key) => {
                        return <DropdownItem key={key} value={themeStatus} >{themeStatus}</DropdownItem>
                    })}
                </Dropdown>
            </Row>
        )
    }

    const renderPriorityDropdown = () => {
        return (
            <Row>
                <Dropdown
                    disabled={childrenFeedbacks?.length===0 || disableEdit || !userPermissions[IS_THEME_ADMIN]}
                    onChange={(val) => updateThemePriority(val)}
                    selectedValue={selectedPriority}
                    onOverrideLabel={(dropdownItem, value) => {
                        return themeInfo?.themePriority || "Priority not set";
                    }}
                    transparentButton={true}
                >
                    {THEME_PRIORITY.map((themePriority, key) => {
                        return <DropdownItem key={key} value={themePriority} >{themePriority}</DropdownItem>
                    })}
                </Dropdown>
            </Row>
        )
    }

    if(updatingStatus || updatingPriority){
        return (
            <div className="page">
                <Loader />
            </div>
            )
    }
    if(openEditModel){
        return (
            <FeedbackThemeModalWrapper
                closeEditModal = {setOpenEditModel}
                isThemeEdited = {setThemeEdited}
                {...props}
                mode='Edit'
                source = 'themesDetailsPage'
                selectedRowItems={[{
                    rowData: {...themeInfo, id}
                }]}
            />
        )
    }

    return (
        <>
        <Column spacingInset="400">
            <BreadcrumbGroup>
                <Breadcrumb><TextButton onClick={() => history.push('/themes')}>Themes</TextButton></Breadcrumb>
                <Breadcrumb label="Theme details" />
            </BreadcrumbGroup>
            <Alert type="info" header="Your changes might take a few minutes to reflect" isOpen={showWaitText}/>
            <Alert type="warning" header="Pending Theme Approval" withCloseButton={true} isOpen={(showBanner && disableEdit)} onClose={()=>setShowBanner(false)}>
                <Text>Edits made to this theme are under review. You cannot make additional changes until edits are reviewed and approved.</Text>
            </Alert>
            <Row width="100%" alignmentHorizontal="justify">
                <Text type="h2">Feedback theme</Text>
                <Column alignmentHorizontal="end">
                    <Row alignmentHorizontal="justify">
                        {userPermissions[IS_THEME_ADMIN] && <Button disabled={disableEdit} primary={true} onClick={()=>createModalOpen()}> Edit </Button>}
                        <Button onClick={()=>copyUrlCTAHandler()}><Icon type={copy} /> Copy url </Button>
                    </Row>
                </Column>
            </Row>

            <ObjectRow
                    columnData={[
                        {
                            dataKey: <Text type="span">Product name</Text>,
                            dataValue: <Text type="span">{themeInfo?.productServices}</Text>
                        },
                    ]}
                />
            <ObjectRow
                    columnData={[
                        {
                            dataKey: <Text type="span">Theme name</Text>,
                            dataValue: <Text type="span">{themeInfo?.themeName}</Text>
                        },
                    ]}
                />
            <ObjectRow
                    columnData={[
                        {
                            dataKey: <Text type="span">Description</Text>,
                            dataValue: <Text type="span">{themeInfo?.additionalContext}</Text>
                        },
                    ]}
                />
            <ObjectRow
                    columnData={[
                        {
                            dataKey: <Text type="span">Blocked revenue</Text>,
                            dataValue: <Text type="span">{themeInfo?.blockedRevenue}</Text>
                        },
                    ]}
                />
            <ObjectRow
                    columnData={[
                        {
                            dataKey: <Text type="span">Attached feedback count</Text>,
                            dataValue: <Text type="span">{themeInfo?.feedbackCount || 0}</Text>
                        },
                    ]}
                />
            <ObjectRow
                    columnData={[
                        {
                            dataKey: <Text type="span">Priority</Text>,
                            dataValue: renderPriorityDropdown()
                        },
                    ]}
                />
            <ObjectRow
                    columnData={[
                        {
                            dataKey: <Text type="span">Status</Text>,
                            dataValue: renderStatusDropdown()
                        },
                    ]}
                />
            <ObjectRow
                    columnData={[
                        {
                            dataKey: <Text type="span">Created by</Text>,
                            dataValue: <Link className="object-details__value" href={getPhoneToolLink(themeInfo?.submittedByEmail)} target="_blank">{themeInfo?.submittedByName}</Link>
                        },
                    ]}
                />
            <ObjectRow
                    columnData={[
                        {
                            dataKey: <Text type="span">Last editted by</Text>,
                            dataValue: themeInfo?.lastModifiedByEmail?<Link className="object-details__value" href={getPhoneToolLink(themeInfo?.lastModifiedByEmail)} target="_blank">{themeInfo?.lastModifiedByName}</Link>:'-'
                        },
                    ]}
                />
            {/* <ObjectRow
                    columnData={[
                        {
                            dataKey: <Text type="span">Associated Feedbacks</Text>,
                            dataValue: <AssociatedFeedbacks key={childrenFeedbacks?.length} childFeedbackLoading={childFeedbackLoading} associatedFeedbacks={childrenFeedbacks} />
                        }
                    ]}
                /> */}
            <Text type="h3">list # of feedbacks associated</Text>
            <AssociatedFeedbacks key={childrenFeedbacks?.length} childFeedbackLoading={childFeedbackLoading} associatedFeedbacks={childrenFeedbacks} />
        </Column>
        </>
    )
}

export default FeedbackThemeDetails





