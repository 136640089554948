import React, { useState, useEffect } from "react";
import { Text, Button, Icon, Alert, BreadcrumbGroup, Breadcrumb, TextButton } from "@amzn/storm-ui";
import { useHistory } from "react-router";
import Row from "../../../components/row";
import { Table } from "../../../components/unified-data-table/unified-data-table";
import {
  FooterPanelContainer,
  PanelGroup,
  Pagination,
  ClientSideExportButton,
  SelectAllCheckbox,
  HeaderPanelContainer,
  BulkActionSelector,
} from "@amzn/unified-data-table-components/pack";
import { download } from "@amzn/storm-ui-icons";

import CustomBulkActionComponent from "../custom-bulk-action-component/CustomBulkActionDialog";
import { overrideZIndex, createEMFObject, getEnvUrl } from '../../../util/util';
import { ThemeProvider } from 'styled-components';
import CreateTemplateForm from "./CreateTemplateForm";
import apiClient from '../../../util/apiClient';
import Logger, {logPageMetric} from "../../../util/logger";
import Column from "../../../components/column";
import { getTemplates } from "../../../util/templateUtils";
import endpoints from "../../../urlConfig";
import TemplateLinkRenderer from "./TemplateLinkRenderer";
import { PAGINATION_CONSTANT } from "../../../util/constants";
import { Page } from "../../../typings/enum";



const AdminFeedbackTemplates = (props) => {
  const history = useHistory();
  const [isError, setIsError] = useState(false);
  const [gridApi, setGridApi] = useState();
  let editLabelsFeedbackType = "";
  let editLabelsValue = "";
  let editedNotes = "";
  let editTemplateName = "";
  let editSelectedCategory = "";
  let editSelectedProductServices="";
  let editAdditionalContext = "";
  const {template, deleteTemplate} = endpoints;

  useEffect(()=>{
    Logger.sessionInfo("Templates Visited", {
      emfLog: createEMFObject("PageVisits", 1, "Count", {PageVisits:"AdminTemplatesVisits"}),
    });
    logPageMetric(Page.FeedbackTemplate);
  },[])

  const triggerSetEditLabelsObj = (editObj) => {
    console.log(editObj);
    editLabelsFeedbackType = editObj.selectedFeedbackType
    editLabelsValue = editObj.labelsValue;
    editedNotes = editObj.notes;
    editTemplateName= editObj.templateName;
    editSelectedCategory= editObj.selectedCategory;
    editSelectedProductServices= editObj.selectedProductServices;
    editAdditionalContext= editObj.additionalContext;
  }

  const getedittedFields = () => {
    return { editLabelsFeedbackType, editLabelsValue, editedNotes, editTemplateName, editSelectedCategory, editSelectedProductServices, editAdditionalContext};
  }

  const onGridReady = async (event) => {
    const { api } = event;
    setGridApi(api);
    // 1. For client side row model, you need to explicitly decide when to show loading overlay
    api.showLoadingOverlay();

    // 2. Fetch data source
    const templateList = await getTemplates();
    const processedtemplateList = processTemplateResponse(templateList);
    api.setRowData(processedtemplateList);
  }
  const processTemplateResponse = (templates) => {
    const filteredtemplates = templates.filter(template => !template.fields.isDeleted ||  template.fields.isDeleted!=="1")
    return filteredtemplates.map((template, key)=>{
        return Object.assign(template.fields, {
          link: getEnvUrl(template.id, "template")
        })
    })
  }

  useEffect(() => {
    const { editLabelsFeedbackType, editLabelsValue } = getedittedFields();
    console.log(editLabelsFeedbackType);
    console.log(editLabelsValue);
  // eslint-disable-next-line
  }, [editLabelsFeedbackType]);

  const columnDefs = [
    {
      colId: "checkboxCol",
      checkboxSelection: true,
      headerComponent: null,
      width: 30,
    },
    {
        colId: 'templateName',
        headerName: 'Template name',
        field: 'name',
    },
    {
        colId: 'feedbackType',
        headerName: 'Feedback type',
        field: 'feedbackType',
    },
    {
        colId: 'feedbackCategory',
        headerName: 'Feedback category',
        field: 'feedbackCategory',
    },
    {
        colId: 'productServices',
        headerName: 'Product/Service',
        field: 'productServices',
    },
    {
        colId: 'feedbackLabels',
        headerName: 'Feedback labels',
        field: 'feedbackLabel',
    },
    {
        colId: "link",
        headerName: 'Sharable link',
        field: 'link',
        minWidth: 175,
        cellRenderer: 'templateLinkRenderer',
        hide: true
    },
    {
        colId: "createdTimeStamp",
        headerName: 'Created time stamp',
        field: 'createdTimeStamp',
        hide: true
    },
    ];

  const bulkActionDefs = [
    {
      bulkActionComponent: CustomBulkActionComponent,
      bulkActionComponentProps: {
        action: "delete"
      },
      bulkActionId: 'deleteRowAction',
      label: 'Delete',
      getDisabledStatus: (selectedRowItem) => ({
        disabled: false,
      }),
      getSelectedRowLabel: (rowItem) => "Delete Template",
      onApply: (params) => {
            let templateName = params.selectedRowItems[0]?.rowData?.name
            let id = params.selectedRowItems[0]?.rowData?.id
            apiClient.delete(`${deleteTemplate}/${id}`)
                .then((res) => onTemplateBulkOperationSuccess("Delete"))
                .catch(err => Logger.sessionError(`Failed to delete template: ${templateName}, error: ${err}`),{
                    emfLog: createEMFObject(`DeleteFeedbaclkTemplateFailed`, 1, "Count", {FeedbaclkTemplate:"DeleteFeedbaclkTemplateFailed"}),
                })
        },
    },
    {
      bulkActionComponent: (props) => <CreateTemplateForm {...props} mode="Edit" triggerSetEditLabelsObj={triggerSetEditLabelsObj} />,
      bulkActionComponentProps: {
        mode: "Edit"
      },
      bulkActionId: 'editRowAction',
      label: 'Edit',
      getDisabledStatus: (selectedRowItem) => ({
        disabled: false,
      }),
      getSelectedRowLabel: (rowItem) => "Edit Feedback Templates",
      onApply: (params) => {
        let id = params.selectedRowItems[0]?.rowData?.id;
        let createdTimestamp = params.selectedRowItems[0]?.rowData?.createdTimestamp
        let submitObj = {};
        let isNameChanges = editTemplateName !== params.selectedRowItems[0]?.rowData?.name? "true":"false"
        submitObj = {
            templateId: id,
            "input": {
                id: id,
                fields: {
                    templateId: id,
                    name: editTemplateName,
                    feedbackType: editLabelsFeedbackType,
                    feedbackCategory: editSelectedCategory,
                    productServices: editSelectedProductServices,
                    feedbackLabel: editLabelsValue,
                    additionalContext: editAdditionalContext,
                    createdTimestamp: createdTimestamp,
                    isNameChanges
                }
            }
        }
        apiClient.put(`${template}/${id}`, submitObj)
          .then((res)=>onTemplateBulkOperationSuccess("Edit"))
          .catch(err => {
            Logger.sessionError(`Failed to edit template, error: ${err}`,{
                emfLog: createEMFObject(`EditFeedbackTemplateFailed`, 1, "Count", {FeedbackTemplate: "EditFeedbackTemplateFailed"}),
            });
            setIsError(true);
          })

      },
    },
  ]

  const onTemplateBulkOperationSuccess = (operation) => {
      Logger.sessionInfo(`${operation} Template successfull:`, {
        emfLog: createEMFObject(`${operation}FeedbackLabelsCounts`, 1, "Count"),
    });
    history.push('/admin');
  }

  const handleExport = () => {
    Logger.sessionInfo("Exported Template table", {
      emfLog: createEMFObject("ExportClick", 1, "Count", {ExportAction:"TemplateExported"}),
    });
    gridApi.exportDataAsCsv({
      allColumns: true,
      onlySelected: false,
      columnKeys: [
        "templateName",
        "feedbackType",
        "feedbackCategory",
        "productServices",
        "feedbackLabels",
      ],
      fileName: "exportTemplates.csv",
    });
  }

  const footerElement = (
    <FooterPanelContainer>
      <PanelGroup align="start">
        <ClientSideExportButton 
          isDisabled={(rows, pageSize)=>(
            rows === 0 ? true: false
            )} 
          onExport={handleExport} 
          label={<Icon type={download} />}>
        </ClientSideExportButton>
      </PanelGroup>
      <PanelGroup align="end">
        <Pagination pageSizes={PAGINATION_CONSTANT} />
      </PanelGroup>
    </FooterPanelContainer>
  );
  const headerPanelElement = (
    <ThemeProvider theme={overrideZIndex}>
      <HeaderPanelContainer>
        <PanelGroup
          align="start"
        >
          <BulkActionSelector
            labels={
              {
                selectorText: "Action",
                saveChanges: "Proceed",
                getRowsSelectedDisplayText: (count) => `${count} Row Selected` 
              }
            }
            bulkActionDefs={bulkActionDefs}
          />
        </PanelGroup>
      </HeaderPanelContainer>
    </ThemeProvider>
  );
  if(isError){
    return(
      <Alert type="error">Something went wrong. Please try again.</Alert>
    )
  }

  return (
    <Column spacingInset="400">
      <BreadcrumbGroup>
        <Breadcrumb><TextButton onClick={() => history.push('/admin')}>Admin</TextButton></Breadcrumb>
        <Breadcrumb label="templates" />
      </BreadcrumbGroup>
      <Text type="h2">Feedback templates</Text>
      
      <Row >
        <Text type="span">This functionality allows admins to create & edit feedback templates. To download the list of all feedback templates below, click ‘export’ on the bottom left.</Text>
        <Button primary={true} onClick={() => history.push('/admin/template/create')}>Create feedback template</Button>
      </Row>
      <Table
        onGridReady={onGridReady}
        frameworkComponents= {{
          'templateLinkRenderer' : TemplateLinkRenderer
        }}
        gridOptions={{
          columnDefs,
          pagination: true,
          getRowId: (rowData) => {
            console.log(rowData);
            return rowData.data.id
          },
          frameworkComponents: {
            SelectAllCheckbox,
          },
          rowSelection: 'single',
          isRowSelectable: rowNode => true,
          enableCellTextSelection:true
        }}
        tableFooter={footerElement}
        headerPanel={headerPanelElement}
      />
      </Column>
  )
}

export default AdminFeedbackTemplates;