import * as React from "react";
import {withRouter} from "react-router-dom";

import "./FeedbackDetailPage.scss";
import "../../styles/common.scss";
import ObjectRow from "../../components/object-row/ObjectRow";
import apiClient from "../../util/apiClient";
import Logger from "../../util/logger";
import Row from "../../components/row";
import {Alert, Text, Link, Button, TextButton, Icon} from '@amzn/storm-ui';
import * as helperFunction from "../../util/helperFunctions"
import {
    createEMFObject,
    formatCurrency,
    formatDate,
    formatString,
    downloadInChunk,
    getEnvUrl
} from "../../util/util";
import Column from "../../components/column";
import Loader from "../../components/Loader";
import { copy, angleLeft, angleRight } from "@amzn/storm-ui-icons";
import UserContext from "../../context/userContext";
import { getFeedback } from "../../util/apiUtils";
import AttachToThemeDialogModal from "../feedback-themes/AttachToThemeDialogModal";
import { removeMappingFlow, createMappingFlow } from "../../util/themeUtils";
import { IS_THEME_ADMIN } from "../../util/constants";

const EMOJI_VERY_SATISFIED = String.fromCodePoint(0x1F604);
const EMOJI_SATISFIED = String.fromCodePoint(0x1F642);
const EMOJI_NEUTRAL = String.fromCodePoint(0x1F610);
const EMOJI_UNSATISFIED = String.fromCodePoint(0x1F641);
const EMOJI_VERY_UNSATISFIED = String.fromCodePoint(0x1F621);

const emojiObject = {
    1: EMOJI_VERY_UNSATISFIED,
    2: EMOJI_UNSATISFIED,
    3: EMOJI_NEUTRAL,
    4: EMOJI_SATISFIED,
    5: EMOJI_VERY_SATISFIED,
    0: EMOJI_NEUTRAL
}

class FeedbackDetailPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            feedback: {},
            isLoading: true,
            isError: false,
            relations: {},
            truncateText: true,
            meta: {},
            openAttachModal: false,
            selectedThemeId:"",
            selectedThemeName:"",
            themeBlockedRevenue:"0",
            feedbackThemeDescription :"",
            selectedThemePriority:"",
            overrideReason: "",
            showBanner: true,
        };
        this.handleToggleTruncate = this.handleToggleTruncate.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.getEmoji = this.getEmoji.bind(this);
        this.copyUrlCTAHandler = this.copyUrlCTAHandler.bind(this);
        this.moveToRoute = this.moveToRoute.bind(this);
        this.makePresignedUrlRequest = this.makePresignedUrlRequest.bind(this);
        this.handleChangeTheme = this.handleChangeTheme.bind(this);
        this.closeAttachThemeModal = this.closeAttachThemeModal.bind(this);
        this.setSelectedThemeDetails = this.setSelectedThemeDetails.bind(this);
        this.handleAttachTheme = this.handleAttachTheme.bind(this);
        this.navigateToPreviousFeedback = this.navigateToPreviousFeedback.bind(this);
        this.navigateToNextFeedback = this.navigateToNextFeedback.bind(this);
    }

    async componentDidUpdate(prevProp) {
        if(this.props?.location?.state){
            const {rowIndex} = this.props?.location?.state
            if(prevProp?.location?.state?.rowIndex !== rowIndex){
                const {id} = this.props.match.params;
                try{
                    const response = await getFeedback(id);
                    this.setState({feedback: response.data?.feedback?.fields});
                    this.setState({relations: response.data?.feedback?.relations});
                    this.setState({meta: response.data?.meta});
                } catch(error){
                    Logger.sessionError(`FeedbackDetailPage componentDidMount ${error}`, {
                        browserUrl: window.location.href,
                    });
                    this.setState({isError: true});
                }
                this.setState({isLoading: false});
            }
        }
    }

    navigateToNextFeedback() {
        const {feedbackArray,rowIndex } = this.props?.location?.state;
        const {location: {pathname}} = window;
        const urlSource = pathname.split('/');

        this.props.history.push(
            {
                pathname: `/${urlSource[1]}/${feedbackArray[rowIndex + 1].id}`,
                state: {
                    feedbackArray,
                    rowIndex: rowIndex + 1,
                },
            });
            this.setState({isLoading:true});
    }

    navigateToPreviousFeedback() {
        const {location:{state:{feedbackArray, rowIndex}}} = this.props;
        const {location: {pathname}} = window;
        const urlSource = pathname.split('/');

        this.props.history.push(
            {
                pathname: `/${urlSource[1]}/${feedbackArray[rowIndex - 1].id}`,
                state: {
                    feedbackArray,
                    rowIndex: rowIndex - 1,
                },
            })
            this.setState({isLoading:true});
}

    handleToggleTruncate() {
        this.setState(prevState => ({
            truncateText: !prevState.truncateText
        }));
    }

    handleEdit() {
        const {id} = this.props.match.params;
        const {history} = this.props;
        history.push("/editfeedback/" + id);
    }

    getEmoji(csatScore) {
        return emojiObject[csatScore] || emojiObject ;
    }

    async componentDidMount() {

        const {id} = this.props.match.params;
        try{
            const response = await getFeedback(id);
            this.setState({feedback: response.data?.feedback?.fields});
            this.setState({relations: response.data?.feedback?.relations});
            this.setState({meta: response.data?.meta});
        } catch(error){
            Logger.sessionError(`FeedbackDetailPage componentDidMount ${error}`, {
                browserUrl: window.location.href,
            });
            this.setState({isError: true});
        }
        this.setState({isLoading: false});

        Logger.sessionInfo("User landed on feedback detail page", {
            emfLog: createEMFObject("ACFFeedbackDetailsPageHits", 1, "Count"),
        });
    }

    copyUrlCTAHandler() {
        const {feedback} = this.state;
        const url = getEnvUrl(feedback.id, "feedback");
        window.navigator.clipboard.writeText(url);
        Logger.sessionInfo(`Copy url cta clicked`, {
            emfLog: createEMFObject(`CopyUrlFeedbackClick`, 1, "Count"),
        });
    }

    moveToRoute(pathname, state={}) {
        const {history} = this.props;
        history.push({
            pathname,
            state:{...state}
        });
    }

    handleChangeTheme() {
        this.setState({openAttachModal:true});
    }

    closeAttachThemeModal() {
        this.setState({openAttachModal:false});
    }

    setSelectedThemeDetails(data, dataType) {
        if(dataType === "themeInfo") {
            this.setState({
                selectedThemeId: data.id,
                selectedThemeName: data?.fields?.themeName,
                themeBlockedRevenue: data?.fields?.blockedRevenue,
                feedbackThemeDescription: data?.fields?.additionalContext,
                selectedThemePriority: data?.fields?.themePriority,
            })
        } else {
            this.setState({
                overrideReason: data
            })
        }
    }

    async handleAttachTheme () {
        const { feedback } = this.state;
        const selectedRowItems = [{
            rowData: {...feedback},
            rowNodeId: feedback.id
        }];
        const { user } = this.context;
        let mappingSubmitArr = [];
        let submitTheme = {};

        try{
            if(feedback.parentTheme){
                [mappingSubmitArr, submitTheme] = removeMappingFlow(selectedRowItems, user)
            }
          createMappingFlow(
              this.state.selectedThemeId,
              this.state.selectedThemeName,
              this.state.themeBlockedRevenue,
              this.state.feedbackThemeDescription,
              this.state.selectedThemePriority,
              selectedRowItems,
              user,
              mappingSubmitArr,
              submitTheme, 
              this.state.overrideReason
          );
          if(sessionStorage !== null) {
            sessionStorage.setItem("isMappingupdated",true);
          }
          Logger.sessionInfo(`Attach to Theme successfull:`, {
              emfLog: createEMFObject(`AttachToThemeCounts`, 1, "Count"),
            });
            this.props.history.push(`/themes/${encodeURIComponent(this.state.selectedThemeId)}`)
        } catch(err) {
          Logger.sessionError(`Error occurred while creating feedback theme mapping ${err}`);
          this.setState({isError: true});
        }
      }

    render() {

        const {isError, isLoading, showBanner} = this.state;
        const { storeProp:{userPermissions} } = this.props;
        const isThemeAdmin = userPermissions[IS_THEME_ADMIN];
        if (isLoading || isError) {
            return <div className="page">
                {isLoading && <Loader/>}
                {isError && <Alert type="error">Something went wrong. Please try again.</Alert>}
            </div>
        }
        let {feedback, relations, meta} = this.state;
        if(!relations) {
            relations = {}
        }
        if(!meta) {
            meta = {}
        }
        const disableEdit = feedback?.reviewStatus === "pending";
        const userEmail = feedback.submittedByEmail;
        const phoneToolLink = helperFunction.getPhoneToolLink(userEmail);
        const modifierEmail = feedback.lastEditedBy;
        const modifierAlias = modifierEmail?helperFunction.getUserAlias(modifierEmail):'-';
        const modifierPhoneToolLink = modifierEmail?helperFunction.getPhoneToolLink(modifierEmail):'';
        const accountLink = helperFunction.getSalesforceAccountLink(feedback.accountId)
        const topUrl = this.context.src;
        const openLink = topUrl==='sf'?'open_in_salesforce':'link_new_tab';
        const feedbackArray = this.props?.location?.state?.feedbackArray;
        const rowIndex = this.props?.location?.state?.rowIndex;
        if(this.state.openAttachModal){
            return (
                <AttachToThemeDialogModal 
                    closeAttachThemeModal={this.closeAttachThemeModal} 
                    setSelectedThemeDetails = {this.setSelectedThemeDetails}
                    selectedRowItems={[{
                        rowData: {...feedback},
                        rowNodeId: feedback.id
                    }]}
                    handleAttachTheme= {this.handleAttachTheme}
                />
            )
        }

        return (
            <>
            
            <div className="container">
                <Column backgroundColor="primary" spacingInset="none">
                    <Column spacingInset="large xlarge">
                        <Row width="100%">
                            <Alert type="warning" header="Pending Feedback Theme Mapping Approval" withCloseButton={true} isOpen={showBanner && disableEdit} onClose={() => this.setState({showBanner: false})}>
                                <Text>Edits made to this feedback are under review. You cannot make additional changes until edits are reviewed and approved.</Text>
                            </Alert>
                        </Row>
                        <Row width="100%" alignmentHorizontal="justify">
                            <Text type="h300">Feedback from {feedback.submittedByName}</Text>
                             <Column alignmentHorizontal="end">
                                <Row widths="90px" alignmentHorizontal="justify">
                                    
                                    <Button disabled={disableEdit} onClick={()=>this.moveToRoute("/editfeedback/" + feedback.id,{isModalOpen:true})}> Edit </Button>
                                    <Button onClick={this.copyUrlCTAHandler}><Icon type={copy} /> Copy url </Button>
                                    <Button primary={true} onClick={()=>this.moveToRoute("/clonefeedback/" + feedback.id,{isModalOpen:true})}> Clone </Button> 
                                </Row>
                            </Column>
                        </Row>
                        <Row spacing="xlarge" alignmentHorizontal="left">
                            <Column spacingInset="500 0">
                                <Text type="b300">{this.getEmoji(feedback.csatScore)} {feedback.csatScore === "0" ? ""  : feedback.sentiment ? feedback.sentiment + " customer experience" : ""}</Text>
                            </Column>
                        </Row>
                        <ObjectRow
                            columnData={[
                                {
                                    dataKey: <Text type="span">Customer quote : </Text>,
                                    dataValue: <Text type="span">{feedback.customerVerbatim}</Text>
                                },
                            ]}
                            divider={false}
                            spacingInset="300"
                        />
                        {feedback.translatedCustomerVerbatim && feedback.translatedCustomerVerbatim.length>0 &&
                        <ObjectRow
                            columnData={[
                                {
                                    dataKey: <Text type="span">Customer quote translated: </Text>,
                                    dataValue: <Text type="span">{feedback.translatedCustomerVerbatim}</Text>
                                },
                            ]}
                            divider={false}
                            spacingInset="300"
                        />
                        }
                        <ObjectRow
                            columnData={[
                                {
                                    dataKey: <Text type="span">Additional context : </Text>,
                                    dataValue: <Text type="span">{feedback.additionalContext}</Text>
                                },
                            ]}
                            divider={false}
                            spacingInset="300"
                        />
                        {feedback.translatedAdditionalContext && feedback.translatedAdditionalContext.length>0 &&
                            <ObjectRow
                            columnData={[
                                {
                                    dataKey: <Text type="span">Additional context translated: </Text>,
                                    dataValue: <Text type="span">{feedback.translatedAdditionalContext}</Text>
                                },
                            ]}
                            divider={false}
                            spacingInset="300"
                        />
                        }
                        <ObjectRow
                            columnData={[
                                {
                                    dataKey: <Text type="span">Why this important : </Text>,
                                    dataValue: <Text type="span">{feedback.whyThisImportant}</Text>
                                },
                            ]}
                            divider={false}
                            spacingInset="300"
                        />
                        {feedback.translatedWhyThisImportant && feedback.translatedWhyThisImportant.length>0 &&
                            <ObjectRow
                                columnData={[
                                    {
                                        dataKey: <Text type="span">Why this important (translated) : </Text>,
                                        dataValue: <Text type="span">{feedback.translatedWhyThisImportant}</Text>
                                    },
                                ]}
                                divider={false}
                                spacingInset="300"
                            />
                        }
                        <ObjectRow
                            columnData={[
                                {
                                    dataKey: <Text type="span">Theme : </Text>,
                                    dataValue: <Text type="span">{feedback.parentThemeName || "No theme tagged"} {(isThemeAdmin) && <TextButton disabled={disableEdit} onClick={this.handleChangeTheme}>{feedback.parentTheme?'Change theme?':'Attach to theme?'}</TextButton>}</Text>
                                    
                                },
                            ]}
                            divider={false}
                            spacingInset="300"
                        />
                        <ObjectRow
                            columnData={[
                                {
                                    dataKey: <Text type="span">Theme mapping source : </Text>,
                                    dataValue: <Text type="span">{feedback?.updateSource==="dataScience"?"Auto mapped": ""} </Text>
                                    
                                },
                            ]}
                            divider={false}
                            spacingInset="300"
                        />
                        {feedback.themeStatus &&
                        <ObjectRow
                            columnData={[
                                {
                                    dataKey: <Text type="span">Status : </Text>,
                                    dataValue: <Text type="span">{feedback.themeStatus}</Text>
                                },
                            ]}
                            divider={false}
                            spacingInset="300"
                        />
                        }
                        <Row spacingInset="none" alignmentHorizontal="left">
                            <div
                                onClick={this.handleToggleTruncate}
                                style={{cursor: "pointer"}}
                            >
                                <Column
                                    alignmentHorizontal="center"
                                    spacing="none"
                                    spacingInset="small"
                                >
                                </Column>
                            </div>
                        </Row>
                    </Column>
                    {feedbackArray &&
                        <Row spacingInset="0 600">

                            <Column alignmentHorizontal="left" width="50%">
                                {rowIndex > 0 && <TextButton onClick={this.navigateToPreviousFeedback}><Icon type={angleLeft} /> Prev</TextButton>}
                            </Column>
                            <Column alignmentHorizontal="right" width="50%">
                                {rowIndex < feedbackArray.length - 1 && <TextButton onClick={this.navigateToNextFeedback}>Next <Icon type={angleRight} /></TextButton>}
                            </Column>
                        </Row>
                    }

                    <Column backgroundColor="#F9FAFA" spacingInset="none">
                        <Column spacingInset="0 600">

                                <Row spacingInset="500 0">
                                    <Text type="h300">Feedback details </Text>
                                </Row>
                                <ObjectRow
                                    columnData={[
                                        {
                                            dataKey: <Text type="h100">{"Submitted"}</Text>,
                                            dataValue: this.createDataRow(formatDate(feedback.createdTimestamp))
                                        },
                                        {
                                            dataKey: <Text type="h100">{"Submitted by"}</Text>,
                                            dataValue: this.createDataRow(feedback.submittedByName, 'link_new_tab', phoneToolLink),
                                        },
                                        {
                                            dataKey: <Text type="h100">{"Last modified by"}</Text>,
                                            dataValue: this.createDataRow(modifierAlias, modifierEmail?'link_new_tab':null, modifierEmail?modifierPhoneToolLink:null),
                                        }
                                    ]}
                                />

                                <ObjectRow
                                    columnData={[
                                        {
                                            dataKey: <Text type="h100">{"Blocked revenue"}</Text>,
                                            dataValue: this.createDataRow(formatCurrency(feedback.revenueImpacted)),
                                        },
                                        {
                                            dataKey: <Text type="h100">{"ID"}</Text>, 
                                            dataValue: this.createDataRow(formatString(feedback.id))
                                        },
                                        {
                                            dataKey: <Text type="h100">{"Account owner"}</Text>,
                                            dataValue: this.createDataRow(formatString(feedback.accountOwnerName)),
                                        },
                                    ]}
                                />

                            <ObjectRow
                                columnData={[
                                    {
                                        dataKey: <Text type="h100">{"Sales team"}</Text>,
                                        dataValue: this.createDataRow(formatString(feedback.salesTeam)),
                                    },
                                    {
                                        dataKey: <Text type="h100">{"Account channel"}</Text>, 
                                        dataValue: this.createDataRow(formatString(feedback.accountChannel))
                                    },
                                    {
                                        dataKey: <Text type="h100">{"Account vertical"}</Text>,
                                        dataValue: this.createDataRow(formatString(feedback.accountVertical)),
                                    }
                                ]}
                            />

                            <ObjectRow
                                columnData={[
                                    {
                                        dataKey: <Text type="h100">{"Account record type"}</Text>,
                                        dataValue: this.createDataRow(formatString(feedback.accountRecordType)),
                                    },
                                    {
                                        dataKey: <Text type="h100">{"Account market"}</Text>, 
                                        dataValue: this.createDataRow(formatString(feedback.accountMarket))
                                    },
                                    {
                                        dataKey: <Text type="h100">{"Account name"}</Text>,
                                        dataValue: this.createDataRow(formatString(feedback.accountName) , openLink, accountLink),
                                    }
                                ]}
                            />
                            <ObjectRow
                                columnData={[
                                    {
                                        dataKey: <Text type="h100">{"Manager alias"}</Text>,
                                        dataValue: this.createDataRow(formatString(feedback.managerName)),
                                    },
                                    {
                                        dataKey: <Text type="h100">{"Feedback type"}</Text>, 
                                        dataValue: this.createDataRow(formatString(feedback.category))
                                    },
                                    {
                                        dataKey: <Text type="h100">{"Feedback category"}</Text>,
                                        dataValue: this.createDataRow(formatString(feedback.subCategory)),
                                    }
                                ]}
                            />
                            <ObjectRow
                                columnData={[
                                    {
                                        dataKey: <Text type="h100">{"Product/Service"}</Text>,
                                        dataValue: this.createDataRow(formatString(feedback.advertisingSubProduct,false)),
                                    },
                                    {
                                        dataKey: <Text type="h100">{"Attachments"}</Text>,
                                        dataValue: <Column>{feedback?.attachments?.split(';').map((attachmentLink, key)=>this.createDataRow(attachmentLink.split('/')[5], 'downLoad', attachmentLink))}</Column>
                                    },
                                    {
                                        dataKey: <Text type="h100">{"Associated Account"}</Text>,
                                        dataValue: this.createDataRow(formatString(feedback.agencyAssociatedAccountName)),
                                    }
                                ]}
                            />
                            <ObjectRow
                                columnData={[
                                    {
                                        dataKey: <Text type="h100">{"Theme Association Date"}</Text>,
                                        dataValue: this.createDataRow(formatDate(feedback.themeAssociationDate)),
                                    },
                                ]}
                            />
                        </Column>


                    </Column>
                </Column>
            </div>
        </>
        );
    }
    makePresignedUrlRequest(attachmentLink) {
        const fileName = attachmentLink.split('/')[5];
        const id = attachmentLink.split('/')[4];
        const urlPath = attachmentLink.split('/').splice(3).join('/')
        apiClient.get(urlPath)
        .then(res=>{
            const {data: {preSignedUrl}} = res;
            fetch(preSignedUrl,{
                method:'Get',
            })
            .then(response=>{
                downloadInChunk(response, fileName);
                Logger.sessionInfo(`Attachment download successful, file: ${fileName} for feedback ${id} `, {
                    emfLog: createEMFObject("AttachmentDownloads", 1, "Count", {AttachmentDownload:"success"})
                });
            })
            .catch(err=>{
                Logger.sessionError(`error getting preSigned url for file ${fileName} for feedback ${id} ${err}`, {
                    emfLog: createEMFObject("AttachmentDownloads", 1, "Count", {AttachmentDownload:"failure"})
                });
            })
        })
        .catch(err=>{
            Logger.sessionError(`error getting preSigned url for file ${fileName} for feedback ${id} ${err}`);
        })
    }

    createDataRow(value, type = 'text', link) {
        switch (type) {
            case 'text' :
                return <div>{formatString(value)}</div>;
            case 'link' :
                return <Link className="object-details__value" href={link}>{value}</Link>;
            case 'link_new_tab':
                return <Link className="object-details__value" href={link} target="_blank">{value}</Link>;
            case 'open_in_salesforce':
                return <TextButton onClick={()=>window.top.location.replace(link)} >{value}</TextButton>
            case 'downLoad':
                return <TextButton onClick={()=>this.makePresignedUrlRequest(link)} >{value}</TextButton>
            default :
                return <div>{formatString(value)}</div>;
        }
    }

}
FeedbackDetailPage.contextType = UserContext;
export default withRouter(FeedbackDetailPage);