import * as React from "react";

import Logger from "./util/logger";
import {Alert} from "@amzn/storm-ui";

class GlobalErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Logger.sessionError(`GlobalErrorBoundary ${error}, ${errorInfo}`);
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <div className="error-container">
          <Alert type="error">Something went wrong. Please try again.</Alert>
        </div>
      );
    }

    return children;
  }
}

export default GlobalErrorBoundary;
