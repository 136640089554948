// Code from https://code.amazon.com/packages/AWS-UI-Components/blobs/mainline-2.1/--/components/table/src/property-filtering/utils.ts

import { revenueBuckets } from "./constants";

const getOperationFn = (operation) => {
  switch (operation) {
    case "or":
      return (value1, value2) => value1 || value2;
    case "and":
    default:
      // This default has been intentionally added as defensive programming to catch
      // unsupported operations passed in my customers of the API
      return (value1, value2) => value1 && value2;
  }
};

const fullMatchStrategy = (token, item) => {
  if (Array.isArray(item[token.propertyKey])) {
    return item[token.propertyKey].indexOf(token.value) > -1;
  } else {
    // eslint-disable-next-line
    return token.value == item[token.propertyKey];
  }
};

const partialMatchStrategy = (token, item) => {
  const searchText = token.value.toLowerCase();
  const searchableProps = token.propertyKey ? [token.propertyKey] : Object.keys(item);

  return searchableProps.some((prop) => {
    return typeof item[prop] === "string" && item[prop].toLowerCase().indexOf(searchText) !== -1;
  });
};

const getMatchFn = (negated, matchStrategy) => {
  return negated ? (a, b) => !matchStrategy(a, b) : matchStrategy;
};

export const tableFilteringFunction = (item, tokens, operation) => {
  if (tokens && tokens.length > 0) {
    return tokens.reduce((include, token) => {
      const opFn = getOperationFn(operation);
      const strategy = token.isFreeText ? partialMatchStrategy : fullMatchStrategy;
      const eqFn = getMatchFn(token.negated, strategy);

      return opFn(include, eqFn(token, item));
    }, operation === "and");
  } else {
    return true;
  }
};

export const getDates = (epoch) => {
  const buckets = [];
  const today = new Date();
  [30, 90, 365].forEach((delta) => {
    const pastDate = new Date().setDate(today.getDate() - delta);

    if (parseInt(pastDate / 1000) < parseInt(epoch)) {
      buckets.push(`Last ${delta} days`);
    }
  });

  return buckets;
};

export const generateFilters = (filteringProperties, objects) => {
  let properties = [];
  filteringProperties.forEach((filteringProperty) => {
    let values = Array.from(new Set(objects.map((object) => object[filteringProperty.key]).filter(Boolean)));
    values = values.sort((a, b) => (a > b) - (a < b));

    if (filteringProperty.isDate && values.length > 0) {
      values = ["Last 30 days", "Last 90 days", "Last 365 days"];
    }

    if (filteringProperty.isCurrency && values.length > 0) {
      values = values.sort((a, b) => {
        const aBucket = revenueBuckets.find((bucket) => bucket.name === a);
        const bBucket = revenueBuckets.find((bucket) => bucket.name === b);
        return (aBucket.sortOrder > bBucket.sortOrder) - (aBucket.sortOrder < bBucket.sortOrder);
      });
    }

    if (filteringProperty.isAggregatedColumn) {
      values = Array.from(
        new Set(
          objects
            .map((object) => {
              if (object[`${filteringProperty.data}Buckets`]) {
                return object[filteringProperty.data].split(filteringProperty.delimiter || ", ");
              } else {
                return null;
              }
            })
            .filter(Boolean)
        )
      );
      values = values.flat(1).sort((a, b) => (a > b) - (a < b));
    }

    if (values && values.length > 0) {
      properties.push({
        values: values.flat(1),
        propertyLabel: filteringProperty.label,
        propertyKey: filteringProperty.key,
        groupValuesLabel: filteringProperty.label,
      });
    }
  });

  properties.sort((property1, property2) => property1.propertyLabel.localeCompare(property2.propertyLabel));

  return properties;
};

export const comaSeparatedFilterComparator = (filter, value, filterText) => {
  // get array of comma separated values
  const filterValues = filterText.split(',');
  // loop through filterValues and see if the value contains any of them
  return filterValues.some((item) => {
    return value.indexOf(item) >= 0;
  });
}