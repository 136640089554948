import * as React from "react";

import { Alert, Button, Modal, Text } from "@amzn/storm-ui";
import {
  AddFilterSelector,
  CurrencyCellRenderer,
  HeaderPanelContainer,
  PanelGroup,
  FilterBar,
  TextOperationValueFilter,
  Pagination,
  ValidationStatusEnum,
  CurrencyOperationValueFilter,
  SelectAllCheckbox,
  ClientSideExportButton,
  FooterPanelContainer,
  MultiSelectWithSearchFilter,
  NumberOperationValueFilter,
  setInitialFilterModel,
  FilterModel,
  udcMultiSelectWithSearchFilter,
  ColumnManager,
  setInitialColumnState,
  udcDateRangeFilter,
  DateRangeFilter,
  BulkActionSelector
} from "@amzn/unified-data-table-components/pack";

import "react-dates/initialize";

import { Table } from "../../components/unified-data-table/unified-data-table";
// import { Table } from "./unified-data-table";
import apiClient from "../../util/apiClient";
import Logger, { logPerformanceTime, logPageMetric } from "../../util/logger";

import {
  TABLE_ROWS_COUNT,
  THEME_STATUS,
  CHARACTER_LIMIT,
  SOURCE,
  IS_THEME_ADMIN,
  CEILING_BUCKET_SIZE
} from "../../util/constants";
import HeaderTable from "./HeaderTable";
import {formatDateTime, processFeedbacks, jsonParseData, createEMFObject, dateRangeFilterComparator, getTwoyearOldTimeStamp, getLastUpdateTimestamp, removeRecievedEntriesFromStoreFeedback} from "../../util/util";
import Loader from "../../components/Loader";
import Column from "../../components/column";
import { sortResults } from "../../util/util";
import { withRouter } from "react-router-dom";
import Row from "../../components/row";
import "../../styles/icon-list.scss";
import "../../styles/common.scss";
import SubmitFeedbackPage from "../feedback-submit-page/SubmitFeedbackPage";
import { comaSeparatedFilterComparator } from "../../util/tableFiltering";
import UserContext from "../../context/userContext";
import { PerformanceMarker, PerformanceMeasureName, Page } from '../../typings/enum';
import AttachToThemeDialog from "../feedback-themes/AttachToThemeDialog";
import { createMappingFlow, removeMappingFlow } from "../../util/themeUtils";
import MoreTextInCell from "./MoreTextInCell";
import { getFeedbacks } from "../../util/apiUtils";

const currency = "USD";
//const isThemeAdmin = verifyThemeRights();
const filterString = {
  filterComponentProps: {
    filterOptions: {
      contains: { label: "contains", default: true },
      notContains: { label: "not contains", default: false },
      equals: { label: "equals", default: false },
      notEqual: { label: "not equal to", default: false },
    },
    inputParams: {
      placeholder: "Enter filter here",
    },
    validate: (input) => {
      if (input === null || input === undefined) {
        return {
          status: ValidationStatusEnum.Error,
          message: "Input cannot be empty",
        };
      }
      return {
        status: ValidationStatusEnum.Success,
      };
    },
  },
};

const exportColumnKeys = [
  "id",
  "submittedByEmail",
  "csatScore",
  "accountId",
  "accountName",
  "agencyAssociatedAccountId",
  "agencyAssociatedAccountName",
  "category",
  "subCategory",
  "customerVerbatim",
  "translatedCustomerVerbatim",
  "advertisingSubProduct",
  "revenueImpacted",
  "createdTimestamp",
  "accountMarket",
  "submittedByName",
  "managerAlias",
  "accountRecordType",
  "accountVertical",
  "accountChannel",
  "salesTeam",
  "additionalContext",
  "translatedAdditionalContext",
  "whyThisImportant",
  "translatedWhyThisImportant",
  "csatScore",
  "accountOwnerName",
  "feedbackLabels",
  "attachments",
  "parentThemeName",
  "themeStatus",
  "updateSource"
];

const handleClientSideExport = (gridApi) => {
  Logger.sessionInfo("Exported All feedback table", {
    emfLog: createEMFObject("ExportClick", 1, "Count", {ExportAction:"FeedbacksExported"}),
  });
  gridApi.exportDataAsCsv({
    allColumns: true,
    onlySelected: false,
    columnKeys: exportColumnKeys,
    processCellCallback: (params)  => {
         if(params.column.colId === "createdTimestamp"){
            return formatDateTime(params.value)
         } else {
           return params.value;
         }
    },
    fileName: "exportfeedback.csv",
  });
};

class FeedbackTable extends React.Component {
  constructor(props) {
    super(props);
    // const modelOpen = localStorage.getItem("isCreateNewModalOpen");
    this.state = {
      feedbacks: [],
      isLoading: false,
      isError: false,
      currentPage: 1,
      sortColumn: "revenueImpact",
      fullDataLoaded: false,
      gridAPI: null,
      isCreateNewModalOpen:
        this.props?.location?.state?.isCreateNewModalOpen || false,
      type_servicesList: [],
      account_salesTeam: [],
      account_salesVertical: [],
      account_recordType: [],
      account_channel: [],
      type_labelList: [],
      category_servicesList: [],
      selectedThemeId: "",
      selectedThemeName: "",
      themeBlockedRevenue: "0",
      feedbackThemeDescription: "",
      selectedThemePriority: "",
      isSuccess: false,
      overrideReason: "",
      openConfirmationModal: false,

    };
    this.onSort = this.onSort.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.getStaticData = this.getStaticData.bind(this);
    this.saveStaticData = this.saveStaticData.bind(this);
    this.getFilterGroups = this.getFilterGroups.bind(this);
    this.loadAccountData = this.loadAccountData.bind(this);
    this.getBulkActiondef = this.getBulkActiondef.bind(this);
    this.setSelectedThemeDetails = this.setSelectedThemeDetails.bind(this);
    this.getFeedbackListAndUpdateTeable = this.getFeedbackListAndUpdateTeable.bind(this);
    this.setOpenConfirmationModal = this.setOpenConfirmationModal.bind(this);
    this.closeModalWithWarning = this.closeModalWithWarning.bind(this);
  }

  setSelectedThemeDetails(data, dataType){
    if(dataType==="mappingReason"){
      this.setState({
        overrideReason: data
      })
    } else {
      this.setState({
        selectedThemeId: data?.id,
        selectedThemeName: data?.fields?.themeName,
        themeBlockedRevenue: data?.fields?.blockedRevenue,
        selectedThemePriority: data?.fields?.themePriority,
        feedbackThemeDescription: data?.fields?.additionalContext,
      })
    }
  }

  getFilterGroups() {
    const filterGroups = [
      {
        colId: "accountName",
        filterName: "Account name",
        filterComponent: TextOperationValueFilter,
        filterString,
      },
    
      {
        colId: "customerVerbatim_1",
        filterName: "Customer quote",
        filterComponent: TextOperationValueFilter,
        filterString,
      },
    
      {
        colId: "category",
        filterName: "Feedback type",
        filterComponent: MultiSelectWithSearchFilter,
        filterComponentProps: {
          shouldAllowAddRemoveAll: true,
          filterOptions: {
            clientSideOptionRows: this.state.type_servicesList,
            getOptionLabel: (data) => data.lineItem,
            getOptionValue: (data) => data.lineItem,
            getOptionQuickFilterText: (data) => data.lineItem,
          },
        },
      },
      {
        colId: "submittedByName",
        filterName: "Submitted by",
        filterComponent: TextOperationValueFilter,
        filterString,
      },
      {
        colId: "keyword",
        filterName: "Keywords",
        filterComponent: TextOperationValueFilter,
        filterString,
      },
      {
        colId: "parentThemeName",
        filterName: "Parent theme",
        filterComponent: TextOperationValueFilter,
        filterString,
      },
      {
        colId: "updateSource",
        filterName: "Theme mapping source",
        filterComponent: MultiSelectWithSearchFilter,
          filterComponentProps: {
              shouldAllowAddRemoveAll: true,
              filterOptions: {
                  clientSideOptionRows: SOURCE,
                  getOptionLabel: (data) => data,
                  getOptionValue: (data) => data,
                  getOptionQuickFilterText: (data) => data,
              },
          },
      },
      {
        colId: "themeStatus",
        filterName: "Theme status",
        filterComponent: MultiSelectWithSearchFilter,
        filterComponentProps: {
          shouldAllowAddRemoveAll: true,
          filterOptions: {
            clientSideOptionRows: THEME_STATUS,
            getOptionLabel: (data) => data,
            getOptionValue: (data) => data,
            getOptionQuickFilterText: (data) => data,
          },
        },
      },
    
      {
        colId: "accountMarket",
        filterName: "Locale",
        filterComponent: TextOperationValueFilter,
        filterString,
      },
      {
        colId: "salesTeam",
        filterName: "Sales team",
        filterComponent: TextOperationValueFilter,
        filterString,
      },
      {
        colId: "accountVertical",
        filterName: "Vertical",
        filterComponent: MultiSelectWithSearchFilter,
        filterComponentProps: {
          shouldAllowAddRemoveAll: true,
          filterOptions: {
            clientSideOptionRows: this.state.account_salesVertical,
            getOptionLabel: (data) => data.lineItem,
            getOptionValue: (data) => data.lineItem,
            getOptionQuickFilterText: (data) => data.lineItem,
          },
        },
      },
      {
        colId: "csatScore",
        filterName: "Customer satisfaction score",
        filterComponent: NumberOperationValueFilter,
        filterString,
      },
      {
        colId: "managerAlias",
        filterName: "Manager alias",
        filterComponent: TextOperationValueFilter,
        filterString,
      },
      {
        colId: "accountRecordType",
        filterName: "Account record type",
        filterComponent: MultiSelectWithSearchFilter,
        filterComponentProps: {
          shouldAllowAddRemoveAll: true,
          filterOptions: {
            clientSideOptionRows: this.state.account_recordType,
            getOptionLabel: (data) => data.lineItem,
            getOptionValue: (data) => data.lineItem,
            getOptionQuickFilterText: (data) => data.lineItem,
          },
        },
      },
      {
        colId: "accountChannel",
        filterName: "Account channel",
        filterComponent: MultiSelectWithSearchFilter,
        filterComponentProps: {
          shouldAllowAddRemoveAll: true,
          filterOptions: {
            clientSideOptionRows: this.state.account_channel,
            getOptionLabel: (data) => data.lineItem,
            getOptionValue: (data) => data.lineItem,
            getOptionQuickFilterText: (data) => data.lineItem,
          },
        },
      },
      {
        colId: "whyThisImportant",
        filterName: "Why this important",
        filterComponent: TextOperationValueFilter,
        filterString,
      },
      {
        colId: "accountId",
        filterName: "Account id",
        filterComponent: TextOperationValueFilter,
        filterString,
      },
      {
        colId: "accountOwnerName",
        filterName: "Account owner name",
        filterComponent: TextOperationValueFilter,
        filterString,
      },
      {
        colId: "additionalContext",
        filterName: "Additional context",
        filterComponent: TextOperationValueFilter,
        filterString,
      },
    
      {
        colId: "feedbackLabels",
        filterName: "Feedback labels",
        filterComponent: MultiSelectWithSearchFilter,
        filterComponentProps: {
          shouldAllowAddRemoveAll: true,
          filterOptions: {
            clientSideOptionRows: this.state.type_labelList,
            getOptionLabel: (data) => data.lineItem,
            getOptionValue: (data) => data.lineItem,
            getOptionQuickFilterText: (data) => data.lineItem,
          },
        },
      },
    
      {
        colId: "subCategory",
        filterName: "Feedback category",
        filterComponent: MultiSelectWithSearchFilter,
        filterComponentProps: {
          shouldAllowAddRemoveAll: true,
          filterOptions: {
            clientSideOptionRows: this.state.category_servicesList,
            getOptionLabel: (data) => data.lineItem,
            getOptionValue: (data) => data.lineItem,
            getOptionQuickFilterText: (data) => data.lineItem,
          },
        },
      },
    
      {
        colId: "revenueImpacted",
        filterName: "Blocked revenue",
        filterComponent: CurrencyOperationValueFilter,
      },
      {
        colId: "advertisingSubProduct",
        filterName: "Product/Service",
        filterComponent: MultiSelectWithSearchFilter,
        filterComponentProps: {
          shouldAllowAddRemoveAll: true,
          filterOptions: {
            clientSideOptionRows: this.state.product_servicesList,
            getOptionLabel: (data) => data.lineItem,
            getOptionValue: (data) => data.lineItem,
            getOptionQuickFilterText: (data) => data.lineItem,
          },
        },
      },
    ];
    return filterGroups;
  }

  onSort({ sortColumn, sortDirection }) {
    let { feedbacks } = this.state;
    feedbacks = sortResults(feedbacks, sortColumn, sortDirection);
    this.setState({ feedbacks, sortColumn, sortDirection });
  }

  handlePageChange(page) {
    this.setState({
      currentPage: page,
    });
  }

  getFeedbackList(from, size, fromTimeStamp) {
    if(!fromTimeStamp) {
      fromTimeStamp = getTwoyearOldTimeStamp();
    }
    const { myFeedbackPage } = this.props;
    const { user } = this.context;
    let lastUpdatefilter = `lastUpdateTimestamp:${fromTimeStamp+''}`
    if(myFeedbackPage){
      let userEmail = `+submittedByEmail:${user.email}`;
      return getFeedbacks(lastUpdatefilter+userEmail, from,size)
    } else {
      return getFeedbacks(lastUpdatefilter, from,size)
    }
  }

  loadAccountData() {
    apiClient
      .get("/accountinfo")
      .then(response => {
        this.saveAccountInfoData(response?.data?.results)
      })
      .catch(err => {
        Logger.sessionError(`error occured while fetching account data ${err}`, {
          browserUrl: window.location.href,
          emfLog: createEMFObject(`FetchingAccountDataFailed`, 1, "Count", ),
        });
        this.setState({ isError: true });
      })
  }

  saveAccountInfoData(accountInfoData) {
    for(const [accountDataKey,valueArray] of Object.entries(accountInfoData)) {
          let obj = [];
          valueArray.forEach(value=>{
            let data = {
            lineItem: value,
            };
            obj.push(data);
          })
          let stateObj = {};
          const stateDield = "account_"+accountDataKey;
          stateObj[stateDield] = obj;
          this.setState(stateObj);
        }
  }

  loadStaticData() {
    const params = {};
    this.setState({ isLoading: true });
    apiClient
      .get("/static?size=10000", { params })
      .then((response) => {
        this.setState({
          staticData: response?.data?.results?.map((stat) => {
            return Object.assign(stat.fields, { id: stat.id });
          }),
        });
        {
          let feedbackProducts = this.getStaticData("PRODUCT_SERVICES");
          let obj = [];
          feedbackProducts = feedbackProducts.filter(
            (word) => word !== "All Products"
          );
          let uSet = new Set(feedbackProducts);
          feedbackProducts = Array.from(uSet);
          feedbackProducts.sort();
          feedbackProducts.forEach((element) => {
            let data = {
              lineItem: element,
            };
            obj.push(data);
          });
          this.setState({
            product_servicesList: obj
          })
        }
        this.saveStaticData("FEEDBACK_CATEGORY", "category_servicesList");
        this.saveStaticData("FEEDBACK_TYPE", "type_servicesList");
        this.saveStaticData("FEEDBACK_LABEL", "type_labelList");
        this.loadFormDataAutoFill();
      })
      .catch((error) => {
        Logger.sessionError(`FeatureTable static ${error}`, {
          browserUrl: window.location.href,
        });
        this.setState({ isError: true });
      })
      .then(() => {
        this.setState({ isLoading: false });
      });
  }

  saveStaticData(field, stateField) {
        let fieldData = this.getStaticData(field);
        fieldData.sort();
        let obj = [];
        fieldData.forEach((element) => {
            let data = {
            lineItem: element,
            };
            obj.push(data);
        });
        let stateObj = {};
        stateObj[stateField] = obj;
        this.setState(stateObj);
    }

  loadFormDataAutoFill() {
    const { history } = this.props;
    const location = history.location;
    const search_params = location.search;
    if (search_params !== "" && search_params !== undefined) {
      const params = new URLSearchParams(search_params);
      const type = params.get("type");
      if (type !== undefined && type !== null)
        this.setState({
          isCreateNewModalOpen: true,
        });
    }
  }

  getStaticData(type, parent) {
    // type should be either CATEGORY or PRODUCT
    let { staticData } = this.state;
    let dataArr = [];
    let entities;
    if (staticData.length) {
      if (parent) {
        entities = staticData.filter((obj) => {
          return obj.docType === type && obj.parent === parent;
        });
      } else {
        entities = staticData.filter((obj) => {
          return obj.docType === type;
        });
      }

      for (let data of entities) {
        dataArr.push(data.value);
      }
    }
    return dataArr;
  }

  async getFeedbackListAndUpdateTeable(requestedItemsCount, bucketSize, fromTimeStamp) {
    const response = await this.getFeedbackList(requestedItemsCount, bucketSize, fromTimeStamp);
    const { myFeedbackPage, storeProp } = this.props;
    // const { feedbacks } = this.state;
    const {
      data: { results: moreFeedbacks },
    } = response;

    const freshFeedbacks = processFeedbacks(moreFeedbacks);
    const {myFeedbacksList, feedbacksList} = storeProp;
    const storeFeedbacks = myFeedbackPage ? myFeedbacksList : feedbacksList;
    const storeFeedbacksAfterDeduplication = removeRecievedEntriesFromStoreFeedback(freshFeedbacks,storeFeedbacks);
    const updatedFeedbacks = [...freshFeedbacks, ...storeFeedbacksAfterDeduplication];

    this.setState(
      prevState=> ({feedbacks: [...updatedFeedbacks,...prevState.feedbacks]})
    );
    if (this.state.gridAPI !== null) {
      this.state.gridAPI.applyTransaction({
        add: freshFeedbacks,
      });
    }
  }

  async componentDidMount() {
    const {token, user} = this.context;
    const { myFeedbackPage, storeProp } = this.props;
    const isThemeAdmin = storeProp.userPermissions[IS_THEME_ADMIN];
    let deltaTime = getTwoyearOldTimeStamp();
    this.setState({
      isThemeAdmin
    });
    this.loadStaticData();
    this.loadAccountData();
    if (token && user) {
      const pageStartTime = Date.now();
      this.setState({
        pageStartTime
      })
      performance.mark(PerformanceMarker.APILoadStart);
      
      if(storeProp) {
        if( myFeedbackPage && storeProp?.lastUpdatedTimeMyFeedback ) {
          deltaTime = Math.max(deltaTime, parseInt(storeProp.lastUpdatedTimeMyFeedback))
        }else if(!myFeedbackPage && storeProp.lastUpdatedTimeAllFeedback ) {
          deltaTime = Math.max(deltaTime, parseInt(storeProp.lastUpdatedTimeAllFeedback))
        }
      }
      try{
        const response = await this.getFeedbackList(0, TABLE_ROWS_COUNT, deltaTime);
          logPageMetric(Page.MyFeedbackPage);
          performance.mark(PerformanceMarker.APILoadEnd);
          performance.measure(
            `First_${PerformanceMeasureName.APILoadTime}`,
            PerformanceMarker.APILoadStart,
            PerformanceMarker.APILoadEnd
          );
          logPerformanceTime(`First_${PerformanceMeasureName.APILoadTime}`);
          const processedfeedbacks = processFeedbacks(response.data.results);
          const {myFeedbacksList, feedbacksList} = storeProp;
          const storeFeedbacks = myFeedbackPage ? myFeedbacksList : feedbacksList;
          const storeFeedbacksAfterDeduplication = removeRecievedEntriesFromStoreFeedback(processedfeedbacks,storeFeedbacks);
          const mergedFeedbacks = [...processedfeedbacks, ...storeFeedbacksAfterDeduplication];
          this.setState({
            feedbacks: mergedFeedbacks,
          });
          let requestedItemsCount = TABLE_ROWS_COUNT;
          let bucketSize = TABLE_ROWS_COUNT;
          let index = 1;
          const apiPromise = [];
          if(requestedItemsCount>=response.data.totalItemsCount) {
            this.setState({
              fullDataLoaded: true
            })
          }
          while (requestedItemsCount < response.data.totalItemsCount) {
            index++;
            if(index===2){
              bucketSize = 300;
            }
            else{
              bucketSize = CEILING_BUCKET_SIZE;
            }
            apiPromise.push(this.getFeedbackListAndUpdateTeable(requestedItemsCount,bucketSize, deltaTime));
            requestedItemsCount += bucketSize;
          }
          Promise.all(apiPromise).then(res => {
            this.setState({
              fullDataLoaded: true
            });
            performance.mark(PerformanceMarker.AllRequestEnd);
            performance.measure(
              `Total_${PerformanceMeasureName.APIDataLoadTime}`,
              PerformanceMarker.APILoadStart,
              PerformanceMarker.AllRequestEnd
            );
            logPerformanceTime(`Total_${PerformanceMeasureName.APIDataLoadTime}`);
          })
          performance.mark(PerformanceMarker.AllAPICallEnd);
          performance.measure(
            `Total_${PerformanceMeasureName.APILoadTime}`,
            PerformanceMarker.APILoadStart,
            PerformanceMarker.AllAPICallEnd
          );
          logPerformanceTime(`Total_${PerformanceMeasureName.APILoadTime}`);
        // }
      

      } catch(error) {
        Logger.sessionError(
          `FeatureDetailPage FeedbackTable componentDidMount",
          ${error}`,
          {
            browserUrl: window.location.href,
          }
        );
        this.setState({ isError: true });
      }
    }
    
  }

  componentWillUnmount() {
    const {pageStartTime} = this.state;
    const {myFeedbackPage} = this.props;
    const pageEndTime = Date.now();
    const timeSpent = pageEndTime - pageStartTime;
    if(myFeedbackPage){
      Logger.sessionInfo("Time spent on myFeedback page", {
        emfLog: createEMFObject("MyFeedbackTimeSpent", timeSpent, "Milliseconds"),
      });
    } else {
      Logger.sessionInfo("Time spent on feedback page", {
        emfLog: createEMFObject("FeedbackTimeSpent", timeSpent, "Milliseconds"),
      });
    }
  }

  componentDidUpdate(prevProp, prevState) {
    const { myFeedbackPage, storeProp } = this.props;
    const { twoYearLength, lastUpdatedTimeAllFeedback, lastUpdatedTimeMyFeedback } = storeProp;
    const lastUpdatedTime = lastUpdatedTimeMyFeedback || lastUpdatedTimeAllFeedback;
    const { user } = this.context;
    if( this.state.fullDataLoaded && prevState.fullDataLoaded !== this.state.fullDataLoaded){
      Logger.sessionInfo("full data loaded", prevState.fullDataLoaded, this.state.fullDataLoaded )
      Logger.sessionInfo(`verifying cache reliability: ${user.email},${this.state.feedbacks.length},${twoYearLength},${formatDateTime(lastUpdatedTime)},${formatDateTime(Math.floor(Date.now()/1000))}`);
      //validate final loaded data:
      if(twoYearLength!==this.state.feedbacks.length) {
        Logger.sessionError("feedback count mismatch", {
          emfLog: createEMFObject("FeedbackCountMismatch", 1, "Count"),
        });
        if (myFeedbackPage) {
          storeProp?.updateMyFeedbackList([]);
          storeProp?.setlastUpdatedTimeMyFeedback("");
        } else {
          storeProp?.updateFeedbackList([]);
          storeProp?.setlastUpdatedTimeAllFeedback("");
        }
        this.setState({
          isError: true
        })
      } else {
        if (myFeedbackPage) {
          const latestUpdateTimeStamp = getLastUpdateTimestamp(this.state.feedbacks);
          storeProp?.updateMyFeedbackList(this.state.feedbacks);
          storeProp?.setlastUpdatedTimeMyFeedback(latestUpdateTimeStamp);
        } else {
          const latestUpdateTimeStamp = getLastUpdateTimestamp(this.state.feedbacks);
          storeProp?.updateFeedbackList(this.state.feedbacks);
          storeProp?.setlastUpdatedTimeAllFeedback(latestUpdateTimeStamp);
        }
      }
    }

}

  getBulkActiondef() {
    return [
      {
        bulkActionComponent: AttachToThemeDialog,
        bulkActionComponentProps: {
          action: "assign",
          setSelectedThemeDetails: this.setSelectedThemeDetails
        },
        bulkActionId: 'assignThemeAction',
        label: 'Assign to theme',
        getDisabledStatus: (selectedRowItem) => ({
          disabled: false,
        }),
        getSelectedRowLabel: (rowItem) => "Assign to theme",
        onApply: async (params) => {
          this.setState({ isLoading: true });
          const { selectedRowItems } = params;
          const selectedRowwithoutSeletedTheme = selectedRowItems.filter(rowItem=>rowItem.rowData.parentTheme!==this.state.selectedThemeId);
          const { user } = this.context;
          const feedbackWithThemeList = selectedRowwithoutSeletedTheme.filter(rowItem=>rowItem.rowData.parentTheme);
          try{
            let mappingSubmitArr = [];
            let submitTheme = {};
            if(feedbackWithThemeList.length>0){
              [mappingSubmitArr, submitTheme] = removeMappingFlow(selectedRowwithoutSeletedTheme, user)
            }
            if(selectedRowwithoutSeletedTheme?.length>0){
              await createMappingFlow(
                  this.state.selectedThemeId,
                  this.state.selectedThemeName,
                  this.state.themeBlockedRevenue,
                  this.state.feedbackThemeDescription,
                  this.state.selectedThemePriority,
                  selectedRowwithoutSeletedTheme,
                  user,
                  mappingSubmitArr,
                  submitTheme, 
                  this.state.overrideReason
              );
            }
            if(sessionStorage !== null) {
              sessionStorage.setItem("isMappingupdated",true);
            }
            this.setState({isLoading:false});
            this.setState({isSuccess:true});
            const encodedId = encodeURIComponent(this.state.selectedThemeId)
            this.props.history.push(`/themes/${encodedId}`);
          } catch(err) {
            Logger.sessionError(`Error occurred while creating feedback theme mapping ${err}`);
            this.setState({isLoading:false});
            this.setState({isError: true});
          }
        },
      },
    ]
  }
  getColumnDefs() {
    return [
      {
        colId: "checkboxCol",
        checkboxSelection: (params)=>{
          return params.data.reviewStatus!=="pending"
        },
        headerComponent: null,
        width: 100,
        hide: !(this.state.isThemeAdmin),
        alwaysHidden: !(this.state.isThemeAdmin),
        hideWhenResetToDefault: !(this.state.isThemeAdmin),
        cellStyle: {cursor: "default"}
      },
      {
        colId: "accountId",
        field: "accountId",
        headerName: "Account id",
        lockVisible: true,
        hide: true,
        hideWhenResetToDefault: true,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          textCustomComparator: comaSeparatedFilterComparator
        },
      },
      {
        colId: "agencyAssociatedAccountId",
        field: "agencyAssociatedAccountId",
        headerName: "Associated Account Id",
        hide: true,
        alwaysHidden: true,
        hideWhenResetToDefault: true,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          textCustomComparator: comaSeparatedFilterComparator
        },
      },
      {
        colId: "id",
        field: "id",
        headerName: "id",
        lockVisible: true,
        hide: true,
        alwaysHidden: true,
      },
      {
        colId: "submittedByEmail",
        field: "submittedByEmail",
        headerName: "Submitted by email",
        lockVisible: true,
        hide: true,
        alwaysHidden: true,
      },
      {
        colId: "attachments",
        field: "attachments",
        headerName: "Attachment urls",
        lockVisible: true,
        hide: true,
        alwaysHidden: true,
      },
      {
        colId: "accountName",
        field: "accountName",
        sortable: true,
        resizable: true,
        width: 180,
        minWidth: 170,
        maxWidth: 170,
        headerName: "Account name",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          textCustomComparator: comaSeparatedFilterComparator,
        },
      },
      {
        colId: "agencyAssociatedAccountName",
        field: "agencyAssociatedAccountName",
        headerName: "Associated Account",
        hide: true,
        alwaysHidden: false,
        hideWhenResetToDefault: true,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          textCustomComparator: comaSeparatedFilterComparator
        },
      },
      {
        colId: "category",
        field: "category",
        sortable: true,
        headerName: "Feedback type",
        filter: udcMultiSelectWithSearchFilter,
      },
      {
        colId: "subCategory",
        field: "subCategory",
        sortable: true,
        headerName: "Feedback category",
        filter: udcMultiSelectWithSearchFilter,
      },
      {
        colId: "customerVerbatim",
        field: "customerVerbatim",
        headerName: "Customer quote",
        alwaysHidden: true,
        lockVisible: true,
        hide: true,
      },
      {
        colId: "customerVerbatim_1",
        field: "customerVerbatim_1",
        headerName: "Customer quote",
        width: 300,
        maxWidth: 300,
        cellRenderer: 'MoreTextInCell',
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          textCustomComparator: comaSeparatedFilterComparator,
        },
        valueGetter: (params) =>
          params.data.customerVerbatim
      },
      {
        colId: "translatedCustomerVerbatim",
        field: "translatedCustomerVerbatim",
        headerName: "Translated customer quote",
        sortable: true,
        maxWidth: 300,
        cellRenderer: 'MoreTextInCell',
        lockVisible: true,
        hide: true,
        hideWhenResetToDefault: true,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: "contains",
          textCustomComparator: comaSeparatedFilterComparator
        },
      },
      {
        colId: "advertisingSubProduct",
        field: "advertisingSubProduct",
        headerName: "Product/Service",
        filter: udcMultiSelectWithSearchFilter,
        width: 180,
        maxWidth: 200,
        valueGetter: (params) => params.data.advertisingSubProduct,
      },
      {
        colId: "revenueImpacted",
        field: "revenueImpacted",
        sortable: true,
        headerName: "Blocked revenue",
        cellRenderer: CurrencyCellRenderer,
        cellRendererParams: {
          getFormattedValue: (value, params) =>
            value === null || value === undefined
              ? "EMPTY_CELL_VALUE"
              : new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: currency,
                }).format(value),
        },
        filter: "agNumberColumnFilter",
      },
      {
        colId: "createdTimestamp",
        field: "createdTimestamp",
        headerName: "Submitted date",
        sortable: true,
        valueFormatter:(params) => formatDateTime(params.data.createdTimestamp),
        filter: udcDateRangeFilter,
        sort: "desc",
        filterValueGetter: (params) => {
          return  formatDateTime(params.data.createdTimestamp);
        },
        filterParams: {
          comparator: dateRangeFilterComparator,
        },
      },

      {
        colId: "accountMarket",
        field: "accountMarket",
        sortable: true,
        headerName: "Locale",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          textCustomComparator: comaSeparatedFilterComparator,
        },
      },
      {
        colId: "feedbackLabels",
        field: "selectedLabelsPayload",
        headerName: "Labels",
        filter: udcMultiSelectWithSearchFilter,
        maxWidth: 300,
      },
      {
        colId: "submittedByName",
        field: "submittedByName",
        sortable: true,
        headerName: "Submitted by",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          textCustomComparator: comaSeparatedFilterComparator,
        },
      },
      {
        colId: "themeStatus",
        field: "themeStatus",
        sortable: true,
        headerName: "Feedback status",
        hideWhenResetToDefault: true,
        filter: udcMultiSelectWithSearchFilter,
      },
      {
        colId: "parentThemeName",
        field: "parentThemeName",
        sortable: true,
        headerName: "Parent theme",
        hideWhenResetToDefault: true,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          textCustomComparator: comaSeparatedFilterComparator,
        },
      },
      {
        colId: "updateSource",
        field: "updateSource",
        sortable: true,
        headerName: "Theme mapping source",
        valueGetter: params => {
          return params.data.updateSource === 'dataScience' ? 'Sales AI':'ACF portal'
        },
        hideWhenResetToDefault: true,
        filter: udcMultiSelectWithSearchFilter,
      },
      {
        colId: "managerAlias",
        field: "managerName",
        headerName: "Manager alias",
        sortable: true,
        lockVisible: true,
        hide: true,
        hideWhenResetToDefault: true,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          textCustomComparator: comaSeparatedFilterComparator
        },
      },
      {
        colId: "accountRecordType",
        field: "accountRecordType",
        headerName: "Account record type",
        sortable: true,
        lockVisible: true,
        hide: true,
        hideWhenResetToDefault: true,
        filter: udcMultiSelectWithSearchFilter
      },
      {
        colId: "accountVertical",
        field: "accountVertical",
        headerName: "Vertical",
        sortable: true,
        lockVisible: true,
        hide: true,
        hideWhenResetToDefault: true,
        filter: udcMultiSelectWithSearchFilter,
      },
      {
        colId: "accountChannel",
        field: "accountChannel",
        headerName: "Channel",
        sortable: true,
        lockVisible: true,
        hide: true,
        hideWhenResetToDefault: true,
        filter: udcMultiSelectWithSearchFilter,
      },
      {
        colId: "salesTeam",
        field: "salesTeam",
        headerName: "Sales team",
        sortable: true,
        lockVisible: true,
        hide: true,
        hideWhenResetToDefault: true,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          textCustomComparator: comaSeparatedFilterComparator,
        },
      },
      {
        colId: "additionalContext",
        field: "additionalContext",
        headerName: "Additional context",
        cellRenderer: 'MoreTextInCell',
        sortable: true,
        lockVisible: true,
        hide: true,
        hideWhenResetToDefault: true,
        filter: "agTextColumnFilter",
      },
      {
        colId: "translatedAdditionalContext",
        field: "translatedAdditionalContext",
        headerName: "Translated additional context",
        cellRenderer: 'MoreTextInCell',
        sortable: true,
        lockVisible: true,
        hide: true,
        hideWhenResetToDefault: true,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: "contains",
          textCustomComparator: comaSeparatedFilterComparator
        },
      },
      {
        colId: "keyword",
        field: "additionalContext",
        headerName: "Keyword",
        valueGetter: (params) => {
          return params.data.customerVerbatim + ' ' + params.data.translatedCustomerVerbatim + ' ' + params.data.additionalContext + ' ' + params.data.translatedAdditionalContext
        },
        hide: true,
        hideWhenResetToDefault: true,
        lockVisible: true,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          textCustomComparator: comaSeparatedFilterComparator
        },
      },
      {
        colId: "whyThisImportant",
        field: "whyThisImportant",
        headerName: "Why is this important?",
        cellRenderer: 'MoreTextInCell',
        sortable: true,
        lockVisible: true,
        hide: true,
        hideWhenResetToDefault: true,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          textCustomComparator: comaSeparatedFilterComparator
        },
      },
      {
        colId: "translatedWhyThisImportant",
        field: "translatedWhyThisImportant",
        headerName: "Why is this important?(translated)",
        cellRenderer: 'MoreTextInCell',
        sortable: true,
        lockVisible: true,
        hide: true,
        hideWhenResetToDefault: true,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: "contains",
          textCustomComparator: comaSeparatedFilterComparator
        },
      },
      {
        colId: "csatScore",
        field: "csatScore",
        headerName: "Customer satisfaction score",
        sortable: true,
        lockVisible: true,
        hide: true,
        hideWhenResetToDefault: true,
        filter: "agNumberColumnFilter"
      },
      {
        colId: "accountOwnerName",
        field: "accountOwnerName",
        headerName: "Account owner alias",
        sortable: true,
        lockVisible: true,
        hide: true,
        hideWhenResetToDefault: true,
        filter: "agTextColumnFilter",
      },
    ];
  }
  setOpenConfirmationModal(val) {
    this.setState({
      openConfirmationModal: val
    })
  }

  closeModalWithWarning() {
    this.setState({
        openConfirmationModal: !this.state.openConfirmationModal
    })
  }
  render() {
    const { feedbacks, isSuccess, isError, isLoading, isCreateNewModalOpen, isThemeAdmin } = this.state;
    //const isThemeAdmin = verifyThemeRights();
    const footerPanelElement = (
      <FooterPanelContainer>
        <PanelGroup align="end">
          <Pagination></Pagination>
        </PanelGroup>
      </FooterPanelContainer>
    );
    const closeModal = () => {
      this.setState({ isCreateNewModalOpen: null, openConfirmationModal: false });
      if (localStorage.getItem("newFeedbackCreated")) {
        localStorage.removeItem("newFeedbackCreated");
        window.location.replace("/feature");
      }
    };
    const headerPanelElement = (
      <>
        <FilterBar filterGroups={this.getFilterGroups()} />
        <HeaderPanelContainer>
          <PanelGroup align="start">
            {(isThemeAdmin) &&
              <BulkActionSelector
                labels={
                  {
                    selectorText: "Action",
                    // dialogTitle: "Row Action",
                    // getRowNameDisplayText: ()=>"",
                    saveChanges: "Proceed",
                    getRowsSelectedDisplayText: (count) => `${count} Row Selected`
                  }
                }
                bulkActionDefs={this.getBulkActiondef()}
              />
            }
            <Button
              primary={true}
              onClick={() => this.setState({ isCreateNewModalOpen: true })}
            >
              Submit new feedback
            </Button>
            <HeaderTable></HeaderTable>
            <AddFilterSelector filterGroups={this.getFilterGroups()} />
            <ClientSideExportButton
              primary
              labels="ExportAll"
              onExport={handleClientSideExport}
            />
            {<DateRangeFilter
                filterColId="createdTimestamp"
                  customPeriodId="CUSTOM"
                  secondaryViewElementId="portal"
                  zone="America/Toronto"
                  />}
          </PanelGroup>
          <PanelGroup align="end">
            <ColumnManager columnExtendedDefs={this.getColumnDefs()}/>
          </PanelGroup>
        </HeaderPanelContainer>
      </>
    );

    if(isLoading){
      return (
        <Row
          spacingInset="xlarge"
          alignmentVertical="center"
          alignmentHorizontal="center"
        >
          <Loader />
        </Row>
      );
    }
    if(isError){
      return (
        <div className="error-container">
          <Alert type="error">
            Something went wrong. Please try again. <a href=".">Reload</a>
          </Alert>
        </div>
      );
    }
    if (isSuccess) {
      return (
        <div className="error-container">
          <Alert type="success">
            Success
          </Alert>
        </div>
      );
    }
    if (isCreateNewModalOpen) {
      return (
        <Modal
          header="Add Customer Feedback"
          onClose={this.closeModalWithWarning}
          isOpen={isCreateNewModalOpen}
        >
          <SubmitFeedbackPage
            {...this.props}
            setOpenConfirmationModal={this.setOpenConfirmationModal} 
            openConfirmationModal={this.state.openConfirmationModal}
            onClose={closeModal}
            mode="create"
          ></SubmitFeedbackPage>
        </Modal>
      );
    }

    return feedbacks.length === 0 && !isLoading ? (
      <Column minWidth="100%" spacing="small" spacingInset="xlarge">
  
	         <Row spacingInset="300">
  
	           <span className="list-icon" />
  
	           <Text type="h3">My submitted feedback</Text>
  
	         </Row>
  
	         <Row backgroundColor="#F3F6F7" spacingInset="large">
  
	           <Text type="b500">
  
	             {" "}
  
	             You don't have any customer feedback to display.{" "}
  
	           </Text>
  
	         </Row>
  
	       </Column>
  
	     ) : (
      <div className="container">
        <Column>
          <div className="element-style">
            <Table
              gridOptions={{
                onPaginationChanged : (e) => {
                  const { myFeedbackPage } = this.props;
                  if(e.newPage){
                    if(myFeedbackPage) {
                      Logger.sessionInfo("My Feedback page pagination clicked", {
                        emfLog: createEMFObject("MyFeedbackPaginationClick", 1, "Count"),
                      });
                    } else {
                      Logger.sessionInfo("Feedback page pagination clicked", {
                        emfLog: createEMFObject("FeedbackPaginationClick", 1, "Count"),
                      });
                    }
                  }
                },
                onSortChanged: () => this.setState({ currentPage: 0 }),
                // onRowClicked: (params) => {
                //   this.props.history.push("/feedback/" + params.node.data.id);
                // },
                animateRows: true,
                pagination: true,
                paginationPageSize: 50,
                cacheBlockSize: feedbacks.length,
                rowData: feedbacks,
                columnDefs: this.getColumnDefs(),
                getRowStyle: params => {
                  return {cursor: "pointer"}
                },
                rowHeight: undefined,
                getRowHeight: undefined,
                defaultColDef: {
                  wrapText: true,
                  autoHeight: true,
                },
                frameworkComponents: {
                  SelectAllCheckbox,
                },
                getRowId: (rowData) => {
                  return rowData.data.id
                },
                rowSelection: 'multiple',
                isRowSelectable: rowNode => true,
                onCellClicked: (params) => {
                  const allRowdata = [];
                  params.api.forEachNodeAfterFilter((node) => {
                    allRowdata.push(node.data);
                  });
                  let characterLimit = (params?.column?.colDef?.characterLimit) ? params.column.colDef.characterLimit : CHARACTER_LIMIT;
                  let path = window.location.pathname;
                  let cellClicked = params.column.colId;
                  let colsToIgnoreOnCellClicked = [ 'customerVerbatim_1', 'translatedCustomerVerbatim', 'whyThisImportant', 'additionalContext', 'translatedAdditionalContext', 'translatedWhyThisImportant'];
                  let clickIgnore = colsToIgnoreOnCellClicked.includes(cellClicked) && params?.value?.length > characterLimit;
                  path = path.replace("/","");
                  if(path.includes("feedback")){
                    path = "/feedback/";
                  } else {
                    path = "/feature/";
                  }
                  if (cellClicked === "checkboxCol" || clickIgnore) {
                    return;
                  } else {
                    this.props.history.push(path + params.node.data.id,{
                      feedbackArray: allRowdata.map((feedback)=>({id: feedback.id})),
                      rowIndex: params.rowIndex
                    });
                  }
                }
              }}
              frameworkComponents= {{
                'MoreTextInCell' : MoreTextInCell
              }}
              onGridReady={(event) => {
                this.setState({ gridAPI: event.api });
                if(localStorage.getItem("filterData")) {
                  setInitialFilterModel(event.api, jsonParseData(localStorage.getItem("filterData")));
                  Logger.sessionInfo("Filter applied on All feedback Page", {
                    emfLog: createEMFObject("LoadSavedFilter", 1, "Count", {FilterAction:"FeedbackTable"}),
                  });
                }
                if(localStorage.getItem("columnData")) {
                  setInitialColumnState({columnApi: event.columnApi, initialColumnState: jsonParseData(localStorage.getItem("columnData"))});
                  Logger.sessionInfo("Filter applied on All feedback Page", {
                    emfLog: createEMFObject("LoadSavedColumn", 1, "Count", {FilterAction:"FeedbackTable"}),
                  });
                }
              }}
              onFilterChanged={(event) => {
                const filterModel: FilterModel = event.api.getFilterModel();
                localStorage.setItem("filterData" ,JSON.stringify(filterModel))
                this.setState({ filterPresets: filterModel})
                Logger.sessionInfo("Filter applied on All feedback Page", {
                  emfLog: createEMFObject("FilterApplied", 1, "Count", {FilterAction:"FeedbackTable"}),
                });
                for(const param in filterModel){
                  Logger.sessionInfo("Filter applied on All feedback Page", {
                    emfLog: createEMFObject("FilterApplied", 1, "Count", {FilterAction:"FeedbackTable", filterModel:param}),
                  });
                }
              }}
              tableFooter={footerPanelElement}
              headerPanel={headerPanelElement}
              onColumnPresetChanged={(event) => {
                Logger.sessionInfo("Column Preset applied on All feedback Page", {
                  emfLog: createEMFObject("ColumnPresetApplied", 1, "Count", {ColumnPresetAction:"FeedbackTable"}),
                });
                const columnState: ColumnState[] = event.columnApi.getColumnState();
                localStorage.setItem("columnData" ,JSON.stringify(columnState))
              }}
            />
          </div>
        </Column>
      </div>
    );
  }
}
FeedbackTable.contextType = UserContext;
export default withRouter(FeedbackTable);
