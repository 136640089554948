import React from 'react';
import {
    Modal, Button, TextButton, InputFormGroup, Dropdown, DropdownItem
} from '@amzn/storm-ui';
import {
    MultiSelect,
    MultiSelectItem,
    MultiSelectSimpleBulkActionPanel,
    MultiSelectSearchPanel,
} from '@amzn/storm-ui-multi-select';
import DatePicker from '@amzn/storm-ui-date-picker';
import Row from '../row';
import { ThemeProvider } from 'styled-components';
import { overrideZIndex } from '../../util/util';
import { subMonths } from 'date-fns';
import { toUnix } from '../../util/util';


class FilterDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            activeFilterObj: {},
            filterPrefix: 'includes',
            dateFilterPrefix: "before",
            filterTypeInput: "",
            selectedValuesMultiselect: [],
            filteredItems: [],
            dateSelected: new Date(),
            isDateSelected: false,
            isFilterTypeInput: false,
            editMode: false
        }
        this.buttonRef = React.createRef();
        this.footer = this.footer.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.setTextFilterCallback = this.setTextFilterCallback.bind(this);
        this.handleFilterInputChange = this.handleFilterInputChange.bind(this);
        this.renderFilterPrefix = this.renderFilterPrefix.bind(this);
        this.mappingForMultiSelectSearch = this.mappingForMultiSelectSearch.bind(this);
        this.setDateFilterCallback = this.setDateFilterCallback.bind(this);
        this.addFilter = this.addFilter.bind(this);
        this.handleMultiselectInputChange = this.handleMultiselectInputChange.bind(this);
        this.handleFilterDateSelect = this.handleFilterDateSelect.bind(this);
        this.autoFillData = this.autoFillData.bind(this);
        this.editFilter = this.editFilter.bind(this);
        // const { textContains, textNotContains, dateOn, dateBefore, dateAfter } = filterCallbacks;
    }
    componentDidMount() {
        const { filterField } = this.props;
        const { dropdownItems } = filterField
        if (dropdownItems) {
            this.setState({
                filteredItems: this.mappingForMultiSelectSearch(dropdownItems)
            })
        }
        this.autoFillData();
    }
    autoFillData() {
        const { dialogData } = this.props;
        if (dialogData) {
            const { filterPrefix, filterQueryVal } = dialogData;
            if (['on', 'before', 'after'].includes(filterPrefix)) {
                this.setState({
                    activeFilterObj: dialogData,
                    dateFilterPrefix: filterPrefix,
                    dateSelected: new Date(filterQueryVal * 1000),
                })
            } else {
                this.setState({
                    activeFilterObj: dialogData,
                    filterPrefix,
                    filterTypeInput: filterQueryVal,
                    selectedValuesMultiselect: filterQueryVal.split(','),
                    editMode: true
                })
            }
        }

    }
    mappingForMultiSelectSearch = (list) =>
        list.map((key) => ({ item: { label: Object.values(key)[0], key: Object.values(key)[0] } }));

    toggleModal() {
        const { closeFilterDialog } = this.props;
        this.setState({
            isOpen: false
        });
        closeFilterDialog();
    }
    addFilter() {
        const { updateActiveFilterList, activeFilterList } = this.props;
        const { activeFilterObj } = this.state;
        const updatedFilterList = [...activeFilterList];
        updatedFilterList.push(activeFilterObj);
        updateActiveFilterList(updatedFilterList);

        this.toggleModal();
    }

    editFilter() {
        const { updateActiveFilterList, activeFilterndex, activeFilterList } = this.props;
        const { activeFilterObj } = this.state;
        const updatedActiveFilterList = [...activeFilterList];
        updatedActiveFilterList.splice(activeFilterndex, 1);
        updatedActiveFilterList.push(activeFilterObj);
        updateActiveFilterList(updatedActiveFilterList);

        this.toggleModal();
    }

    footer() {
        const { isDateSelected, isFilterTypeInput, isSelectedValuesMultiselect, filterTypeInput, selectedValuesMultiselect, dateSelected, editMode } = this.state;
        const isDisabled = (isFilterTypeInput && filterTypeInput) || (isSelectedValuesMultiselect && selectedValuesMultiselect.length > 0) || (isDateSelected && dateSelected)
        return (
            <React.Fragment>
                <TextButton onClick={this.toggleModal}>Cancel</TextButton>
                <Button primary disabled={!isDisabled} onClick={editMode ? this.editFilter : this.addFilter} >Apply</Button>
            </React.Fragment>
        )
    }

    setTextFilterCallback(filterPrefix) {
        this.setState({
            filterPrefix
        })
    }
    setDateFilterCallback(dateFilterPrefix) {
        this.setState({
            dateFilterPrefix
        })
    }
    handleFilterInputChange(e) {
        const { value } = e.target;
        const { filterField } = this.props;
        const { filterPrefix } = this.state;
        let activeFilterObj = {};
        switch (filterPrefix) {
            case "includes":
                //attach textFilter contains callback to apply button
                activeFilterObj = {
                    filterPrefix,
                    filterCallback: "textContains",
                    filterPrefixLabel: "contains",
                    filterQueryVal: value,
                    filterField,
                    dataFieldName: filterField.dataFieldName
                }
                break;
            default:
                activeFilterObj = {
                    filterPrefix,
                    filterCallback: "textNotContains",
                    filterPrefixLabel: "doesn't contain",
                    filterQueryVal: value,
                    filterField,
                    dataFieldName: filterField.dataFieldName
                }
        }
        this.setState({
            filterTypeInput: value,
            activeFilterObj,
            isFilterTypeInput: true
        });

    }

    handleFilterDateSelect(selectedDate) {
        const { dateFilterPrefix } = this.state;
        const { filterField } = this.props;
        let activeFilterObj = {};
        switch (dateFilterPrefix) {
            case "on":
                activeFilterObj = {
                    filterPrefix: dateFilterPrefix,
                    filterCallback: "dateOn",
                    filterPrefixLabel: 'on',
                    filterQueryVal: toUnix(selectedDate),
                    filterField,
                    dataFieldName: filterField.dataFieldName
                }
                break;
            case "before":
                activeFilterObj = {
                    filterPrefix: dateFilterPrefix,
                    filterCallback: "dateBefore",
                    filterPrefixLabel: 'before',
                    filterQueryVal: toUnix(selectedDate),
                    filterField,
                    dataFieldName: filterField.dataFieldName
                }
                break;
            default:
                activeFilterObj = {
                    filterPrefix: dateFilterPrefix,
                    filterCallback: "dateAfter",
                    filterPrefixLabel: 'after',
                    filterQueryVal: toUnix(selectedDate),
                    filterField,
                    dataFieldName: filterField.dataFieldName
                }
        }
        this.setState({
            dateSelected: selectedDate,
            isDateSelected: true,
            activeFilterObj
        })
    }

    handleMultiselectInputChange(values) {
        const { filterField } = this.props;
        const { filterPrefix } = this.state;
        let activeFilterObj = {
            filterPrefix,
            filterCallback: "textContains",
            filterPrefixLabel: "contains",
            filterQueryVal: values.join(','),
            filterField,
            dataFieldName: filterField.dataFieldName
        }
        this.setState({
            selectedValuesMultiselect: values,
            activeFilterObj,
            isSelectedValuesMultiselect: true
        })
    }

    renderFilterPrefix() {
        const { filterField } = this.props;
        const { filterPrefix, filterTypeInput, selectedValuesMultiselect, filteredItems, dateSelected, dateFilterPrefix } = this.state;


        switch (filterField.filterComponent) {
            case "textFilter":
                return (
                    <React.Fragment>
                        <Dropdown
                            onChange={this.setTextFilterCallback}
                            selectedValue={filterPrefix}
                            onOverrideLabel={(dropdownItem, value) => {
                                if (value) { return dropdownItem; }
                                return 'Choose a filter';
                            }}
                        >
                            <DropdownItem value='includes'>contains</DropdownItem>
                            <DropdownItem value='excludes'>not contains</DropdownItem>
                        </Dropdown>
                        <InputFormGroup
                            label="Filter Type"
                            placeholder="Enter Value"
                            hideLabel={true}
                            // message="enter comma seperated values"
                            onChange={this.handleFilterInputChange}
                            value={filterTypeInput}
                        />
                    </React.Fragment>
                )
            case "multiselectDropdown":
                const searchFieldArr = this.mappingForMultiSelectSearch(filterField.dropdownItems);
                return (
                    <React.Fragment>
                        <MultiSelect
                            name="multi-select-simple"
                            label={filterField.itemName}
                            onChange={this.handleMultiselectInputChange}
                            fullWidth={true}
                            selectedValues={selectedValuesMultiselect}
                            withBulkActionPanel={(
                                <MultiSelectSimpleBulkActionPanel
                                    selectedValues={selectedValuesMultiselect}
                                    onSelectAll={() => {
                                        const newSelectedValues = filteredItems.map(({ item }) => item.label);
                                        const mergedSelectedValues = Array.from(new Set([...selectedValuesMultiselect, ...newSelectedValues]));

                                        this.handleMultiselectInputChange(mergedSelectedValues);
                                    }}
                                    onUnSelectAll={() => {
                                        this.setState({
                                            selectedValuesMultiselect: [],
                                            isSelectedValuesMultiselect: false
                                        })
                                    }}
                                />
                            )}
                            withSearchPanel={(
                                <MultiSelectSearchPanel
                                    items={searchFieldArr.map(({ item }) => {
                                        return { label: item.label, key: item.label }
                                    })}
                                    options={{ keys: ['label'], threshold: 0.3 }}
                                    onFilterChange={(items) => {
                                        if (items.length === searchFieldArr.length) {
                                            this.setState({
                                                filteredItems: searchFieldArr
                                            })
                                        }
                                        else {
                                            this.setState({
                                                filteredItems: items
                                            })
                                        }
                                    }}
                                    onFilterClear={() => {
                                        const { dropdownItems } = filterField
                                        this.setState({
                                            filteredItems: this.mappingForMultiSelectSearch(dropdownItems)
                                        })
                                    }}
                                />
                            )}
                            onOverrideLabel={(selectedValues) => {
                                if (selectedValues.length === 0) {
                                    return 'Choose one or more ' + filterField.itemName
                                }
                                const prefix = (
                                    <span key={`prefix-${selectedValues.length}`}>
                                        ( {selectedValues.length} - values for {filterField.itemName} selected )
                                    </span>
                                );
                                return [prefix];
                            }}
                            secondaryViewCloseButtonLabel="Done"
                        >
                            {filteredItems.map(({ item }) => {
                                const { label } = item;
                                return (
                                    <MultiSelectItem key={label} name={label} value={label}>
                                        {label}
                                    </MultiSelectItem>
                                )
                            })}
                        </MultiSelect>
                    </React.Fragment>
                )
            default:
                return (
                    <React.Fragment>
                        <Dropdown
                            onChange={this.setDateFilterCallback}
                            selectedValue={dateFilterPrefix}
                            onOverrideLabel={(dropdownItem, value) => {
                                if (value) { return dropdownItem; }
                                return 'Choose a filter';
                            }}
                        >
                            <DropdownItem value='on'>on</DropdownItem>
                            <DropdownItem value='before'>before</DropdownItem>
                            <DropdownItem value='after'>after</DropdownItem>
                        </Dropdown>
                        <DatePicker
                            onDateSelect={this.handleFilterDateSelect}
                            date={dateSelected}
                            maxDate={new Date()}
                            minDate={subMonths(new Date(), 24)}
                            zone='America/New_York'
                        />
                    </React.Fragment>
                )
        }
    }
    render() {
        const { isOpen } = this.state;
        const { filterField } = this.props;
        return (
            <ThemeProvider theme={overrideZIndex}>
                <Modal
                    isOpen={isOpen}
                    header={filterField.itemName}
                    footer={this.footer()}
                    onClose={this.toggleModal}
                    closeButtonLabel="Remove"
                    hasCloseButton={true}
                >
                    <Row spacingInset="small">
                        {this.renderFilterPrefix()}
                    </Row>
                </Modal>

            </ThemeProvider>
        )
    }
}

export default FilterDialog;