import React from "react";
import {
    InlineMessage
} from "@amzn/storm-ui";

const CustomBulkActionComponent = (props) => {
    return (
        <React.Fragment>
            <span>Are you sure you want to delete?</span>
            <InlineMessage messageType="warning" message="The Action is irreversible. Deleting an entity will remove it from the associated feedbacks too."/>
        </React.Fragment>
    )
}

export default CustomBulkActionComponent;