import * as React from "react";
import apiClient from "../../util/apiClient";
import {createEMFObject, throttle} from "../../util/util";
import "../../components/header/Header.scss";
import Logger from "../../util/logger";
import {
  SearchInput,
} from '@amzn/storm-ui';
import Loader from "../../components/Loader";


class HeaderTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOptionsOpen: false,
      isSearchLoading: false,
      isSuggestionsOpen: false,
      searchText: "",
      searchSuggestions: [],
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.fetchSearch = this.fetchSearch.bind(this);
    this.fetchSearchThrottled = throttle(this.fetchSearch, 1000);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.optionsRef && !this.optionsRef.contains(event.target)) {
      this.setState({ isOptionsOpen: false });
    }
    if (
      this.matchesRef &&
      !this.matchesRef.contains(event.target)

    ) {
      this.setState({ isSuggestionsOpen: false });
    }
  }

  fetchSearch() {
    const { searchText } = this.state;

    if (searchText) {
      this.setState({ isSearchLoading: true });
      apiClient
        .get(`search/${'Feedbacks'.toLowerCase()}/${encodeURIComponent(searchText.slice(0,50))}`, {
          params: {
            size: 10,
          },
        })
        .then((response) => {
          this.setState({
            searchSuggestions: response.data.results.filter(
              (suggestion) => suggestion.type === "Feature" || suggestion.type === "Feedback"
            ),
          });
        })
        .then(() => {
          this.setState({ isSearchLoading: false });
        });

      Logger.sessionInfo(`Global search fired for query ${searchText}`, {
        emfLog: createEMFObject("GlobalSearchHits", 1, "Count"),
      });
    }
  }

  render() {
    const {
      isSearchLoading,
      isSuggestionsOpen,
      searchText,
      searchSuggestions,
    } = this.state;

    return (

        <div className="search">
          <div style={{ flexGrow: 1, position: "relative" ,border:"0px"}}>
            <SearchInput
              placeholder="Search for feedback..."
              value={searchText}
              onChange={(event) => {
                this.setState({ searchText: event.target.value, isSuggestionsOpen: true }, this.fetchSearchThrottled);
              }}
              onClick={() => {
                this.setState({ isSuggestionsOpen: true });
              }}
              onFocus={() => {
                this.setState({ isSuggestionsOpen: true });
              }}
              onClear={(): void => {
                this.setState({ searchText: "", isSuggestionsOpen: false });
                this.setState({searchSuggestions : ""});
              }}
              ref={(node) => (this.searchRef = node)}
            />
            {isSearchLoading && (
                <Loader />
            )}
            {isSuggestionsOpen && searchSuggestions.length > 0 && (
              <div className="search-results" ref={(node) => (this.matchesRef = node)}>
                {searchSuggestions.map((suggestion) => (
                  <div className="search-results-item" key={suggestion.id}>
                    <a href={`/${suggestion.type.toLowerCase()}/${suggestion.id}`}>
                      <div>
                        {suggestion.type === "Feedback" ? suggestion.fields.accountName : suggestion.fields.featureName}
                      </div>
                      <div className="type">{suggestion.type}</div>
                    </a>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

    );
  }
}

export default HeaderTable;
