import KatalLogger from "@amzn/katal-logger";

import { PerformanceMarker, PerformanceMeasureName } from "../typings/enum";
import { createEMFObject } from "./util";

const loggerEndPoint = {
  "localhost:3000": "crjx82az3j",
  "beta.unity.advertising.amazon.dev": "crjx82az3j",
  "beta.acfportal.advertising.amazon.dev": "crjx82az3j",
  "gamma.unity.advertising.amazon.dev": "crjx82az3j",
  "gamma.acfportal.advertising.amazon.dev": "crjx82az3j",
  "unity.advertising.amazon.dev": "c0ow85yxcf",
  "acfportal.advertising.amazon.dev": "c0ow85yxcf",
};

const getEnvironment = (host) => {
  const envHash = {
    "beta.acfportal.advertising.amazon.dev": "beta",
    "gamma.acfportal.advertising.amazon.dev": "gamma",
    "acfportal.advertising.amazon.dev": "prod",
  };
  return envHash[host] || "local";
};

const Logger = new KatalLogger({
  url: `https://${
    loggerEndPoint[window.location.host]
  }.execute-api.us-east-1.amazonaws.com/prod/v1/log`,
  logToConsole:
    getEnvironment(window?.location?.hostname) !== "prod" ? true : false,
});

Logger.addMetricsListener();
Logger.addPerformanceListener();
Logger.addErrorListener();

Logger.sessionInfo = (message,context={}) => {
  const sessionId = sessionStorage.getItem("uuid");
  Logger.info(message,{...context,sessionId });
}

Logger.sessionError = (message,context={}) => {
  const sessionId = sessionStorage.getItem("uuid");
  Logger.error(message,{...context,sessionId });
}

export const logPerformanceTime = (measureName) => {
  const entries = performance.getEntriesByName(measureName);
  Logger.sessionInfo(measureName, {
    emfLog: createEMFObject(
      measureName,
      entries?.[entries.length - 1]?.duration,
      "Milliseconds"
    ),
  });
};

export const logPageMetric = (pageName, role = null, startMark = null) => {
  if (!startMark) {
    startMark =
      performance.getEntriesByName(PerformanceMarker.NewPageload).length > 0
        ? PerformanceMarker.NewPageload
        : PerformanceMarker.AppConstructor;
  }
  performance.measure(PerformanceMeasureName.PageLoadTime, startMark);
  const entries = performance.getEntriesByName(
    PerformanceMeasureName.PageLoadTime
  );
  Logger.sessionInfo(`${pageName}LoadTime`, {
    emfLog: createEMFObject(
      `${pageName}LoadTime`,
      entries?.[entries.length - 1]?.duration,
      "Milliseconds",
      role ? { role } : {}
    ),
  });
  Logger.sessionInfo(`${pageName}PageView`, {
    emfLog: createEMFObject(`${pageName}PageView`, 1, "Count"),
  });
};

export default Logger;
