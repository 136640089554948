import React from "react";

import { defaultTheme, Icon } from "@amzn/storm-ui";

const Loader = ({ small = false }) => {
  return (
    <Icon
      type="spinner"
      size={small ? "1x" : "2x"}
      style={{ color: defaultTheme.palette.teal[700] }}
    />
  );
};

export default Loader;
